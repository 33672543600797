<script lang="ts" setup>
import { VButton } from 'ah-common-lib/src/common/components';
import { useToast } from 'ah-common-lib/src/toast';
import { ToastType } from 'ah-common-lib/src/toast/toastInterfaces';
import QueryStringTabs from 'ah-common-lib/src/common/components/QueryStringTabs.vue';
import { ref } from 'vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';
import NotificationsList from 'bd-common/src/components/notifications/NotificationsList.vue';
import {
  BHTradeStatus,
  DocumentExportType,
  Notification,
  NotificationAlertType,
  NotificationResourceType,
  NotificationType,
} from 'ah-api-gateways';
import TradeStatusPill from 'ah-trades/src/components/tableCells/TradeStatusPill.vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { emailField, phoneField, textField } from 'ah-common-lib/src/form/models';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { ValidatedForm } from 'ah-common-lib/src/form/components';

const formDef = ref<FormDefinition>({
  form: makeFormModel({
    name: 'contactDetailsForm',
    title: 'Contact Details',
    fieldType: 'form',

    fields: [
      textField('title', 'Mandatory field', { required: true }),
      textField('firstName', 'Readonly field', { fieldWrapperClass: 'col-6 pr-0', required: false, readonly: true }),
      textField('lastName', 'Optional field', { required: false }),
      emailField('email', 'Email'),
      phoneField('phoneNumber', 'Mobile Number', {
        required: true,
        errorMessages: {
          required: 'This field is mandatory',
        },
      }),
    ],
  }),
  validation: null,
});

const statusList: BHTradeStatus[] = [
  BHTradeStatus.OPEN,
  BHTradeStatus.CLOSED,
  BHTradeStatus.AMENDED,
  BHTradeStatus.REJECTED,
  BHTradeStatus.REMAINING_CANCELING,
  BHTradeStatus.REMAINING_CANCELED,
  BHTradeStatus.DATE_ROLLED,
  BHTradeStatus.REMAINING_DATE_ROLLED,
  BHTradeStatus.FULLY_SETTLED,
  BHTradeStatus.REMAINING_SETTLED,
  BHTradeStatus.OVERDUE,
  BHTradeStatus.SETTLES_TODAY,
  BHTradeStatus.SUBMITTED,
  BHTradeStatus.PARTIALLY_OPEN,
  BHTradeStatus.CANCELING,
  BHTradeStatus.REMAINING_CLOSED,
  BHTradeStatus.VERIFIED,
];

const toast = useToast();

const notifications = ref<Notification[]>([
  {
    id: '1',
    alertType: NotificationAlertType.REGULAR,
    title: 'Notification one',
    message: 'This is a regular notification',
    createdAt: '2024-02-15T14:14:55.729Z',
    read: false,
    resourceType: NotificationResourceType.CLIENT_UUID,
    type: NotificationType.CLIENT_STATUS_UPDATE,
  },
  {
    id: '2',
    alertType: NotificationAlertType.REGULAR,
    title: 'Payments List',
    message: 'XLSX Document is ready to download',
    createdAt: '2024-02-14T12:14:55.729Z',
    read: false,
    resource: 'res3',
    resourceOrigin: DocumentExportType.EOM_STATEMENT,
    resourceType: NotificationResourceType.DOCUMENT_UUID,
    type: NotificationType.DOCUMENT_STATUS_EXPORTED,
  },
  {
    id: '3',
    alertType: NotificationAlertType.URGENT,
    title: 'Payments List',
    message: 'XLSX Document is ready to download',
    createdAt: '2024-02-13T12:14:55.729Z',
    read: false,
    resource: 'res3',
    resourceOrigin: DocumentExportType.EOM_STATEMENT,
    resourceType: NotificationResourceType.DOCUMENT_UUID,
    type: NotificationType.TRADE_CONFIRMED,
  },
  {
    id: '4',
    alertType: NotificationAlertType.REGULAR,
    title: 'Notification four',
    message: 'This is a regular notification',
    createdAt: '2024-02-12T14:14:55.729Z',
    read: true,
    resourceType: NotificationResourceType.CLIENT_UUID,
    type: NotificationType.CLIENT_STATUS_UPDATE,
  },
  {
    id: '5',
    alertType: NotificationAlertType.REGULAR,
    title: 'Payments List',
    message: 'PDF Document is ready to download',
    createdAt: '2024-02-11T12:14:55.729Z',
    read: true,
    resource: 'res5',
    resourceOrigin: DocumentExportType.EOM_STATEMENT,
    resourceType: NotificationResourceType.DOCUMENT_UUID,
    type: NotificationType.DOCUMENT_STATUS_EXPORTED,
  },
  {
    id: '6',
    alertType: NotificationAlertType.URGENT,
    title: 'Payments List',
    message: 'XLSX Document is ready to download',
    createdAt: '2024-02-10T12:14:55.729Z',
    read: true,
    resource: 'res3',
    resourceOrigin: DocumentExportType.EOM_STATEMENT,
    resourceType: NotificationResourceType.DOCUMENT_UUID,
    type: NotificationType.TRADE_CONFIRMED,
  },
]);

const buttonsDisabled = ref(false);
const isUseSwitches = ref(false);
</script>

<template>
  <div class="kitchen-sink p-3">
    <div class="text pb-5">
      <h1>H1 title</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut.
      </p>
      <h2>H2 title</h2>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut.
      </p>
      <h3>H3 title</h3>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut.
      </p>
      <h4>H4 title</h4>

      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi suscipit, ad, dolorum quam magni
        aliquid dolorem perspiciatis eligendi odio nisi veniam voluptatem. Facilis quisquam maiores corrupti deleniti
        ut.
      </p>
      <h5>H5 title</h5>
    </div>
    <div class="buttons">
      <div class="mb-3">
        <VButton
          class="btn-primary mr-4"
          @click="toast.info('Info box', {}, { noAutoHide: true })"
          :disabled="buttonsDisabled"
        >
          Trigger Info
        </VButton>
        <VButton
          class="btn-secondary mr-4"
          @click="toast.show('Warning box', { toastType: ToastType.warning })"
          :disabled="buttonsDisabled"
        >
          Trigger Warning
        </VButton>
        <VButton class="btn-success mr-4" @click="toast.success('Success box')" :disabled="buttonsDisabled">
          Trigger Success
        </VButton>
        <VButton class="btn-danger mr-4" @click="toast.error('Danger box')" :disabled="buttonsDisabled">
          Trigger Danger
        </VButton>
      </div>
      <div class="mb-3">
        <VButton class="btn-secondary mr-4" @click="buttonsDisabled = !buttonsDisabled"
          >{{ buttonsDisabled ? 'Enable' : 'Disable' }} all buttons</VButton
        >
      </div>
    </div>
    <div class="form">
      <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" />
    </div>
    <div class="mb-5">
      <VButton class="btn-danger mr-4">
        <div class="d-flex">
          <MaterialIcon class="mr-2" icon="report" />
          <span class="mt-1">Urgent</span>
        </div>
      </VButton>
      <VButton class="btn-secondary mr-4">
        <div class="d-flex">
          <MaterialIcon class="mr-2" icon="download" />
          <span class="mt-1">Download</span>
        </div>
      </VButton>
    </div>
    <div class="mb-5">
      <VButton class="btn-ghost"> Ghost Button </VButton>
    </div>
    <div>
      <div
        :class="{ 'custom-checkbox': !isUseSwitches, 'custom-switch': isUseSwitches }"
        class="checkbox-holder custom-control custom-checkbox"
      >
        <input type="checkbox" name="default" class="custom-control-input form-control" />
        <label class="custom-control-label"> Default </label>
      </div>
      <div class="checkbox-holder custom-control custom-checkbox">
        <input type="checkbox" name="checked" class="custom-control-input form-control" checked />
        <label class="custom-control-label"> Checked </label>
      </div>
      <div class="checkbox-holder custom-control custom-checkbox">
        <input type="checkbox" name="disabled" class="custom-control-input form-control" disabled />
        <label class="custom-control-label"> Disabled </label>
      </div>
    </div>
    <div class="mb-5">
      <div>
        <MaterialIcon class="mr-2" icon="notifications" />
        <MaterialIcon class="mr-2" icon="download" />
        <MaterialIcon class="mr-2" icon="notifications_active" />
        <MaterialIcon class="mr-2" icon="visibility" />
        <MaterialIcon class="mr-2" icon="visibility_off" />
        <MaterialIcon class="mr-2" icon="check_circle" />
        <MaterialIcon class="mr-2" icon="info" />
        <MaterialIcon class="mr-2" icon="error" />
        <MaterialIcon class="mr-2" icon="percent" />
        <MaterialIcon class="mr-2" icon="close" />
        <MaterialIcon class="mr-2" icon="cancel" />
        <MaterialIcon class="mr-2" icon="arrow_forward" />
        <MaterialIcon class="mr-2" icon="expand_more" />
        <MaterialIcon class="mr-2" icon="expand_less" />
        <MaterialIcon class="mr-2" icon="filter_alt" />
        <MaterialIcon class="mr-2" icon="filter_alt_off" />
        <MaterialIcon class="mr-2" icon="border_color" />
        <MaterialIcon class="mr-2" icon="delete" />
        <MaterialIcon class="mr-2" icon="pan_zoom" />
        <MaterialIcon class="mr-2" icon="cached" />
        <MaterialIcon class="mr-2" icon="list" />
        <MaterialIcon class="mr-2" icon="leaderboard" />
        <MaterialIcon class="mr-2" icon="settings" />
        <MaterialIcon class="mr-2" icon="add_box" />
      </div>
    </div>
    <div class="mb-5">
      <QueryStringTabs ref="tabsContainer" content-class="mt-4 px-2 mx-1" lazy>
        <BTab title="Page Main Tab" name="pageMainTabActive" active>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p>
        </BTab>
        <BTab title="Page Main Tab" name="pageMainTab">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p></BTab
        >
      </QueryStringTabs>
    </div>
    <div class="mb-5">
      <QueryStringTabs ref="tabsContainer" content-class="mt-4 px-2 mx-1 tab-icon" lazy>
        <BTab title="Secondary Tab" name="secondaryTabActive" active>
          <template #title>
            <div class="d-flex align-items-center">
              <MaterialIcon class="icon" icon="list" />
              Secondary Tab
            </div>
          </template>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p>
        </BTab>
        <BTab title="Secondary Tab" name="secondaryTab">
          <template #title>
            <div class="d-flex align-items-center">
              <MaterialIcon class="icon" icon="list" />
              Secondary Tab
            </div>
          </template>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p></BTab
        >
        <BTab title=" Secondary Analytics" name="secondaryAnalyticsTab">
          <template #title>
            <div class="d-flex align-items-center">
              <MaterialIcon class="icon" icon="leaderboard" />
              Secondary Analytics
            </div>
          </template>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p></BTab
        >
        <BTab title=" Settings Tab" name="settingsTab" active>
          <template #title>
            <div class="d-flex align-items-center">
              <MaterialIcon class="icon" icon="settings" />
              Settings Tab
            </div>
          </template>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem veritatis eligendi reiciendis voluptate quo
            beatae eos, possimus eaque adipisci quasi ipsum architecto inventore saepe dolor ullam tenetur quaerat
            ducimus aliquid!
          </p></BTab
        >
      </QueryStringTabs>
    </div>
    <div class="mb-5 row">
      <TradeStatusPill v-for="(status, index) in statusList" :key="index" class="m-1 col-3" :status="status" />
    </div>
    <div>
      <WarningSplash
        heading="No results found"
        sub-heading="Please try again with different filters"
        icon="tab_close"
        type="info"
      />
      <WarningSplash icon="emergency_home" type="danger" />
      <WarningSplash icon="move_to_inbox" type="success" />
      <WarningSplash
        heading="No results found"
        sub-heading="Please try again with different filters"
        icon="tab_close"
        type="warning"
      />
    </div>

    <div class="all-notifications card">
      <NotificationsList :notifications="notifications" />
      <div class="no-notifications-found mt-2">
        <WarningSplash heading="No notification found" sub-heading="You are up to date" icon="tab_close" type="info" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.no-notifications-found {
  background: getColorWithAlpha($color-bdMediumBlue, 0.3);
  padding: 20px 12px;
}
.btn-danger {
  padding: 8px 10px;
  border-radius: 2px;
  @include themedProp('background', getColorWithAlpha($color-bdDanger, 0.7), getColorWithAlpha($color-bdDanger, 0.3));
}

.urgent-text {
  font-size: 18px;
}

.all-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;

  .notification-action-icon {
    @include themedTextColor($color-bdPrimary);
    background: transparent;
    padding: 10px;
    font-size: 24px;

    &:disabled {
      @include themedTextColor($color-bdMediumGrey);
    }
  }
}

.go-back {
  border: none;
  background: transparent;
  border-bottom: 3px solid;
}
</style>
import { makeFormModel } from 'ah-common-lib/src/form/helpers'; import { textField, emailField, phoneField } from
'ah-common-lib/src/form/models';
