import { Store as PiniaStore } from 'pinia';
import { commonStoreActions } from 'ah-common-lib/src/constants/storeActions';
import { useAuthStore } from './authStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { useSettingsStore } from './settingsStore';

export const runActions = async (action: commonStoreActions, payload?: any) => {
  const actions = [] as Promise<any>[];

  const piniaStores: PiniaStore[] = [useAuthStore(), useNotificationsStore(), useSettingsStore()];
  for (let i = 0; i < piniaStores.length; i += 1) {
    if (typeof (piniaStores[i] as any)[action] === 'function') {
      actions.push(Promise.resolve((piniaStores[i] as any)[action](payload)));
    }
  }
  await Promise.all(actions);
};
