import { clientRoles } from 'ah-api-gateways';
import { ComponentOptions } from 'vue';
import { CustomRouteConfig, CustomRoute } from 'bd-common/src/router/routeModels';
import Error500View from 'bd-common/src/views/public/error/Error500View.vue';
import LoginView from 'bd-common/src/views/public/session/LoginView.vue';
import StubViewVue from 'bd-common/src/views/StubView.vue';
import NavViewVue from './views/NavView.vue';
import KitchenSinkViewVue from 'bd-common/src/views/KitchenSinkView.vue';
import NotificationsViewVue from 'bd-common/src/views/notifications/NotificationsView.vue';
import DownloadsView from 'bd-common/src/views/notifications/DownloadsView.vue';
import RegisterView from './views/RegisterView.vue';
import TradeView from './views/TradeView.vue';
import DeclineInviteView from './views/DeclineInviteView.vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const blankComponent = (name = 'BlankRouter'): ComponentOptions<Vue> => ({
  name,
  render(c) {
    return c('router-view');
  },
});

const sessionRoutes: CustomRouteConfig[] = [
  {
    path: 'login',
    component: LoginView,
    props: (route) => ({ redirectTo: route.query.r, redirectToQuery: route.query.q }),
    meta: { unAuthOnly: true },
  },
  {
    path: 'forgottenPassword',
    component: () =>
      import(/* webpackChunkName: "publicViews" */ 'bd-common/src/views/public/session/ForgottenPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'resetPassword/:token',
    props: true,
    component: () =>
      import(/* webpackChunkName: "publicViews" */ 'bd-common/src/views/public/session/ResetPasswordView.vue'),
    meta: { unAuthOnly: true },
  },
  {
    path: 'impersonate',
    component: () =>
      import(/* webpackChunkName: "publicViews" */ 'bd-common/src/views/public/session/ImpersonateWaitView.vue'),
    meta: { title: 'Impersonating User' },
  },
];

const errorRoutes: CustomRouteConfig[] = [
  {
    path: '/500',
    component: Error500View,
  },
  { path: '*', redirect: '/login' },
];

const stubRoutes: CustomRouteConfig[] = [
  { path: 'accounts', title: 'Accounts' },
  { path: 'payments', title: 'Payments' },
  { path: 'partner-offerings', title: 'Partner Offerings' },
  { path: 'settings', title: 'Settings' },
].map((i) => ({
  path: i.path,
  component: StubViewVue,
  props: {
    pageTitle: i.title,
  },
  meta: { title: i.title },
}));

const appRoute: CustomRouteConfig = {
  path: '/app',
  component: NavViewVue,
  meta: { auth: true, allowedRoles: clientRoles, redirectDisallowedRole: '/admin' },
  children: [
    ...stubRoutes,
    {
      path: 'trading',
      component: TradeView,
      meta: { title: 'Trading' },
    },
    {
      path: 'kitchen-sink',
      component: KitchenSinkViewVue,
      meta: { title: 'Kitchen Sink' },
    },
    {
      path: 'notifications',
      component: NotificationsViewVue,
      meta: { title: 'Notifications' },
    },
    {
      path: 'downloads',
      component: DownloadsView,
      meta: { title: 'Downloads' },
    },
    {
      path: '',
      redirect: './trading',
    },
    {
      path: '*',
      redirect: './trading',
    },
  ],
};

const registrationRoutes: CustomRouteConfig[] = [
  {
    path: '/register',
    component: RegisterView,
    props: (route: CustomRoute) => ({ token: route.params.token }),
    meta: { title: 'Register User', unAuthOnly: true },
  },
  {
    path: '/decline',
    component: DeclineInviteView,
    props: (route: CustomRoute) => ({ token: route.query.token }),
    meta: { title: 'Decline Registration', unAuthOnly: true },
  },
];

export const routes: CustomRouteConfig[] = [
  appRoute,
  ...registrationRoutes,
  {
    path: '/',
    component: NavViewVue,
    props: {
      hideSidebar: true,
    },
    children: [...sessionRoutes, ...errorRoutes],
  },
];
