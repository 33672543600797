<script lang="ts" setup>
import { ref } from 'vue';
import { useServices } from '../../services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useRouter } from 'vue-router/composables';

const props = defineProps<{
  email: string;
}>();

const resentCode = ref(false);

const requestManager = useRequestManager({
  exposeToParent: false,
}).manager;

const services = useServices();

const router = useRouter();

function resendVerificationCode() {
  if (resentCode.value) {
    return;
  }
  requestManager
    .currentOrNew('resendVerification', services.registration.resendRegistrationConfirmation(props.email))
    .subscribe(
      () => {
        resentCode.value = true;
      },
      () => {
        router.push('/login');
      }
    );
}
</script>

<template>
  <div>
    <h1 class="mb-5">Email not verified</h1>
    <p>Check your {{ email }} inbox to confirm your email and finish registration.</p>
    <div class="text-center mt-5">
      <VButton
        :label="resentCode ? 'Email sent' : 'Resend verification email'"
        :disabled="resentCode"
        @click="resendVerificationCode"
        class="btn-primary"
      />
    </div>
  </div>
</template>
