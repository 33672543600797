import { Address, CurrentAddressHistoryItem } from '../common/address';
import { IndividualType } from './individualType';
import { User } from '../user';
import { PartnerReference } from '../partner/partnerReference';
import { ClientReference } from '../client/clientReference';
import { IndividualSettings } from './individualSettings';
import { PaginatedQuery } from '..';
import { VersionedObject } from '../rest';
import { Permission } from '../authorization';
import { OnboardingIndividualInfo } from 'ah-api-gateways';

/**
 * Registration Status of an Individual
 *
 * Will map to Client's status if the Individual is registered (not CREATED or INVITED)
 * Will default to APPROVED (if registered) for Agent Individuals
 */
export enum RegistrationStatus {
  CREATED = 'CREATED',
  INVITED = 'INVITED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROPOSED = 'PROPOSED',
}

export interface BareIndividual {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  workPhoneNumber?: string;
  owner?: boolean;
  secondaryOwner?: boolean;
  nationality?: string;
  applicant?: boolean;
  type: IndividualType;
  title?: string;
  jobTitle?: string;
  proposedPermissions?: Permission[];
  retrievedInviteDetails?: boolean;
  birthDate?: string;
  currentAddress?: CurrentAddressHistoryItem;
  ownershipPercentage?: number;
}

export interface IndividualReference extends BareIndividual {
  id: string;
}

export interface AssigneeReference {
  individual: IndividualReference;
  primary: boolean;
}

export interface IndividualEditModel extends BareIndividual {
  birthDate: string;
  address: Address;
}

export interface Individual extends IndividualEditModel, VersionedObject {
  user: User;
  partner: PartnerReference;
  client?: ClientReference;
  nationality?: string;
  settings: IndividualSettings;
  status: RegistrationStatus;
  createdAt: string;
  createdBy: User;
  updatedAt: string;
  updatedBy: User;
}

export type IndividualListQueryParameters = PaginatedQuery<{
  clientId?: string;
  status: RegistrationStatus | RegistrationStatus[];
  type: IndividualType;
  email: string;
}>;

export interface CreateIndividualRequest extends IndividualEditModel {
  partner: { id: string };
  client: { id: string };
}

export function getIndividualName(identity: BareIndividual) {
  return `${identity.firstName} ${identity.lastName}`;
}

export function clearIndividual(individual: OnboardingIndividualInfo): OnboardingIndividualInfo {
  const innerInd = { ...individual };

  if (innerInd?.phoneNumber !== undefined && innerInd.phoneNumber.length <= 0) {
    delete innerInd.phoneNumber;
  }
  if (innerInd?.proposedPermissions !== undefined && innerInd.type === IndividualType.CLIENT_ADMIN) {
    delete innerInd.proposedPermissions;
  }
  return innerInd;
}
