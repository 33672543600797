import { render, staticRenderFns } from "./AppLogo.vue?vue&type=template&id=4665d716&scoped=true"
import script from "./AppLogo.vue?vue&type=script&lang=ts&setup=true"
export * from "./AppLogo.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./AppLogo.vue?vue&type=style&index=0&id=4665d716&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4665d716",
  null
  
)

export default component.exports