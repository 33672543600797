<script lang="ts" setup>
import VersionAlert from 'ah-common-lib/src/common/components/VersionAlert.vue';
import { config } from './config';
import MaintenanceWarningScreen from 'bd-common/src/components/common/MaintenanceWarningScreen.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { computed, onMounted, ref, watch } from 'vue';
import { useTheme } from 'ah-theme';

/**
 * Main App component
 *
 * This component serves 2 functions:
 * - Displays the maintenance screen if either the app is in maintenance OR the app theme has failed to load
 * - Re-checks for theme until it loads properly
 */

const CHECK_THEME_LOAD_TIMEOUT = 3000;

const pageLoaded = ref(false);

const authStore = useAuthStore();

const theme = useTheme();

let checkLoadThemeTimeout: number | null = null;

onMounted(() => {
  window.setTimeout(() => {
    pageLoaded.value = true;
  });
});

const showDevTools = computed(() => !!config.showDevTools);

const themeLoaded = computed(() => theme.loaded || theme.error);

function checkLoadTheme() {
  if (checkLoadThemeTimeout) {
    clearTimeout(checkLoadThemeTimeout);
  }
  if (!theme.loaded && !authStore.isInMaintenance) {
    theme.load().subscribe({
      error: () => {
        checkLoadThemeTimeout = window.setTimeout(() => checkLoadTheme(), CHECK_THEME_LOAD_TIMEOUT);
      },
    });
  }
}

watch(() => authStore.isInMaintenance, checkLoadTheme);
</script>

<template>
  <div id="app-holder" v-if="themeLoaded">
    <Transition name="fade" :appear="pageLoaded">
      <MaintenanceWarningScreen v-if="authStore.isInMaintenance || $theme.error" class="maintenance-screen-holder" />
      <RouterView v-else />
    </Transition>
    <VersionAlert v-if="showDevTools" />
  </div>
</template>

<style lang="scss">
#app-holder {
  height: 100%;
  @include themedBackgroundColor($color-main-bg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.maintenance-screen-holder {
  z-index: 1101; // Above toaster z-index
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>
