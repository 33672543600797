<script setup lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import {
  defineManagedModelFormEmits,
  defineManagedModelFormProps,
  useManagedModelForm,
} from 'ah-common-lib/src/form/useManagedModelForm';
import { maxLength, required } from '@vuelidate/validators';
import { ClientInviteCompanyAddress } from 'ah-api-gateways/models/client/clientInvite';

const props = defineProps({
  ...defineManagedModelFormProps<ClientInviteCompanyAddress>(),
});

const emit = defineEmits(defineManagedModelFormEmits<ClientInviteCompanyAddress>());

const { formDef } = useManagedModelForm({
  props,
  emit,
  formModel: makeFormModel({
    name: 'contactDetailsForm',
    title: 'Contact Details',
    fieldType: 'form',
    state: {
      showRequiredMarkers: true,
    },
    fields: [
      textField(
        'addressLine',
        'Street',
        {
          errorMessages: {
            required: 'This field is mandatory',
            maxLength: 'Address cannot have more than 250 characters.',
          },
          fieldWrapperClass: 'col col-12',
        },
        { required, maxLength: maxLength(250) }
      ),
      textField(
        'stateOrProvince',
        'State / Province / Region',
        {
          class: 'half-width right',

          errorMessages: {
            required: 'This field is mandatory',
            maxLength: 'State/Province cannot have more than 128 characters.',
          },
          fieldWrapperClass: 'col col-6',
        },
        { required, maxLength: maxLength(128) }
      ),
      textField(
        'postalCode',
        'ZIP / Postal code',
        {
          class: 'half-width right',

          errorMessages: {
            required: 'This field is mandatory',
            maxLength: 'Postcode cannot have more than 10 digits.',
          },
          fieldWrapperClass: 'col col-6',
        },
        { required, maxLength: maxLength(10) }
      ),
      textField(
        'city',
        'City / Town',
        {
          errorMessages: {
            required: 'This field is mandatory',
            maxLength: 'City cannot have more than 86 characters.',
          },
          fieldWrapperClass: 'col col-12',
        },
        { required, maxLength: maxLength(86) }
      ),
    ],
  }),
});
</script>

<template>
  <div class="contact-details-form">
    <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" />
  </div>
</template>
