<script lang="ts">
export interface PasswordDetails {
  password?: string;
  passwordConfirm?: string;
}
</script>

<script setup lang="ts">
import { watch } from 'vue';
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { passwordField } from 'ah-common-lib/src/form/models';
import { sameAsField } from 'ah-common-lib/src/form/validators';
import { required } from '@vuelidate/validators';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import PasswordValidationDisplay from 'bd-common/src/components/form/PasswordValidationDisplay.vue';
import {
  defineManagedModelFormEmits,
  defineManagedModelFormProps,
  useManagedModelForm,
} from 'ah-common-lib/src/form/useManagedModelForm';

const props = defineProps({
  ...defineManagedModelFormProps<PasswordDetails>(),
});

const emit = defineEmits(defineManagedModelFormEmits<PasswordDetails>());

const { formDef } = useManagedModelForm({
  props,
  emit,
  formModel: makeFormModel({
    name: 'createPasswordForm',
    title: 'Create Password',
    fieldType: 'form',
    fields: [
      passwordField('password', 'New password', true, {
        allowShowPassword: true,
        errorMessages: {
          passwordLength: '',
          passwordLower: '',
          passwordUpper: '',
          passwordNumber: '',
          passwordSpecialChar: '',
          required: 'This field is mandatory',
        },
        placeholder: 'Enter your new password',
      }),
      passwordField(
        'passwordConfirm',
        'Confirm new password',
        false,
        {
          autocomplete: 'new-password',
          allowShowPassword: true,
          placeholder: 'Confirm your new password',
          errorMessages: {
            passwordMatch: 'This password does not match the password entered above',
            required: 'This field is mandatory',
          },
        },
        {
          required,
          passwordMatch: sameAsField('password'),
        }
      ),
    ],
    state: {
      showRequiredMarkers: true,
    },
  }),
});

watch(
  () => !!(formDef.form.password && formDef.validation?.password?.$invalid && formDef.validation?.password?.$dirty),
  (val) => {
    const passwordField = getChildModel(formDef.form, 'password');
    if (passwordField) {
      setState(
        passwordField,
        'errors',
        val
          ? [
              {
                name: 'password',
                error: 'This password does not follow the required criteria.',
              },
            ]
          : []
      );
    }
  }
);
</script>

<template>
  <div class="create-password-form">
    <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation">
      <template #createPasswordForm.password:passwordShownIcon="{ isPasswordShown }">
        <MaterialIcon
          class="password-visible-icon clickable"
          :icon="isPasswordShown ? 'visibility' : 'visibility_off'"
        />
      </template>
      <template #createPasswordForm.passwordConfirm:passwordShownIcon="{ isPasswordShown }">
        <MaterialIcon
          class="password-visible-icon clickable"
          :icon="isPasswordShown ? 'visibility' : 'visibility_off'"
        />
      </template>
    </ValidatedForm>
    <PasswordValidationDisplay :validation="formDef.validation?.password" v-if="formDef.validation?.password" />
  </div>
</template>
