<script setup lang="ts">
import RedeemInviteStep from './RedeemInviteStep.vue';
import { PropType, ref, watch } from 'vue';
import { ClientInvite } from 'ah-api-gateways/models/client/clientInvite';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';
import AccountVerificationStep from './AccountVerificationStep.vue';
import TermsAndConditionsStep from './TermsAndConditionsStep.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import CompanyDetailsStep from './CompanyDetailsStep.vue';
import { useRouter } from 'vue-router/composables';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { waitForEntityChange } from 'ah-requests';
import { Observable, from } from 'rxjs';
import { FullSessionUser, UserRole } from 'ah-api-gateways';
import { useToast } from 'ah-common-lib/src/toast';
import LoadingSplash from 'bd-common/src/components/common/LoadingSplash.vue';

/**
 * Client Invite registration Flow
 *
 * Manages flow state and displays the correct step - all actions are performed within the step components
 */

defineProps({
  token: {
    type: String,
    required: false,
  },
  invite: {
    type: Object as PropType<ClientInvite>,
    required: true,
  },
});

const emit = defineEmits({
  'update:step': (_step: RegistrationSteps) => true,
});

const authStore = useAuthStore();

const router = useRouter();

const requestManager = useRequestManager().manager;

const toast = useToast();

enum RegistrationSteps {
  invite = 'invite',
  verification = 'verification',
  companyDetails = 'companyDetails',
  termsAndConditions = 'termsAndConditions',
  end = 'end',
  error = 'error',
}

const StepsFlow = [
  RegistrationSteps.invite,
  RegistrationSteps.verification,
  RegistrationSteps.companyDetails,
  RegistrationSteps.termsAndConditions,
];

const step = ref<RegistrationSteps>(RegistrationSteps.invite);

if (authStore.hasRegistrationSession) {
  const user = authStore.loggedInUser!;
  if (!user.individual?.client) {
    step.value = RegistrationSteps.companyDetails;
  } else {
    step.value = RegistrationSteps.termsAndConditions;
  }
} else if (authStore.isLoggedIn) {
  router.push('/');
}

function onStepSubmitted() {
  const stepIndex = StepsFlow.indexOf(step.value);

  if (stepIndex > -1 && stepIndex < StepsFlow.length - 1) {
    step.value = StepsFlow[stepIndex + 1];
  } else if (step.value === RegistrationSteps.termsAndConditions) {
    step.value = RegistrationSteps.end;
    requestManager
      .currentOrNew(
        'submitRegistration',
        waitForEntityChange(
          () =>
            new Observable<FullSessionUser>((subscriber) => {
              from(authStore.refreshSession().then(() => authStore.loggedInUser!)).subscribe(subscriber);
            }),
          (user) => {
            return user.role !== UserRole.CLIENT_REGISTRATION;
          }
        )
      )
      .subscribe(
        () => {
          router.push('/');
          toast.success('You are now in the BankHedge portal.', {
            title: 'Your onboarding was successful!',
          });
        },
        () => {
          step.value = RegistrationSteps.error;
        }
      );
  }
}

watch(step, () => {
  emit('update:step', step.value);
});

function reloadPage() {
  router.go(0);
}
</script>

<template>
  <div class="row register-block justify-content-center">
    <!-- <div class="col-4 flow-steps-block">
      <RegistrationFlowSteps />
    </div> -->
    <RedeemInviteStep
      @submitted="onStepSubmitted"
      @failed="step = RegistrationSteps.error"
      :token="token"
      :invite="invite"
      v-if="step === RegistrationSteps.invite && token"
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4"
    />
    <AccountVerificationStep
      v-else-if="step === RegistrationSteps.verification"
      @submitted="onStepSubmitted"
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4"
    />
    <CompanyDetailsStep
      v-else-if="step === RegistrationSteps.companyDetails"
      @submitted="onStepSubmitted"
      :invite="invite"
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4"
    />
    <TermsAndConditionsStep
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4"
      v-else-if="step === RegistrationSteps.termsAndConditions"
      @submitted="onStepSubmitted"
    />
    <LoadingSplash
      class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mt-4"
      text="Finishing your registration"
      v-else-if="step === RegistrationSteps.end"
    />
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mt-4" v-else-if="step === RegistrationSteps.error">
      <WarningSplash heading="An error occurred while processing invite" type="danger" sub-heading="Please try again" />
      <div class="text-center mt-4">
        <VButton class="btn-primary" @click="reloadPage"> Reload page </VButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
