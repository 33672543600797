import { APIGatewayURls, AppConfig, baseConfig } from "./baseConfig";

export default {
  ...baseConfig,
  ...APIGatewayURls('https://dev.api.alt21.com/v3.2/'),
  staticOTP: true,
  showDevTools: true,
  allowMockedFunding: true,
  altReferer: 'https://dev.x-hedge.alt21.com/',
  plaidInSandboxMode: false,
} as AppConfig;
