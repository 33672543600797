import Vue from 'vue';
import { useServices, Services } from '../services';

import {
  StoreSupportData as CommonStoreSupportData,
  makeStoreSupportData as commonMakeSupport,
} from 'ah-common-lib/src/store';
import { useConfig } from '../config';

export type StoreSupportData<T = any> = CommonStoreSupportData<T, Services>;

export function makeStoreSupportData<T = any>(data: T = {} as any): StoreSupportData<T> {
  return commonMakeSupport({
    data,
    config: useConfig(),
    s: useServices(),
    tabSync: Vue.tabSync,
  });
}
