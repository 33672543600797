import type { AppConfigSetup } from 'bd-common/src/config';
import App from '@/App.vue';
import { routes } from './app/routes';
import { clientRoles } from 'ah-api-gateways';

export const config: AppConfigSetup = {
  allowedRoles: clientRoles,
  rootComponent: App,
  routes,
  // FIXME add an alt referer per environment
  altReferer: 'https://dev.clientportal.alt21.com/',
};
