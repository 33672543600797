<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const chars = ref<string[]>([]);

const items = ref<HTMLDivElement[]>([]);

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, inputClass, inputErrorClass, fieldErrorsShown } = useBaseFieldValues(formElement);

const { modelStateRef, emitFormEvent, field, setValue } = formElement;

function onOtpKeyDown(event: KeyboardEvent, index: number) {
  if (event.key === 'Backspace' && !(event.target as any).value) {
    const next = items.value[constrainIndex(index - 1)].firstElementChild as HTMLInputElement;
    next.focus();
  } else if (event.key === 'Enter') {
    emitFormEvent({
      event: 'form-field-submit',
    });
  }
}

function onOtpInput(event: InputEvent, index: number) {
  const strings = (event.target as any).value.split('');
  const nextIndex = constrainIndex(strings.length + index);
  const next = items.value[nextIndex].firstElementChild as HTMLInputElement;
  if (strings.length) {
    strings.forEach((char: string, charIndex: number) => {
      if (charIndex + index < otpParts.value) {
        const number = parseInt(char, 10);
        chars.value[charIndex + index] = Number.isNaN(number) ? '' : number.toString();
        chars.value = [...chars.value];
        if (!Number.isNaN(number)) {
          next.focus();
        }
      }
    });
  } else {
    chars.value[index] = '';
    chars.value = [...chars.value];
  }
}

function constrainIndex(index: number) {
  return Math.max(Math.min(index, otpParts.value - 1), 0);
}

const otpParts = modelStateRef('length', 6);

function onFocus(event: FocusEvent) {
  const target: HTMLInputElement = event.target as HTMLInputElement;
  if (target) {
    target.setSelectionRange(0, target.value.length);
  }
}

function onBlur() {
  setTimeout(() => {
    let internal = false;
    items.value.forEach((item: any) => {
      if (document.activeElement && document.activeElement === item.firstElementChild) {
        internal = true;
      }
    });
    if (!internal) {
      field.value.$touch();
    }
  });
}

watch(chars, () => setValue(chars.value));

watch(
  () => field.value.$model,
  () => {
    chars.value = Array.isArray(field.value.$model) ? field.value.$model : [];
  }
);
</script>

<template>
  <div class="otp-field-holder">
    <div v-for="index in otpParts" ref="items" :key="index" class="otp-field">
      <input
        :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
        inputmode="numeric"
        autocomplete="off"
        :readonly="readonly"
        :value="chars[index - 1] ? chars[index - 1].toString() : ''"
        @input="onOtpInput($event, index - 1)"
        @keydown="onOtpKeyDown($event, index - 1)"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.otp-field-holder {
  display: flex;
  margin: 0 -0.25rem;
  justify-content: center;

  .otp-field {
    padding: 0.5rem 0.5rem;
    text-align: center;

    .field-group-field-input {
      max-width: 2.5rem;
      padding: 0 0.5rem;
      text-align: center;
    }
  }
}
</style>
