<script lang="ts" setup>
import Vue, { ref, getCurrentInstance, computed, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { generateUID } from '../../../helpers/uuid';
import { VueTelInput } from 'vue-tel-input';
import { getState } from '../../helpers/formHelpers';
import { displayNumber } from '../../../helpers/calls';
import 'vue-tel-input/dist/vue-tel-input.css';

interface VueTelInputValidation {
  country: {
    dialCode: string;
    iso2: string;
    name: string;
  };
  countryCallingCode: string;
  countryCode: string;
  formatted: string;
  nationalNumber: string;
  number: string;
  valid: boolean;
}

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const uid = generateUID(10);

const component = getCurrentInstance()?.proxy;

/**
 * We keep `valid` stored so changes to the model can trigger recalculation of input-based validation
 */
const valid = ref(true);

/**
 * We store `typedValue` and `fieldValue` so phone number input validation is triggered as intended by VueTelInput.
 *
 * The input shows preferably `typedValue` (if set), so normal user input is triggers phone input validation as intended.
 *
 * During normal operation, `fieldValue` matches the model, and `typedValue` is shown.
 * When $model changes externally, `typedValue` and `fieldValue` are reset - this is done via the @Watch annotation
 */
const typedValue = ref('');
const fieldValue = ref('');

const telInput = ref<Vue>();

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, dirtyOnInput, placeholder, inputClass, inputErrorClass, fieldErrorsShown } =
  useBaseFieldValues(formElement);

const { modelStateRef, getModelState, model, field, setModelState, setValue: baseSetValue } = formElement;

/**
 * If `typedValue` is set
 */
const modelValue = computed(() => {
  return typedValue.value || displayNumber(field.value.$model) || field.value.$model;
});

const defaultCountry = modelStateRef('defaultCountry', '');

const inputOptions = computed(() => {
  return {
    id: uid,
    placeholder: placeholder.value,
    styleClasses: [
      'field-group-field-input form-control',
      inputClass.value,
      { [inputErrorClass.value]: fieldErrorsShown.value },
    ],
    ...getModelState('inputOptions', { showDialCode: true, tabindex: 0 }),
  };
});

const countries = computed(() => {
  return getState(model.value, 'countries', undefined);
});

const autoDefaultCountry = computed(() => {
  return modelValue.value.length === 0;
});

/**
 * Input event reaction:
 * - Trigger `setValue`
 * - Maintain focus, if currently focussed (preventing focus change bug in vue-tel-input), using telInput's focus method
 */
function onInput(val: string, value: VueTelInputValidation) {
  const input = telInput.value?.$refs?.input as HTMLElement;
  if (input && document.activeElement === input) {
    setTimeout(() => (telInput.value as any).focus());
  }
  setValue(val, value);
}

/**
 * Set value method called on user input - this sets field.$model but also `typedValue` and `fieldValue`,
 * to ensure field display and calidation runs as expected
 *
 * If field is too short, it is considered as empty, so field can be non-required
 */
function setValue(val: string, validation: VueTelInputValidation) {
  if (val.length > 4 || (validation.country && val !== `+${validation.country.dialCode}`)) {
    fieldValue.value = validation.valid ? validation.number : val;
    typedValue.value = val;
  } else {
    fieldValue.value = '';
    typedValue.value = val;
  }
  if (fieldValue.value !== field.value.$model) {
    baseSetValue(fieldValue.value, !field.value.$dirty && !dirtyOnInput.value);
  }
  setModelState('valid', validation.valid);
  component?.$forceUpdate();
}

function onValidate(val: VueTelInputValidation) {
  valid.value = val.valid;
  setModelState('valid', valid.value);
}

watch(
  () => field.value.$model,
  () => {
    if (!fieldValue.value || field.value.$model !== fieldValue.value) {
      fieldValue.value = '';
      typedValue.value = '';
    }
  }
);

/**
 * Trigger a `valid` state change on value or field changes
 */
watch([modelValue, field], () => {
  setModelState('valid', valid.value);
});
</script>

<template>
  <VueTelInput
    ref="telInput"
    :value="modelValue"
    :auto-default-country="autoDefaultCountry"
    :default-country="defaultCountry"
    :disabled="readonly"
    :all-countries="countries"
    :valid-characters-only="true"
    :input-options="inputOptions"
    style-classes="field-group-field-holder"
    @validate="onValidate"
    @input="onInput"
    @blur="() => field.$touch()"
    :key="autoDefaultCountry"
  />
</template>
