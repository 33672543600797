import { render, staticRenderFns } from "./InputGroupWrapper.vue?vue&type=template&id=6fdad764&scoped=true"
import script from "./InputGroupWrapper.vue?vue&type=script&setup=true&lang=ts"
export * from "./InputGroupWrapper.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./InputGroupWrapper.vue?vue&type=style&index=0&id=6fdad764&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fdad764",
  null
  
)

export default component.exports