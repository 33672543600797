<script lang="ts" setup>
import VButton from '../../common/components/VButton.vue';
import FormElement from './FormElement.vue';
import { FormModel, FormEvent, FormAction } from '../interfaces/form';
import { PropType, reactive, watch, ref } from 'vue';
import { FormValidation } from '../interfaces';
import { useFormElement } from '../composables/useFormElement';
import { makeUseFormElementEmits } from '../composables/useFormElementInterfaces';
/**
 * Dynamic Form component
 *
 * Usually the base component for any schema based forms
 * Emits:
 * - `form-event` for all form events (re-emited from fields or emited from form actions)
 * - `submit` - convenience event, emmited alongside the relevant `form-event`
 */

const props = defineProps({
  form: {
    type: Object as PropType<FormValidation<FormModel>>,
    required: true,
  },
});

const emit = defineEmits({
  ...makeUseFormElementEmits(),
  submit: (_event: FormEvent) => true,
});

const formElProps = reactive({
  field: props.form,
  model: props.form.$model,
});

const fieldEls = ref<InstanceType<typeof FormElement>[]>([]);

watch(
  () => props.form,
  () => {
    formElProps.field = props.form as any;
    formElProps.model = props.form.$model;
  }
);

const { modelStateRef, model, field } = useFormElement({
  emit,
  props: formElProps,
});

const requiredText = modelStateRef('requiredText', '');

const requiredTextNote = modelStateRef('requiredTextNote', '');

const formClass = modelStateRef('formClass', 'form dynamic-form', true);

function onFormEvent(event: FormEvent) {
  emit('form-event', event);
  if (event.event === 'form-field-submit') {
    emit('submit', event);
  }
}

function onFormAction(action: FormAction) {
  setTimeout(() => {
    emit('form-event', {
      field: field.value,
      model: model.value as FormModel,
      event: 'form-action',
      action,
    } as FormEvent);
  });
}

function triggerFieldAction(path: string, action: any) {
  if (model.value.$path === path) {
    // trigger action here!
  } else {
    model.value.$fields.forEach((field: any, index: number) => {
      if (path.startsWith(field.$path)) {
        const child = fieldEls.value[index] as InstanceType<typeof FormElement>;
        if (child && child.triggerFieldAction) {
          child.triggerFieldAction(path, action);
        }
      }
    });
  }
}

defineExpose({
  triggerFieldAction,
});
</script>

<template>
  <form @submit.prevent :class="formClass" :name="model.$name">
    <slot name="form-header" v-bind="{ form, model }">
      <label v-if="requiredText" class="form-header">
        <span class="form-header-text required-text">{{ requiredText }}</span>
        <template v-if="requiredTextNote">
          <br />
          <span class="form-header-text required-text-note">{{ requiredTextNote }}</span>
        </template>
      </label>
    </slot>
    <FormElement
      v-for="(child, index) in model.$fields"
      :key="`${child.$name} ${index}`"
      ref="fieldEls"
      :field="form[child.$name]"
      :model="child"
      @form-event="onFormEvent"
    >
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </FormElement>
    <div v-if="model.$actions.length" class="form-actions">
      <VButton
        v-for="(action, index) in model.$actions"
        :key="index"
        :disabled="!action.enabled || (!action.allowOnInvalid && form.$invalid)"
        :class="action.class"
        :label="action.label"
        :loading-label="action.loadingLabel"
        :loading="action.loading"
        @click="onFormAction(action)"
      />
    </div>
  </form>
</template>
