var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.InputGroupWrapper,_vm._b({scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}})],null,true)},'InputGroupWrapper',{ field: _setup.field, model: _vm.model },false),[_c('input',{ref:"currencyInput",class:[
        'field-group-field-input currency-field-input text-right',
        _setup.inputClass,
        {
          [_setup.inputErrorClass]: _setup.fieldErrorsShown,
          'hide-error': !_setup.showAsDirty,
          'is-dirty': _setup.showAsDirty,
          'unexpected-error': _setup.unexpectedErrorsShown,
        },
      ],attrs:{"placeholder":_setup.placeholder,"disabled":_setup.readonly,"inputmode":"decimal"},domProps:{"value":_setup.inputText},on:{"input":function($event){return _setup.setAmount($event.target.value)},"blur":_setup.onBlur,"focus":_setup.onFocus}})]),(_setup.unexpectedErrorsShown)?_c('ErrorIcon',{staticClass:"error-icon icon"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }