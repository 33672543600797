<script lang="ts" setup>
import { reactive, watch, ref, onMounted, computed, PropType } from 'vue';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { makeFormModel, toDataModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { otpField } from 'ah-common-lib/src/form/models';
import { FormValidation, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { AuthErrorCodes, MFAType, SecurityErrorCodes } from 'ah-api-gateways';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { ExpiryTime } from 'ah-api-gateways/models/expiry';
import { useServices } from 'bd-common/src/services';
import { useConfig } from '../../config';
import { VButton } from 'ah-common-lib/src/common/components';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import ToastComponent from '../common/ToastComponent.vue';
import { useToast } from 'ah-common-lib/src/toast';
import { ToastType } from 'ah-common-lib/src/toast/toastInterfaces';
import { DEFAULT_OTP_EXPIRY_TIME } from 'ah-common-lib/src/helpers/otp';

const props = defineProps({
  otpTarget: {
    type: String,
    default: '',
  },
  otpType: {
    type: String as PropType<MFAType>,
    required: true,
  },
  expiry: {
    type: Object as PropType<ExpiryTime>,
    required: false,
  },
});

const emit = defineEmits<{
  (e: 'error', value: any): void;
  (e: 'success'): void;
  (e: 'cancel'): void;
}>();

const state = reactive({
  otpFM: makeFormModel({
    name: 'otpForm',
    title: 'OTP',
    fieldWrapperClass: 'otp-field-wrapper',
    fieldType: 'form',
    fields: [otpField('otp', '', { hideErrors: true })],
  }),
  otpFV: null as FormValidation | null,
  expiredOtp: false,
});

enum OtpCodeError {
  INVALID_OTP = 'INVALID_OTP',
  EXPIRED_OTP = 'EXPIRED_OTP',
}

const otpCodeError = ref<OtpCodeError | null>(null);

const requestManager = useRequestManager({ exposeToParent: false }).manager;

const authStore = useAuthStore();

const services = useServices();

const toast = useToast();

const config = useConfig();

const form = ref<ValidatedForm | null>(null);

const isResendCodeButtonDisabled = ref(false);

const expiryInner = ref<ExpiryTime>();

watch(
  () => props.expiry,
  () => {
    expiryInner.value = props.expiry;
  },
  { immediate: true }
);

const minutesToExpire = computed(() => {
  if (expiryInner.value?.expiresIn) {
    return Math.floor(expiryInner.value.expiresIn / 60);
  }
  return DEFAULT_OTP_EXPIRY_TIME;
});

watch(
  () => state.expiredOtp,
  () => {
    state.otpFM.otp = [];
    const otpField = getChildModel(state.otpFM, 'otp');
    if (otpField) {
      setState(otpField, 'readonly', state.expiredOtp);
    }
  }
);

function otpContinue() {
  if (state.otpFV?.$invalid) {
    return;
  }
  otp();
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-submit') {
    otpContinue();
  }
}

function otp() {
  requestManager.currentOrNewPromise('sendOtp', () =>
    authStore.otp(toDataModel(state.otpFM).otp).then(
      () => onOtpSuccess(),
      (error: any) => {
        if (error.response) {
          if (error.response?.data?.code === AuthErrorCodes.OTP_ATTEMPTS_EXCEEDED) {
            toast.error(error.response?.data?.message);
            backToLogin();
          } else if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
            otpCodeError.value = OtpCodeError.INVALID_OTP;
          } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
            otpCodeError.value = OtpCodeError.EXPIRED_OTP;
            state.expiredOtp = true;
          } else {
            toast.error('Oops, there was an error while trying to validate your OTP');
          }
        }
        emit('error', error);
      }
    )
  );
}

function refreshOtp() {
  requestManager.currentOrNew('refreshOtp', services.auth.refreshOtp()).subscribe(
    (res) => {
      state.otpFM.otp = [];
      state.expiredOtp = false;
      toast.success('Two-step authentication code resent');
      expiryInner.value = res;
    },
    (error) => {
      if (error.response) {
        if (error.response?.data?.code === SecurityErrorCodes.INVALID_OTP) {
          otpCodeError.value = OtpCodeError.INVALID_OTP;
        } else if (error.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
          otpCodeError.value = OtpCodeError.EXPIRED_OTP;
          state.expiredOtp = true;
        } else {
          toast.error('Oops, there was an error while trying to validate your OTP');
        }
      }
    }
  );
}

function resendCode() {
  isResendCodeButtonDisabled.value = true;

  refreshOtp();

  setTimeout(() => {
    isResendCodeButtonDisabled.value = false;
  }, 60000);
}

const errorTitle = computed(() => {
  if (otpCodeError.value === OtpCodeError.INVALID_OTP) {
    return 'Wrong code. Please try again.';
  } else if (otpCodeError.value == OtpCodeError.EXPIRED_OTP) {
    return 'Code expired. Please click on "Resend code"';
  }
  return '';
});

const errorMessage = computed(() => {
  if (otpCodeError.value === OtpCodeError.INVALID_OTP) {
    return 'Sorry, the code you entered is incorrect.';
  } else if (otpCodeError.value == OtpCodeError.EXPIRED_OTP) {
    return 'Sorry, the code you entered has expired.';
  }
  return '';
});

function closeOtpCodeError() {
  otpCodeError.value = null;
}

function onOtpSuccess() {
  emit('success');
}

/**
 * called when it is needed to go back and change the email; emits cancel
 */
function backToLogin() {
  emit('cancel');
}

onMounted(() => {
  form.value?.form.triggerFieldAction('otpForm.otp', 'focus');
});
</script>

<template>
  <div class="otp-box">
    <div class="mb-4">
      <p class="resend-code-text">
        A 6-digit SMS verification code has been sent to your
        {{ otpType === MFAType.EMAIL_OTP ? 'email address' : 'phone number'
        }}<span v-if="otpTarget"> ending in {{ otpTarget }}</span
        >. The code will be valid for {{ minutesToExpire }} {{ minutesToExpire === 1 ? 'minute' : 'minutes' }}.
      </p>
    </div>
    <ToastComponent
      v-if="otpCodeError"
      :id="'otp-error-toast'"
      :toast-type="ToastType.danger"
      :message="errorMessage"
      :title="errorTitle"
      :dismiss="true"
      @close="closeOtpCodeError"
      class="mb-4"
    />
    <div class="otp-form-wrapper">
      <div class="otp-form-wrapper-inner">
        <p v-if="!config.staticOTP">Code</p>
        <p v-else class="text-secondary">For testing purposes, code is always 123456</p>
        <ValidatedForm
          ref="form"
          class="otp-form"
          :fm="state.otpFM"
          :validation.sync="state.otpFV"
          @form-event="onFormEvent"
        />
      </div>
    </div>
    <p class="text-center">
      Didn’t receive the message?
      <a href="#" @click.prevent="resendCode" class="resend-code" :class="{ disabled: isResendCodeButtonDisabled }">
        Resend code.</a
      >
    </p>
    <div class="form-actions pt-5 text-center">
      <VButton
        :disabled="state.otpFV && state.otpFV.$invalid"
        :loading="requestManager.anyPending"
        label="Verify and login"
        class="btn-primary"
        @click="otpContinue"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.otp-form-wrapper {
  display: flex;
  justify-content: center;
  .otp-form {
    display: inline-block;
  }
}
.resend-code {
  &.disabled {
    @include themedTextColor($color-bdDarkGrey);
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: underline;
  }
}
</style>
