<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useNotificationsStore } from 'ah-notifications/src/store';
import NotificationsTooltip from 'bd-common/src/components/notifications/NotificationsTooltip.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import UserIconMenu from 'bd-common/src/components/user/UserIconMenu.vue';

type NotificationsStoreType = {
  unreadCount: number;
  fileExportUnreadCount: number;
};

const notificationsTooltip = ref<InstanceType<typeof NotificationsTooltip> | null>(null);
const fileExportsTooltip = ref<InstanceType<typeof NotificationsTooltip> | null>(null);

const notificationsStore = useNotificationsStore();

const hasUnreadNotifications = computed(() => notificationsStore.unreadCount > 0);

const hasUnreadFileExports = computed(() => notificationsStore.fileExportUnreadCount > 0);

const notificationCount = (property: keyof NotificationsStoreType) => {
  return computed(() => {
    const count = notificationsStore[property];
    return count > 99 ? '99+' : count;
  });
};

const notificationNumber = notificationCount('unreadCount');
const fileExportNumber = notificationCount('fileExportUnreadCount');

function showNotificationsTooltip() {
  notificationsTooltip.value?.show();
}

function showFileExportsTooltip() {
  fileExportsTooltip.value?.show();
}
</script>

<template>
  <div class="top-nav-holder">
    <div @click="showFileExportsTooltip" class="menu-icon download-icon">
      <span v-b-tooltip="'Downloads'">
        <MaterialIcon class="nav-icon" icon="download" />
        <div v-if="hasUnreadFileExports" class="notification-badge">{{ fileExportNumber }}</div>
      </span>
      <NotificationsTooltip ref="fileExportsTooltip" type="file_export" />
    </div>
    <div @click="showNotificationsTooltip" class="menu-icon notification-icon">
      <span v-b-tooltip="'Notifications'" id="custom-tooltip">
        <MaterialIcon class="nav-icon" icon="notifications" />
        <div v-if="hasUnreadNotifications" class="notification-badge">{{ notificationNumber }}</div>
      </span>
      <NotificationsTooltip ref="notificationsTooltip" type="notification" />
    </div>
    <UserIconMenu class="user-icon-menu" />
    <slot name="nav-siblings" />
  </div>
</template>

<style lang="scss" scoped>
.top-nav-holder {
  display: grid;
  grid-template-columns: 1fr max-content max-content max-content;
  grid-template-areas: 'empty notification-icon download-icon user-icon-menu';
  grid-template-rows: 100%;
  grid-gap: 0.5em;

  &:not(.public-nav) {
    @include themedBackgroundColor($color-header-bg);
  }
}
.menu-icon {
  font-size: 24px;
  @include themedTextColor($color-bdPrimary);
  align-self: center;
  margin-right: 0.6em;
  cursor: pointer;
  user-select: none;
  &:hover,
  &:active {
    @include themedTextColor($color-bdBlack);
  }
}
.user-icon-menu {
  grid-area: user-icon-menu;
  display: none;
  @include tablet {
    display: block;
  }
}

.notification-icon {
  grid-area: notification-icon;
  position: relative;
  display: inline-block;
}

.download-icon {
  grid-area: download-icon;
  position: relative;
  display: inline-block;
}

.notification-badge {
  @include themedBackgroundColor($color-bdPrimary);
  @include themedTextColor($color-bdWhite);
  font-weight: bold;
  position: absolute;
  top: 3px;
  right: -5px;
  border-radius: 50%;
  padding: 2px 0px;
  width: 2em;
  height: 2em;
  font-size: $font-size-xs;
  text-align: center;
}

.nav-icon {
  font-size: 30px;
  vertical-align: middle;
}
</style>
