var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['nav-view-grid', { ['no-sidebar']: !_setup.isSidebarShown }]},[_vm._t("top-nav",function(){return [_c(_setup.AppLogo,{staticClass:"app-logo"}),(_setup.authStore.isLoggedIn)?_c(_setup.PrivateTopNav,{staticClass:"header"}):_c(_setup.PublicTopNav,{staticClass:"header"})]}),_c(_setup.BoxGrid,{attrs:{"alignV":"start"}},[_c(_setup.BoxGridItem,{attrs:{"align-self":"stretch","cols":"12"}},[_c(_setup.RouterView,{attrs:{"name":"banner"}})],1)],1),(_setup.isSidebarShown)?_c(_setup.SideNav,{staticClass:"sidebar"},[_vm._t("nav-menu")],2):_vm._e(),_c('div',{ref:"mainAreaDiv",staticClass:"main-area"},[_c('div',{class:[
        'main-area-inner',
        {
          'with-sidebar': _setup.isSidebarShown,
          'with-sidebar-menu': _setup.isSidebarShown && _setup.hasSidebarView,
        },
      ]},[_vm._t("default",function(){return [_c(_setup.RouterView)]})],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }