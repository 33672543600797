<script setup lang="ts">
import { computed, ref } from 'vue';
import NotificationList from 'bd-common/src/components/notifications/NotificationsList.vue';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { VButton } from 'ah-common-lib/src/common/components';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { useManagedListing } from 'ah-common-lib/src/listing/useManagedListing';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useServices } from 'bd-common/src/services';
import {
  Notification,
  NotificationAlertType,
  PaginatedResponse,
  fileExportNotifications,
  publicNotifications,
} from 'ah-api-gateways';
import ListPagination from '../list/ListPagination.vue';
import LoadingSplash from '../common/LoadingSplash.vue';

const props = withDefaults(
  defineProps<{
    type?: 'notification' | 'file_export';
  }>(),
  {
    type: 'notification',
  }
);

const notificationsStore = useNotificationsStore();

const services = useServices();

const reqManager = useRequestManager().manager;

const alertTypes = [NotificationAlertType.REGULAR, NotificationAlertType.URGENT];

const notificationTypes = computed(() =>
  props.type === 'notification' ? publicNotifications : fileExportNotifications
);

const listing = useManagedListing({
  reqManager,
  fields: ref([]),
  refs: {
    paginationQueryParam: ref('pageData'),
  },
  loadDataRequest(query) {
    return services.notification.listNotifications({
      ...query,
      sort: 'createdAt',
      sortDirection: 'DESC',
      pageSize: 10,
      alertType: alertTypes,
      type: notificationTypes.value,
    });
  },
});

const isNotificationEmpty = computed(
  () =>
    (props.type === 'notification' ? notificationsStore.notifications : notificationsStore.fileExportNotifications)
      .length === 0
);

const unreadCount = computed(() =>
  props.type === 'notification' ? notificationsStore.unreadCount : notificationsStore.fileExportUnreadCount
);

const isAllRead = computed(() => unreadCount.value === 0);

const tableData = computed(() => ({ ...listing.refs.tableData.value, ...listing.refs.sortAndPageParams.value }));

const notifications = computed(() => tableData.value?.list || []);

const loadingData = computed(() => listing.bindings.dataLoadState === 'pending');

const markAllAsRead = () => {
  notificationsStore.markAllAsRead(props.type === 'notification' ? 'notifications' : 'fileExports').then(() => {
    // Casting tableData.value to PaginatedResponse<Notification> as we can trust it isn't a Partial within this context
    const data = listing.refs.tableData.value as any as PaginatedResponse<Notification>;
    if (data) {
      listing.setData({
        ...data,
        list: (data.list || [])?.map((i) => ({
          ...i,
          read: true,
        })),
      });
    }
  });
};

const title = computed(() => (props.type === 'notification' ? 'All Notifications' : 'All Downloads'));

function setPage(page: number) {
  listing.refs.sortAndPageParams.value = { ...listing.refs.sortAndPageParams.value, pageNumber: page };
}

function onMarkedAsRead(markedRead: Notification) {
  const notification = notifications.value.find((n) => n.id === markedRead.id);
  if (notification) {
    notification.read = true;
  }
}

listing.loadData();
</script>

<template>
  <div class="paginated-notifications">
    <div class="all-notification-header">
      <h2 class="all-title">{{ title }}</h2>
      <VButton
        :disabled="isAllRead || isNotificationEmpty"
        class="btn-secondary mark-as-read ml-auto"
        @click="markAllAsRead"
      >
        <MaterialIcon class="mr-1 mark-as-read-icon" icon="done_all" />
        <span>Mark all as read</span>
      </VButton>
    </div>

    <NotificationList @marked-as-read="onMarkedAsRead" :notifications="notifications" />
    <ListPagination class="paginator" :data="tableData" v-if="listing.refs.tableData.value" @set-page="setPage" />
    <LoadingSplash v-if="loadingData" class="loading-overlay" />
  </div>
</template>

<style scoped lang="scss">
.paginated-notifications {
  position: relative;
  min-height: 300px;
}
.all-notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  .all-title {
    font-size: $h3-font-size;
    font-weight: 400;
  }
}

.mark-as-read {
  @include themedTextColor($color-bdPrimary);
  background: transparent;
  font-weight: 600;
  font-size: $font-size-base;
  border: none;

  .mark-as-read-icon {
    font-size: 1.7em;
    line-height: 0;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
  }

  &:disabled {
    @include themedTextColor($color-bdMediumGrey);
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
