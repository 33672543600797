<script setup lang="ts">
import Vue, { computed, ref, useSlots } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import BaseVueSelect from 'vue-select';
import { getState } from '../../helpers/formHelpers';
import { FieldOption, FormSelectFieldSearchEvent, FieldOptionObj, AppendToBodyOptions } from '../../interfaces/form';
import isEqual from 'lodash/isEqual';
import { createPopper } from '@popperjs/core';
import { cloneDeep } from 'lodash';

// We need to replace the VueSelect.select method with one we can hook into
// The original select method is disregardable, as we set the value ourselves
const VueSelect = Vue.extend({
  extends: BaseVueSelect,
  methods: {
    select(option: any) {
      this.$emit('option-selected', option);
    },
  },
});

const props = defineProps(makeUseFormElementProps<FieldModel, any>());

const emit = defineEmits(makeUseFormElementEmits<any>());

const slots = useSlots();

const selectComponent = ref<InstanceType<typeof BaseVueSelect>>();

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, inputClass, dirtyOnInput, placeholder: basePlaceHolder } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName, getModelState, setValue: baseSetValue, emitFormEvent } = formElement;

const showBadges = modelStateRef('showBadges', false);

const itemsCountLabel = modelStateRef('itemsCountLabel', 'items');

const maxFulltextLabels = modelStateRef('maxFulltextLabels', 3);

const useSwitches = modelStateRef('switches', false);

const multiple = modelStateRef('multiple', false);

const clearable = modelStateRef('clearable', false);

const searchable = modelStateRef('searchable', true);

const filterable = modelStateRef('filterable', true);

const selectOpenIndicatorComponent = modelStateRef('selectOpenIndicatorComponent');

const closeOnSelect = modelStateRef('closeOnSelect', !multiple.value);

const loading = computed(() => {
  const loading = getModelState('loading', false);

  return typeof loading === 'function' ? loading() : loading;
});

const placeholder = computed(() => (loading.value ? 'Loading...' : basePlaceHolder.value));

const selectable = computed(() => {
  const baseSelectable = getModelState('selectable', () => true);
  const maxItems = getModelState('maxItems', 0);

  return (option: any) => {
    if (typeof baseSelectable === 'function' && !baseSelectable(option)) {
      return false;
    }
    if (multiple.value && maxItems > 0) {
      return field.value.$model.length < maxItems;
    }
    return true;
  };
});

const selectOnTab = modelStateRef('selectOnTab', true);

const appendToBody = computed(() => !!getModelState('appendToBody', false));

const appendToBodyOptions = computed(() => (getModelState('appendToBody', {}) ?? {}) as AppendToBodyOptions);

const showSelectedOptions = modelStateRef('showSelectedOptions', true);

const calculateTextLabel = modelStateRef('calculateLabel', true);

const loadingText = modelStateRef('loadingText', undefined);

const options = computed<FieldOptionObj[]>(() => {
  const options = getState(model.value, 'options', []);
  return typeof options === 'function' ? options() : options;
});

const filteredOptions = computed(() => {
  if (showSelectedOptions.value) {
    return options.value;
  }

  return options.value.filter((o: any) => {
    let value = field.value.$model;
    if (!multiple.value) {
      value = [value];
    }

    for (let i = 0; i < value.length; i++) {
      if (isEqual(o, value[i]) || isEqual(o.value, value[i])) {
        return false;
      }
    }

    return true;
  });
});

const itemsText = computed(() => {
  if (!field.value.$model || field.value.$model.length === 0) {
    return placeholder.value;
  }
  if (field.value.$model.length <= maxFulltextLabels.value) {
    return field.value.$model
      .map((i: any) => {
        const option = options.value.find((o: any) => o.value === i);
        return option ? option.label : i;
      })
      .join(', ');
  }
  return `${field.value.$model.length} ${itemsCountLabel.value}`;
});

function isOptionSelected(option: FieldOptionObj) {
  return field.value.$model.includes(option.value);
}

function onSearch(search: string, loading: (val: boolean) => void) {
  emitFormEvent({
    search,
    loading,
    event: 'form-field-select-search',
  } as FormSelectFieldSearchEvent);
}

function onOptionSelected(option: any) {
  let selectedValue = option;
  if (multiple.value) {
    const index = optionIndex(option);
    const value = [...field.value.$model];
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(cloneDeep(option.value));
    }
    selectedValue = value;
  }

  if (closeOnSelect.value && selectComponent.value) {
    // In composition APi, VueSelect typings are not retrieved, so need to cast as any to reach VueSelect's searchEl
    (selectComponent.value as any).searchEl.blur();
  }
  setValue(selectedValue);
}

function remove(value: any) {
  const option = options.value.find((opt) => opt.value === value);
  if (option) {
    onOptionSelected(option);
  }
}

function optionIndex(option: any) {
  if (!field.value.$model || field.value.$model.length === 0) {
    return -1;
  }
  return field.value.$model.findIndex((i: any) => i === option.value);
}

function reduceValue(item: FieldOption) {
  return typeof item === 'string' ? item : item.value;
}

function localSlotName(slotName: string) {
  return slotName.substr(model.value.$path.length + 1);
}

function getSelectSlotNames() {
  return Object.keys(slots).filter((k) => {
    const innerSlotName = k.split(':')[1];
    if (!innerSlotName) {
      return false;
    }
    return !!getSlotName(innerSlotName);
  });
}

function getValueText(value: any) {
  if (loadingText.value && loading.value) {
    return loadingText.value;
  }

  const option = options.value.find((o: any) => {
    if (typeof o === 'object') {
      return o === value || o.value === value || o.value === value.label;
    }

    return o === value;
  });

  if (option) {
    return option.label ?? option;
  }

  return value.label ?? value;
}

function setValue(event: any) {
  if (event && (event.value === 0 || event.value === null || event.value === false)) {
    // ensuring value is set if it is 0 or null since
    // (event && event.value) || event will return event if value === 0 || value === null
    baseSetValue(event.value, !field.value.$dirty && !dirtyOnInput.value);
    return;
  }
  baseSetValue((event && event.value) || event, !field.value.$dirty && !dirtyOnInput.value);
}

function withPopper(dropdownList: HTMLElement, component: Vue) {
  /**
   * We need to explicitly define the dropdown width since
   * it is usually inherited from the parent with CSS.
   */

  const popper = createPopper(component.$el as any, dropdownList, {
    placement: appendToBodyOptions.value.placement ?? 'bottom',
    modifiers: [
      /**
       * Make the dropdown the same width as the parent
       */
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect: ({ state }) => {
          state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
        },
      },
      /**
       * Place dropdown 1px in in the direction it is rendered
       * This overlaps a 1px border, by default
       */
      {
        name: 'offset',
        options: {
          offset: appendToBodyOptions.value.offset ?? [0, -1],
        },
      },
      {
        name: 'positioningClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle('drop-up', state.placement === 'top');
          component.$el.classList.toggle('drop-down', state.placement !== 'top');
          dropdownList.classList.toggle('drop-up', state.placement === 'top');
          dropdownList.classList.toggle('drop-down', state.placement !== 'top');
        },
      },
    ],
  });

  setTimeout(() => {
    popper.forceUpdate();
  });

  return () => popper.destroy();
}
</script>

<template>
  <div class="align-items-center" :class="{ 'row mx-0': getSlotName('prepend', false) }">
    <template v-if="getSlotName('prepend', false)">
      <slot :name="getSlotName('prepend')" v-bind="{ field, model }" />
    </template>
    <VueSelect
      ref="selectComponent"
      :class="['field-group-field-input', inputClass, { readonly, multiple }]"
      :value="field.$model"
      :reduce="reduceValue"
      :multiple="multiple"
      :disabled="readonly"
      :clearable="clearable"
      :placeholder="placeholder"
      :searchable="searchable"
      :getOptionLabel="calculateTextLabel ? getValueText : undefined"
      :closeOnSelect="closeOnSelect"
      :appendToBody="appendToBody"
      :selectable="selectable"
      :selectOnTab="selectOnTab"
      :filterable="filterable"
      :options="filteredOptions"
      :calculatePosition="withPopper"
      :loading="loading"
      @option-selected="onOptionSelected"
      @input="setValue"
      @search="onSearch"
      @search:blur="() => field.$touch()"
    >
      <template #option="option">
        <div
          :class="{ 'custom-checkbox': useSwitches === false, 'custom-switch': useSwitches !== false }"
          class="checkbox-holder custom-control custom-checkbox"
          v-if="multiple"
        >
          <input
            :id="`tags-filter-select-${option.label}`"
            :checked="isOptionSelected(option)"
            type="checkbox"
            class="custom-control-input"
          />
          <label :for="`tags-filter-select-${option.label}`" class="custom-control-label">
            <span class="label-text">
              {{ option.label }}
            </span>
          </label>
        </div>
      </template>
      <template #search="{ attributes, events }">
        <input v-if="multiple" class="vs__search" v-bind="attributes" v-on="events" :placeholder="itemsText" />
      </template>
      <template #selected-option="value" v-if="!getSlotName('selected-option')"> {{ getValueText(value) }} </template>
      <template
        #open-indicator="{ attributes }"
        v-if="selectOpenIndicatorComponent && !getSlotName('open-indicator', false)"
      >
        <component :is="selectOpenIndicatorComponent" v-bind="attributes" />
      </template>
      <template v-for="slot in getSelectSlotNames()" v-slot:[localSlotName(slot)]="selectScope">
        <slot :name="slot" v-bind="{ field, model, selectScope }" />
      </template>
    </VueSelect>
    <template v-if="showBadges">
      <VBadge class="badge-cell" v-for="val in field.$model" :key="val">
        <span class="badge-label">
          {{ getValueText(val) }}
          <div @click="remove(val)" class="icon"><CloseIcon /></div>
        </span>
      </VBadge>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.badge-cell {
  @include themedBorderColor($color-dark-primary, $color-primary);
  background: transparent;
  border: 1px solid;
  margin-top: 1em;
  margin-right: 1em;
  font-size: $font-size-xxs;
  .badge-label,
  .icon {
    @include themedTextColor($color-dark-primary, $color-primary);
    display: inline-flex;
    .icon > * {
      cursor: pointer;
      width: 0.5em;
      font-weight: 2em;
      margin-left: 1em;
      ::v-deep path {
        @include themedPropColor('fill', $color-dark-primary, $color-primary, '', ' !important ');
      }
    }
  }
}
</style>
