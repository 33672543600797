import VueI18n from 'vue-i18n';
import _Vue, { PluginObject } from 'vue';

export interface I18nPluginOptions {
  messages: VueI18n.LocaleMessages;
  locale: string;
}

export default {
  install: function install(Vue: typeof _Vue, options?: I18nPluginOptions) {
    Vue.use(VueI18n);
    i18n = new VueI18n(options);
  },
} as PluginObject<I18nPluginOptions>;

let i18n: VueI18n | null = null;

export function useI18n(): VueI18n {
  if (!i18n) {
    throw 'VueI18n used before plugin setup!';
  }
  return i18n;
}
