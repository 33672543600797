<script lang="ts" setup>
import { PropType, computed } from 'vue';
import CallTextBalloon from '../../../common/components/CallTextBalloon.vue';
import VButton from '../../../common/components/VButton.vue';
import { TreeOptionsBranch, TreeOptionsBranchOption } from '../../interfaces/form';

const props = defineProps({
  branch: {
    type: Object as PropType<TreeOptionsBranch>,
    required: true,
  },
  valueChain: {
    type: Array as PropType<any[]>,
    required: true,
  },
  showCallText: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits({
  choice: (_choices: TreeOptionsBranchOption[]) => true,
});

const value = computed(() => {
  return props.valueChain && props.valueChain[0];
});

const nextValueChain = computed(() => {
  return props.valueChain.slice(1);
});

const selectedOption = computed(() => {
  return props.branch.options.find((option) => value.value === option.value);
});

function onChoice(choice: TreeOptionsBranchOption) {
  if (value.value !== choice.value) {
    emit('choice', [choice]);
  }
}

function onBranchedChoice(choices: TreeOptionsBranchOption[]) {
  emit('choice', [selectedOption.value!, ...choices]);
}
</script>

<template>
  <div class="choice-branch">
    <p v-if="branch.text" class="branch-text">
      {{ branch.text }}
    </p>
    <CallTextBalloon v-if="showCallText && branch.callText" class="branch-call-text">
      {{ branch.callText }}
    </CallTextBalloon>
    <div class="choice-branch-leaves">
      <div v-for="(option, index) in branch.options" :key="index" class="choice-leaf">
        <VButton :class="value !== option.value ? 'secondary' : 'selected'" class="big-btn" @click="onChoice(option)">
          {{ option.label }}
        </VButton>
      </div>
    </div>
    <ChoiceBranch
      v-if="selectedOption && selectedOption.next"
      :branch="selectedOption.next"
      :value-chain="nextValueChain"
      @choice="onBranchedChoice"
    />
  </div>
</template>
