import { computed, getCurrentInstance } from 'vue';
import { BaseFormModel, FieldModel, FormEvent } from '../interfaces';
import { getFieldError, getState, setState, toggleState } from '../helpers';
import { UseFormElementOptions } from './useFormElementInterfaces';

export function useFormElement<M extends BaseFormModel = BaseFormModel, V = any>(options: UseFormElementOptions<M, V>) {
  const { props, emit } = options;

  let component = options.component as Vue;

  if (!component) {
    try {
      component = getCurrentInstance()!.proxy;
    } catch (e) {
      throw 'No component found for useFormElement composable. It must be used within a setup function.';
    }
  }

  /**
   * Returns matching slot name to file path, if one exists
   * If defaultToExact is true, defaults to exact path name (set to false for v-if checks)
   *
   * Allows wildcards in path:
   *    form.model.*:before will match form.model.foo:before and form.model.bar:before
   */
  function getSlotName(name?: string, defaultToExact = true) {
    // Check for path using * as wild
    const pathSegments = (props.model as BaseFormModel).$path.split('.');
    const found = Object.keys(component.$scopedSlots).find((k: string) => {
      const [slotParts, slotInnerSlotName] = k.split(':');
      const slotSegments = slotParts.split('.');

      if (slotSegments.length !== pathSegments.length || name !== slotInnerSlotName) {
        return false;
      }
      for (let i = 0; i < slotSegments.length; i++) {
        if (slotSegments[i] !== '*' && pathSegments[i] !== slotSegments[i]) {
          return false;
        }
      }
      return true;
    });

    if (found) {
      return found;
    }

    // Check for exact match, return if found
    const exactName = `${(props.model as BaseFormModel).$path}${name ? ':' + name : ''}`;
    if (component.$scopedSlots[exactName] || defaultToExact) {
      return exactName;
    }
  }

  function setValue(value: any, keepPristine = false) {
    props.field.$model = value;
    if (keepPristine) {
      props.field.$reset();
    }
    emitFormEvent({
      event: 'form-field-set-value',
    });
  }

  function emitFormEvent(event: Omit<FormEvent<V>, 'model' | 'field'>) {
    emit('form-event', { ...event, model: props.model, field: props.field } as FormEvent<V>);
  }

  function getModelState(
    key: string,
    defaultValue?: any,
    useHierarchy: boolean | ((curr: any, parent: any) => any) = false
  ) {
    return getState(props.model as BaseFormModel, key, defaultValue, useHierarchy);
  }

  function toggleModelState(key: string) {
    return toggleState(props.model as BaseFormModel, key);
  }

  function setModelState(key: string, value: any) {
    return setState(props.model as BaseFormModel, key, value);
  }

  function modelStateRef<T = any>(
    key: string,
    defaultValue?: any,
    useHierarchy: boolean | ((curr: any, parent: any) => any) = false
  ) {
    return computed(() => getModelState(key, defaultValue, useHierarchy) as T);
  }

  function getError(error: string) {
    return getFieldError(error, props.field, props.model as FieldModel);
  }

  function errorRef(error: string) {
    return computed(() => getError(error));
  }

  return {
    model: computed(() => options.props.model as M),
    field: computed(() => options.props.field),
    getSlotName,
    component,
    getError,
    setValue,
    errorRef,
    emitFormEvent,
    getModelState,
    toggleModelState,
    setModelState,
    modelStateRef,
  };
}
