<script lang="ts" setup>
import SideNav from '../../nav/SideNav.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import BoxGrid from '../boxGrid/BoxGrid.vue';
import BoxGridItem from '../boxGrid/BoxGridItem.vue';
import { RouterView } from 'vue-router';
import PrivateTopNav from 'bd-common/src/components/nav/PrivateTopNav.vue';
import PublicTopNav from 'bd-common/src/components/nav/PublicTopNav.vue';
import AppLogo from '../AppLogo.vue';

const props = withDefaults(
  defineProps<{
    hideSidebar?: boolean;
  }>(),
  {
    hideSidebar: false,
  }
);

const authStore = useAuthStore();

const route = useRoute();

const isSidebarShown = computed(() => props.hideSidebar === false && authStore.isLoggedIn);

const hasSidebarView = computed(() => !!route.matched.find((r) => !!r.instances.sidebar));

const mainAreaDiv = ref<HTMLDivElement>();

defineExpose({
  scrollView(y: number) {
    if (mainAreaDiv.value) {
      mainAreaDiv.value.scrollTop = y;
    }
  },
});
</script>

<template>
  <div :class="['nav-view-grid', { ['no-sidebar']: !isSidebarShown }]">
    <slot name="top-nav">
      <AppLogo class="app-logo" />
      <PrivateTopNav class="header" v-if="authStore.isLoggedIn" />
      <PublicTopNav class="header" v-else />
    </slot>
    <BoxGrid alignV="start">
      <BoxGridItem align-self="stretch" cols="12">
        <RouterView name="banner" />
      </BoxGridItem>
    </BoxGrid>
    <SideNav class="sidebar" v-if="isSidebarShown">
      <slot name="nav-menu" />
    </SideNav>
    <div class="main-area" ref="mainAreaDiv">
      <div
        :class="[
          'main-area-inner',
          {
            'with-sidebar': isSidebarShown,
            'with-sidebar-menu': isSidebarShown && hasSidebarView,
          },
        ]"
      >
        <slot>
          <RouterView />
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.nav-view-grid {
  height: 100%;
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: $top-nav-height min-content 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar subheader'
    'sidebar main';

  &.no-sidebar {
    grid-template-columns: auto;
    grid-template-areas:
      'header'
      'subheader'
      'main';

    .sidebar {
      display: none;
    }
  }

  @include tablet {
    grid-template-rows: $top-nav-height min-content 1fr;
  }
  @include desktop {
    grid-template-rows: $top-nav-height min-content 1fr;
  }

  > .main-area {
    grid-area: main;
    overflow: auto;
    align-self: stretch;
    position: relative;

    .main-area-inner {
      height: 100%;
      max-width: 2000px;
      margin: 0 auto;
    }
  }

  > .header {
    grid-area: header;
  }

  > .subheader {
    grid-area: subheader;
  }

  > .sidebar {
    grid-area: sidebar;
  }
}
.app-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
  position: absolute;
  top: 50px;
  left: 25px;
  z-index: 1;
}
</style>
