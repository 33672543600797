<script setup lang="ts">
import { config } from '@/config';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { RouterLink } from 'vue-router';

interface MenuLink {
  text: string;
  link: string;
  icon: string;
}

const menuLinks: MenuLink[] = [
  {
    text: 'Trading',
    link: '/app/trading',
    icon: 'multiple_stop',
  },
  {
    text: 'Accounts',
    link: '/app/accounts',
    icon: 'account_balance_wallet',
  },
  {
    text: 'Payments',
    link: '/app/payments',
    icon: 'business_center',
  },
  {
    text: 'Partner Offerings',
    link: '/app/partner-offerings',
    icon: 'handshake',
  },
  ...(config.showDevTools
    ? [
        {
          text: 'Kitchen Sink',
          link: '/app/kitchen-sink',
          icon: 'countertops',
        },
      ]
    : []),
  {
    text: 'Settings',
    link: '/app/settings',
    icon: 'settings',
  },
];
</script>

<template>
  <ul class="main">
    <RouterLink
      :to="menuLink.link"
      custom
      v-slot="{ navigate, isActive }"
      v-for="(menuLink, index) in menuLinks"
      :key="index"
    >
      <li
        :class="['menu-link', { ['router-link-active']: isActive }]"
        @click="navigate"
        @keypress.enter="navigate()"
        role="link"
      >
        <MaterialIcon class="menu-link-icon" :icon="menuLink.icon" />
        <span class="menu-link-text"> {{ menuLink.text }} </span>
      </li>
    </RouterLink>
  </ul>
</template>
