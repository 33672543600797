<script setup lang="ts">
import PaginatedNotifications from 'bd-common/src/components/notifications/PaginatedNotifications.vue';
import { useRoute, useRouter } from 'vue-router/composables';

const router = useRouter();

const route = useRoute();

function goBack() {
  const fromRoute = route.query?.fromRoute;

  router.push({
    path: typeof fromRoute === 'string' ? fromRoute : '/app/dashboard',
  });
}
</script>

<template>
  <div class="view-area">
    <div class="row">
      <div class="col-3">
        <VButton class="btn-ghost" @click="goBack"> Go Back </VButton>
      </div>
      <div class="col-6 all-notifications">
        <PaginatedNotifications type="file_export" />
      </div>
    </div>
  </div>
</template>
