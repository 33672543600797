import { setupApp } from 'bd-common/src/setupApp';
import { config as baseConfig } from './config';
import { AhTradesPlugin, AhTradesPluginOptions } from 'ah-trades';
import { useWalletsStore } from 'bd-common/src/store/walletsStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { useSettingsStore } from 'bd-common/src/store/settingsStore';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { useI18n } from 'ah-common-lib/src/i18n';
import { useServices } from 'bd-common/src/services';
import { AhWalletsPlugin, AhWalletsPluginOptions } from 'ah-wallets';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import ManagedLoadingOverlay from 'ah-common-lib/src/common/components/overlays/ManagedLoadingOverlay.vue';
import BoxGridItem from 'bd-common/src/components/common/boxGrid/BoxGridItem.vue';
import BoxGridBlock from 'bd-common/src/components/common/boxGrid/BoxGridBlock.vue';
import BoxGrid from 'bd-common/src/components/common/boxGrid/BoxGrid.vue';
import { AhBeneficiariesPlugin, AhBeneficiariesPluginOptions } from 'ah-beneficiaries';
import DataRow from 'ah-common-lib/src/common/components/DataRow.vue';
import { useFeatureFlagStore } from 'ah-common-stores';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { useConfig } from 'bd-common/src/config';

setupApp({
  ...baseConfig,
  postSetup: async (vue) => {
    const i18n = useI18n();
    const services = useServices();
    const config = useConfig();

    // FIXME GT-1068 components loaded to allow for Trading screens, will be moved out to shared components as part of https://assurehedge.atlassian.net/browse/GT-1068

    vue.component('LoadingOverlay', LoadingOverlay);
    vue.component('ManagedLoadingOverlay', ManagedLoadingOverlay);
    vue.component('BoxGridItem', BoxGridItem);
    vue.component('BoxGridBlock', BoxGridBlock);
    vue.component('BoxGrid', BoxGrid);
    vue.component('DataRow', DataRow);
    vue.component('IconArrowRight', { render: (h) => h(MaterialIcon, { props: { icon: 'arrow_forward' } }) });
    vue.component('IconRefresh', { render: (h) => h(MaterialIcon, { props: { icon: 'autorenew' } }) });
    vue.component('IconAlertCircle', { render: (h) => h(MaterialIcon, { props: { icon: 'error' } }) });
    vue.component('InformationIcon', { render: (h) => h(MaterialIcon, { props: { icon: 'info' } }) });

    vue.use<AhBeneficiariesPluginOptions>(AhBeneficiariesPlugin, {
      state: {
        mediaQuery: vue.prototype.$mediaQuery,
        showDevTools: config.showDevTools,
        theme: vue.theme.val,
        store: { useSettingsStore, useAuthStore },
        i18n,
        services,
        toast: vue.toast,
      },
    });

    vue.use<AhTradesPluginOptions>(AhTradesPlugin, {
      state: {
        theme: vue.theme.val,
        config,
        mediaQuery: vue.prototype.$mediaQuery,
        showDevTools: config.showDevTools,
        store: { useWalletsStore, useNotificationsStore, useSettingsStore, useAuthStore, useFeatureFlagStore },
        i18n,
        services,
        toast: vue.toast,
      },
    });

    vue.use<AhWalletsPluginOptions>(AhWalletsPlugin, {
      state: {
        mediaQuery: vue.prototype.$mediaQuery,
        store: { useWalletsStore, useSettingsStore, useAuthStore, useNotificationsStore },
        i18n,
        services,
        toast: vue.toast,
      },
    });
  },
}).then((app) => {
  app.$mount('#app');
});
