<script setup lang="ts">
import { ref, reactive, watch, onBeforeMount } from 'vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { ComplianceErrorCodes, ExportFileType, LegalFileType } from 'ah-api-gateways';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FormValidation, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { checkboxField } from 'ah-common-lib/src/form/models';
import { useServices } from 'bd-common/src/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { HttpError } from 'ah-requests';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const emit = defineEmits<{
  (e: 'update:validation', value: FormValidation): void;
  (e: 'submitted'): void;
}>();

const termsAndConditionsHtml = ref('');

const requestManager = useRequestManager().manager;

const services = useServices();

const authModule = useAuthStore();

const termsAndConditionsFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'termsAndConditions',
    fieldType: 'form',
    fields: [
      checkboxField('termsAndConditions', 'I confirm I have read and agree with the Terms and Conditions.', false, {
        required: true,
        errorMessages: {
          mustAccept: 'Please confirm',
        },
      }),
    ],
  }),
  validation: null,
});

onBeforeMount(() => {
  requestManager
    .sameOrCancelAndNew(
      'loadTOSHtml',
      services.customerReference.getLegalDocument(LegalFileType.TOS, ExportFileType.HTML)
    )
    .subscribe((res) => (termsAndConditionsHtml.value = res.html));
});

function approveTermsRequest() {
  return services.client
    .acceptTermsAndConditions(authModule.loggedInIdentity?.client?.id!, {
      options: {
        errors: {
          silent(error) {
            return error.response?.data.code === ComplianceErrorCodes.TERMS_AND_CONDITIONS_ALREADY_APPROVED;
          },
        },
      },
    })
    .pipe(
      catchError((error: HttpError) => {
        if (error.response?.data.code === ComplianceErrorCodes.TERMS_AND_CONDITIONS_ALREADY_APPROVED) {
          return of(null);
        }
        return throwError(error);
      })
    );
}

function handleFinishButtonClick() {
  termsAndConditionsFM.validation?.$touch();
  if (termsAndConditionsFM.validation?.$invalid) {
    return;
  }
  requestManager.currentOrNew('approveTerms', approveTermsRequest()).subscribe(() => {
    emit('submitted');
  });
}

function downloadTerms() {
  requestManager
    .sameOrCancelAndNew(
      'downloadTerms',
      services.customerReference.downloadLegalDocument(LegalFileType.TOS, ExportFileType.PDF)
    )
    .subscribe();
}

watch(
  () => termsAndConditionsFM.validation,
  () => termsAndConditionsFM.validation && emit('update:validation', termsAndConditionsFM.validation)
);
</script>

<template>
  <div class="terms-and-condition-step">
    <div class="card mb-4">
      <div class="card-body">
        <div class="head-section">
          <h5 class="mt-2">Terms and Conditions</h5>
          <VButton
            class="btn-secondary"
            @click="downloadTerms"
            :loading="requestManager.requestStates.downloadTerms === 'pending'"
            ><MaterialIcon icon="download" /> Download
          </VButton>
        </div>
      </div>
      <div class="embed-file">
        <LoadingIcon v-if="requestManager.requestStates.loadTOSHtml === 'pending'" />
        <div v-html="termsAndConditionsHtml" v-else />
      </div>
      <div
        :class="[
          'confirm-terms-conditions',
          {
            'invalid-styling':
              termsAndConditionsFM.validation &&
              termsAndConditionsFM.validation.$invalid &&
              termsAndConditionsFM.validation.$dirty,
          },
        ]"
      >
        <ValidatedForm :fm="termsAndConditionsFM.form" :validation.sync="termsAndConditionsFM.validation" />
      </div>
    </div>
    <div class="buttons mt-3">
      <span class="required-text">* Mandatory</span>
      <VButton
        :loading="requestManager.requestStates.approveTerms === 'pending'"
        class="btn-primary"
        @click="handleFinishButtonClick"
      >
        Finish <MaterialIcon icon="arrow_forward" />
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms-and-condition-step {
  color: getColor($color-bdDarkBlue);

  .head-section {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    font-weight: 600;
  }

  .confirm-terms-conditions {
    padding-left: 2rem;
    height: 3.5rem;
  }

  ::v-deep {
    .custom-checkbox .custom-control-label {
      font-size: $font-size-sm;
    }
  }

  .embed-file {
    position: relative;
    height: 35rem;
    overflow: auto;
    border: 1px solid transparent;
    border-top-color: getColor($color-bdMediumGrey);
    border-bottom-color: getColor($color-bdMediumGrey);
    padding: 1rem;
    margin-bottom: 2.5rem;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);

    ::v-deep {
      li {
        display: block;
        position: relative;
        padding-bottom: 0.3em;
      }

      li:before {
        content: counters(item, '.') '. ';
        white-space: nowrap;
        position: absolute;
        counter-increment: item;
        top: 0;
        left: -3.5em;
      }

      li.addon:before {
        content: '';
        display: none !important;
      }

      li.subclause:before {
        content: '(' counter(item, lower-alpha) ')';
      }

      div > ol > li {
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 2em;
      }

      div > ol > li > ol {
        padding-left: 0em;
        padding-top: 2em;
      }

      ol {
        text-transform: none;
        font-weight: normal;
        padding-left: 3.5em;
        counter-reset: item;
      }
    }
  }

  .confirm-terms-conditions {
    padding-top: 0.5rem;
    position: relative;

    &.invalid-styling::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      width: 8px;
      border-radius: 2px;
      background: getColorHSLA($color-bdDanger, 45%);
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .required-text {
      color: getColor($color-bdDanger);
      font-size: $font-size-sm;
      font-weight: 600;
    }
  }
}
</style>
