<script lang="ts" setup>
import { VCol } from 'ah-common-lib/src/common/components';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import ManagedLoadingOverlay from 'ah-common-lib/src/common/components/overlays/ManagedLoadingOverlay.vue';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import { computed, useAttrs } from 'vue';

type ClassPropEl = string | { [key: string]: boolean };

const props = withDefaults(
  defineProps<{
    itemClass?: ClassPropEl | ClassPropEl[];
    animateTransitions?: boolean | string;
    useLoadingOverlay?: boolean | string;
    overlayType?: 'managed' | 'simple';
    loadingOverlayProps?: any;
    title?: string;
  }>(),
  {
    itemClass: '',
    animateTransitions: false,
    useLoadingOverlay: false,
    overlayType: 'managed',
    loadingOverlayProps: () => {},
  }
);

const attrs = useAttrs();

const overlayProps = computed(() => ({ variant: 'box', opacity: '0.85', ...props.loadingOverlayProps }));

const showLoadingOverlay = computed(() => props.useLoadingOverlay !== false);

const bColAttrs = computed(() => ({
  'align-self': 'start',
  ...mapKeys(attrs, (v, k) => kebabCase(k)),
}));
</script>

<template>
  <VCol v-bind="bColAttrs" v-on="$listeners">
    <div :class="['box-grid-item', itemClass, { 'stretch-v': bColAttrs['align-self'] === 'stretch' }]">
      <slot name="header">
        <div class="box-grid-item-header" v-if="title">
          <span class="header-title">{{ title.toUpperCase() }}</span>
          <span class="header-line" />
        </div>
      </slot>
      <template v-if="showLoadingOverlay">
        <LoadingOverlay v-if="overlayType === 'simple'" v-bind="overlayProps" v-on="$listeners">
          <slot />
        </LoadingOverlay>
        <ManagedLoadingOverlay v-else-if="overlayType === 'managed'" v-bind="overlayProps" v-on="$listeners">
          <slot />
        </ManagedLoadingOverlay>
      </template>
      <slot v-else />
    </div>
  </VCol>
</template>

<style lang="scss" scoped>
.box-grid-item {
  display: flex;
  flex-direction: column;
  ::v-deep .loading-overlay {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
  }
  &.stretch-v {
    min-height: 100%;
  }
}
</style>
