<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
import UserMenu from './UserMenu.vue';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import { BModal } from 'bootstrap-vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

const show = ref(false);
const userMenu = ref<HTMLElement>();
const deleteModal = ref<BModal>();

const toggleShow = () => {
  // Run in next tick to avoid click event propagation issues
  setTimeout(() => {
    show.value = !show.value;
  }, 0);
};

let clickListener: (event: MouseEvent) => void;

onMounted(() => {
  clickListener = (event) => {
    if (show.value && (!userMenu.value || !isDescendant(userMenu.value, event.target as Element, true))) {
      show.value = false;
    }
  };

  window.addEventListener('click', clickListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', clickListener);
});

const authStore = useAuthStore();

const logout = () => {
  if (deleteModal.value) {
    deleteModal.value.show?.();
    const onceOk = () => {
      authStore.logout();
      show.value = false;
      deleteModal.value?.$off('ok', onceOk);
    };
    deleteModal.value?.$on('ok', onceOk);
  }
};

const user = ref(authStore.loggedInIdentity);

const initials = computed(() => ((user.value?.firstName || ' ')[0] + (user.value?.lastName || ' ')[0]).toUpperCase());

watch(
  () => authStore.loggedInIdentity,
  (newUser) => {
    user.value = newUser;
    show.value = false;
  }
);
</script>

<template>
  <div @click="toggleShow" id="user-menu-toggle" :class="{ 'menu-open': show }">
    <div class="d-flex justify-content-center align-items-center cursor-pointer">
      <div class="user-badge mr-3" :class="{ 'badge-open': show }">
        {{ initials }}
      </div>
      <p class="user-name-details m-0">{{ user?.firstName }} {{ user?.lastName }}</p>
      <MaterialIcon :class="['menu-toggle', { open: show }]" icon="expand_more" />
    </div>
    <BPopover
      v-if="user"
      placement="bottomright"
      custom-class="arrowless"
      target="user-menu-toggle"
      triggers="click blur"
      :show.sync="show"
    >
      <UserMenu ref="userMenu" @logout="logout" />
    </BPopover>

    <!-- MODALS -->
    <div class="row">
      <div class="col-5">
        <BModal
          :centered="true"
          ok-title="Yes, Log Out"
          cancel-variant="secondary"
          footer-class="justify-content-end pl-5 mb-0"
          ref="deleteModal"
        >
          <template v-slot:modal-title>
            <div class="d-flex align-items-center justify-content-center">
              <MaterialIcon icon="error" class="mr-2 logout-error-icon" />
              <span class="logout-title">Do you want to log out?</span>
            </div>
          </template>
          <template v-slot:default>
            Logging out will terminate your session, so you will be redirected to the login page.
          </template>
        </BModal>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.b-popover {
  z-index: 100;
}

#user-menu-toggle {
  padding: 10px 15px;
  cursor: pointer;

  &.menu-open {
    @include themedTextColor($color-bdBlack);
  }

  .user-badge {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid getColor($color-bdPrimary);
    font-size: $font-size-base;
    font-weight: 500;

    &.badge-open {
      border: 1px solid getColor($color-bdBlack);
    }
  }
}

.b-popover.arrowless .arrow {
  opacity: 0;
}

.user-name-details {
  font-size: $base-font-size;
  font-weight: 600;
}

.menu-toggle {
  cursor: pointer;
  transition: transform 0.6s;
  font-size: 1.7em;
  line-height: 30px;
  vertical-align: middle;

  &.open {
    transform: rotate(180deg);
  }
}

.modal-content {
  padding: 1rem;
  border-radius: 0px;
  width: auto;
  stroke: none;

  .modal-header {
    .modal-title {
      margin-left: 0.7em;
      .logout-error-icon {
        font-size: 1.5em;
        color: getColor($color-bdWarning);
      }

      .logout-title {
        font-size: $base-font-size;
        font-weight: 600;
      }
    }

    .close {
      font-size: 2em;
      vertical-align: middle;
      line-height: 20px;
      color: getColor($color-bdDarkBlue);
    }
  }

  .modal-body {
    font-size: $font-size-base;
    font-weight: 400;
    color: getColor($color-bdDarkBlue);
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
</style>
