var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"currency-exchange form"},[_c('div',{staticClass:"currency-selection"},[_c('div',{staticClass:"from"},[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" Converting from: ")]),_c(_setup.VueSelect,{ref:"sell CurrencySelect",class:[
          'field-group-field-input hide-error currency-select',
          _setup.inputClass,
          { [_setup.inputErrorClass]: _setup.fieldErrorsShown },
        ],attrs:{"value":_setup.sellCurrency,"clearable":false,"options":_setup.sellCurrencies,"disabled":_setup.readonly},on:{"input":_setup.onSellCurrencyChange},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option)?_c('span',{key:optionName,staticClass:"currency-select-option"},[_vm._v(" "+_vm._s(option.label)+" "),(_setup.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(_setup.getCurrencyObj(option.label).symbol)+" ")]):_vm._e()]):_vm._e()]}}})],null,true)})],1),_c('div',{staticClass:"change-currencies"},[_c('div',{staticClass:"change-currencies-button",on:{"click":_setup.switchCurrencies}},[_c(_setup.ExchangeIcon)],1)]),_c('div',{staticClass:"to"},[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" To: ")]),_c(_setup.VueSelect,{class:[
          'field-group-field-input hide-error currency-select',
          _setup.inputClass,
          { [_setup.inputErrorClass]: _setup.fieldErrorsShown },
        ],attrs:{"value":_setup.buyCurrency,"clearable":false,"options":_setup.buyCurrencies,"disabled":_setup.readonly},on:{"input":_setup.onBuyCurrencyChange,"search:blur":_setup.showBlurError},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option.label)?_c('span',{key:optionName,staticClass:"currency-select-option"},[_vm._v(" "+_vm._s(option.label)+" "),(_setup.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(_setup.getCurrencyObj(option.label).symbol)+" ")]):_vm._e()]):_vm._e()]}}})],null,true)})],1),_c(_setup.InfoTooltipIcon,{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_setup.supportText),expression:"supportText"}],staticClass:"tooltip-icon"}),_c('div',{staticClass:"trade-direction"},[_c('div',[(_setup.displaySymbols)?_c('span',{staticClass:"trade-direction-number"},[_vm._v(" 1 "+_vm._s(_setup.getCurrencyObj(_setup.fromCurrency).symbol)+" ")]):_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_setup.fromCurrency)+" 1 ")])]),_c('div',{staticClass:"change-currencies-button",on:{"click":function($event){_setup.isTradeDirectionSellBuy = !_setup.isTradeDirectionSellBuy}}},[_c(_setup.ExchangeIcon)],1),_c('div',[(_setup.displaySymbols)?_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_setup.tradeDirectionRate)+" "+_vm._s(_setup.getCurrencyObj(_setup.toCurrency).symbol)+" ")]):_c('span',{staticClass:"trade-direction-number"},[_vm._v(" "+_vm._s(_setup.toCurrency)+" "+_vm._s(_setup.tradeDirectionRate)+" ")])])])],1),_c('div',{staticClass:"amount-selection"},[_c('div',[_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" "+_vm._s(_setup.currencyTitle)+" ")]),_c('div',{staticClass:"amount-convert"},[_c('div',{staticClass:"amount-type"},[_c(_setup.VueSelect,{class:[
              'field-group-field-input hide-error currency-select',
              _setup.inputClass,
              { [_setup.inputErrorClass]: _setup.fieldErrorsShown },
            ],attrs:{"clearable":false,"searchable":false,"disabled":_setup.readonly,"reduce":(option) => option.value,"options":[
              { value: 'SELL', label: 'from' },
              { value: 'BUY', label: 'to' },
            ]},scopedSlots:_vm._u([_vm._l((['option', 'selected-option']),function(optionName){return {key:optionName,fn:function(option){return [(option)?_c('span',{key:optionName,staticClass:"currency-select-option"},[(_setup.displaySymbols)?_c('span',{staticClass:"symbol"},[_vm._v(" "+_vm._s(option.value === 'SELL' ? _setup.sellCurrencyObj.symbol : _setup.buyCurrencyObj.symbol)+" ")]):_vm._e(),_vm._v(" "+_vm._s(option.value === 'SELL' ? _setup.sellCurrency : _setup.buyCurrency)+" ")]):_vm._e()]}}})],null,true),model:{value:(_setup.amountType),callback:function ($$v) {_setup.amountType=$$v},expression:"amountType"}})],1)])]),_c('div',{staticClass:"currency-field"},[(_setup.amountTitle)?_c('label',{staticClass:"field-group-field-label currency-label"},[_vm._v(" "+_vm._s(_setup.amountTitle)+" ")]):_vm._e(),_c('div',{staticClass:"input-holder",class:{ 'mock-label-padding': !_setup.amountTitle }},[_c('input',{ref:"currencyInput",class:[
            'field-group-field-input currency-field-input',
            _setup.inputClass,
            {
              [_setup.inputErrorClass]: _setup.fieldErrorsShown,
              'hide-error': !_setup.showAsDirty,
              'is-dirty': _setup.showAsDirty,
            },
          ],attrs:{"disabled":_setup.readonly,"inputmode":"decimal"},domProps:{"value":_setup.inputText},on:{"input":function($event){return _setup.setAmount($event.target.value, true)},"blur":_setup.onBlur}}),(_setup.displaySymbols)?_c('div',{staticClass:"amount-currency"},[_vm._v(" "+_vm._s(_setup.currencyObj.symbol)+" ")]):_vm._e()]),(_setup.showAsDirty)?_c(_setup.FormFieldErrors,{attrs:{"field":_setup.field,"model":_vm.model}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }