import { storeSetupDone } from '../store';
import { inheritedMeta } from 'ah-common-lib/src/helpers/router';
import { CustomNavigationGuard, CustomRoute, CustomRouteMeta } from './routeModels';
import { AuthorityType, UserRole } from 'ah-api-gateways';
import { RawLocation } from 'vue-router';
import { useAuthStore } from '../store/authStore';

function calcRedirect(redirect: string, to: CustomRoute) {
  if (redirect.startsWith('./')) {
    let basePath = to.matched[Math.max(0, to.matched.length - 2)].path;
    if (basePath.endsWith('/')) {
      basePath = basePath.substr(0, basePath.length - 1);
    }
    return basePath + redirect.substr(1);
  }
  return redirect;
}

export function checkAccountRegistrationState(next: (to?: RawLocation) => void) {
  const authModule = useAuthStore();
  if (authModule.hasRegistrationSession) {
    next(authModule.questionnaireFinished ? '/review' : 'register');
  } else {
    next();
  }
}

export const requireRouteRolesAndAuthorities: CustomNavigationGuard<Vue> = (to, from, next) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to) as CustomRouteMeta;
    const roles: UserRole[] | undefined = inherited.allowedRoles;
    const requiredAuthorities: AuthorityType[] | undefined = inherited.requiredAuthorities;
    const redirect: RawLocation = calcRedirect(inherited.redirectDisallowedRole || '/', to);

    if (!roles?.length && !requiredAuthorities?.length) {
      next();
    } else if (!authModule.loggedInRole) {
      next(redirect);
    } else if (roles && !roles.includes(authModule.loggedInRole)) {
      next(redirect);
    } else if (requiredAuthorities && !authModule.hasAuthorities(requiredAuthorities)) {
      next(redirect);
    } else {
      next();
    }
  });
};

export const checkAuth: CustomNavigationGuard<Vue> = (to, from, next: (to?: RawLocation) => void) => {
  storeSetupDone.then(() => {
    const authModule = useAuthStore();
    const inherited = inheritedMeta(to) as CustomRouteMeta;
    if (inherited.auth) {
      if (!authModule.hasValidSession) {
        next({ path: '/login', query: { r: to.path, q: JSON.stringify(to.query) } });
      } else {
        checkAccountRegistrationState(next);
      }
    } else if (inherited.unVerifiedOrUnAuth) {
      next(authModule.isLoggedIn && !authModule.isUnverifiedSelfRegister ? '/app' : undefined);
    } else if (inherited.unAuthOnly) {
      next(authModule.isLoggedIn ? '/app' : undefined);
    } else {
      next();
    }
  });
};
