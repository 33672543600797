<script setup lang="ts">
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { TreeOptionsBranchOption } from '../../interfaces/form';
import ChoiceBranch from './ChoiceBranch.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const formElement = useFormElement({
  emit,
  props,
});

const { modelStateRef, field, setModelState, setValue } = formElement;

const tree = modelStateRef('options');

const showCallText = modelStateRef('showCallText', true, true);

function onChoice(choice: TreeOptionsBranchOption[]) {
  if (choice.length) {
    const final = !choice[choice.length - 1].next;
    setModelState('final', final);
    setModelState('meta', choice[choice.length - 1].meta);
  }
  setValue(
    choice.map((c) => c.value),
    true
  );
}
</script>

<template>
  <div class="choice-tree">
    <ChoiceBranch :branch="tree" :value-chain="field.$model" :show-call-text="showCallText" @choice="onChoice" />
  </div>
</template>
