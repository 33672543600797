<script lang="ts" setup>
import { PropType, computed } from 'vue';
import { FormValidation } from '../interfaces';

const props = defineProps({
  levels: {
    type: Number,
    default: 4,
  },
  validation: {
    type: Object as PropType<FormValidation>,
    required: true,
  },
});

const password = computed(() => {
  return props.validation.$model;
});

const validators = computed(() => {
  const validations = {} as { [key: string]: boolean };
  const passwordValidationsKeys = Object.keys(props.validation).filter((key) => key.includes('password'));
  passwordValidationsKeys.forEach((key) => (validations[key] = props.validation[key].$invalid === false));
  return validations;
});

const validationNumber = computed(() => {
  return Object.keys(validators.value).length;
});

const secureLevelCounter = computed(() => {
  if (!password.value) return 0;
  return Object.values(validators.value).filter((val) => val).length;
});

const hasValidLength = computed(() => {
  return validators.value.passwordLength && password.value;
});

const hasNumber = computed(() => {
  return validators.value.passwordNumber && password.value;
});

const hasSpecialChar = computed(() => {
  return validators.value.passwordSpecialChar && password.value;
});

const hasUpper = computed(() => {
  return validators.value.passwordUpper && password.value;
});

const hasLower = computed(() => {
  return validators.value.passwordLower && password.value;
});

const has = computed(() => {
  return (validation: string) => {
    return Object.keys(validators.value).find((key) => key.includes(validation));
  };
});
</script>

<template>
  <div>
    <VRow>
      <VCol v-for="index in validationNumber" :key="index">
        <hr :class="{ active: index <= secureLevelCounter }" />
      </VCol>
    </VRow>
    <slot name="password-minimum-criteria">
      <ul class="info-component-wrapper text-small mt-1 pl-3">
        <li v-if="has('Length')" :class="{ valid: hasValidLength }">Minimum 8 characters. Maximum 64 characters</li>
        <li v-if="has('Number')" :class="{ valid: hasNumber }">Must include at least 1 number</li>
        <li v-if="has('SpecialChar')" :class="{ valid: hasSpecialChar }">Must include at least 1 special character</li>
        <li v-if="has('Upper')" :class="{ valid: hasUpper }">Must include at least 1 uppercase letter</li>
        <li v-if="has('Lower')" :class="{ valid: hasLower }">Must include at least 1 lowercase letter</li>
      </ul>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
hr {
  border-width: 3px;
  border-radius: 2px;
  @include themedBorderColor($color-secondary, $color-secondary);
  @include themedBackgroundColor($color-secondary, $color-secondary);

  &.active {
    @include themedBorderColor($color-yellow-highlight, $color-dark-yellow-highlight);
    @include themedBackgroundColor($color-yellow-highlight, $color-dark-yellow-highlight);
  }
}

.valid {
  @include themedTextColor($color-success, $color-success);
}
</style>
