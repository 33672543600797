<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { MFAActionType, MFAState, MFAType, UserStatus } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { config } from '@/config';
import { useAuthStore } from 'bd-common/src/store/authStore';
import MFAForm from 'bd-common/src/components/session/MFAForm.vue';
import LoadingSplash from 'bd-common/src/components/common/LoadingSplash.vue';
import { useToast } from 'ah-common-lib/src/toast';

const emit = defineEmits<{
  (e: 'submitted'): void;
  (e: 'failed', error: any): void;
}>();

const requestManagerParent = useRequestManager();
const requestManager = requestManagerParent.manager;

const authStore = useAuthStore();

const toast = useToast();

const phoneOTP = ref<InstanceType<typeof MFAForm>>();

const phoneMFAStatus = ref<MFAState>(MFAState.NOT_REQUESTED);
const phoneOtp = ref<string>('');

const staticOTP = computed(() => config.staticOTP);

const otpPhone = computed(
  () => authStore.loggedInUser?.phoneNumber.substring(authStore.loggedInUser!.phoneNumber.length - 4) || ''
);

const phoneMFA = computed(() => ({ actionType: MFAActionType.CLIENT_INVITE, type: MFAType.SMS_OTP }));

function submitRegistration() {
  requestManager.newPromise('redeemTokens', authStore.registrationMfa()).then(
    () => emit('submitted'),
    (e) => emit('failed', e)
  );
}

watch(phoneMFAStatus, () => {
  toast.clear('expiredMFA');
  if (phoneMFAStatus.value === MFAState.EXPIRED) {
    toast.error(
      'Sorry, the code you entered has expired',
      {
        group: 'expiredMFA',
        title: 'Code expired. Please click on "Resend Code"',
      },
      {
        noAutoHide: true,
      }
    );
  } else if (phoneMFAStatus.value === MFAState.SUCCESS) {
    submitRegistration();
  }
});

if (authStore.loggedInUser?.status === UserStatus.VERIFIED) {
  emit('submitted');
}
</script>

<template>
  <div x-test-name="account-verification-form">
    <template v-if="authStore.loggedInUser?.status === UserStatus.TEMPORARY">
      <LoadingSplash v-if="requestManager.requestStates.getRegistrationToken === 'pending'" />

      <div class="card mb-4" v-else>
        <div class="card-body p-5">
          <h4 class="verification-form-title my-3"><span class="text-danger">*</span> Verification Code</h4>
          <MFAForm
            ref="phoneOTP"
            :value.sync="phoneOtp"
            :status.sync="phoneMFAStatus"
            :destination="otpPhone"
            :mfa="phoneMFA"
            :staticMFA="staticOTP"
            auto-request
          />
        </div>
      </div>
      <span class="required-text">* Mandatory</span>
    </template>
    <LoadingSplash text="Verifying your account..." v-else />
  </div>
</template>

<style lang="scss" scoped>
.verification-form-title {
  color: getColor($color-bdDarkBlue);
}

::v-deep .resend-code-text {
  color: getColor($color-bdDarkBlue);
}

.required-text {
  color: getColor($color-bdDanger);
  font-size: $font-size-sm;
  font-weight: 600;
}
</style>
