<script setup lang="ts">
import { PropType, ref } from 'vue';
import ContactDetailsForm, { ContactDetails } from './ContactDetailsForm.vue';
import CreatePasswordForm, { PasswordDetails } from './CreatePasswordForm.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { submitForm } from 'ah-common-lib/src/form/helpers';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { ClientInvite } from 'ah-api-gateways/models/client/clientInvite';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useServices } from 'bd-common/src/services';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

/**
 * Invite Redeem Step
 *
 * Collects user information and redeems a Client Invite, initializing a temporary session
 */

const props = defineProps({
  token: {
    type: String,
    required: true,
  },
  invite: {
    type: Object as PropType<ClientInvite>,
    required: true,
  },
});

const emit = defineEmits({
  submitted: () => true,
  failed: () => true,
});

const requestManager = useRequestManager().manager;
const services = useServices();
const authStore = useAuthStore();

const contactDetails = ref<ContactDetails>();
const contactDetailsValidation = ref<FormValidation | undefined>();
const passwordDetails = ref<PasswordDetails>();
const passwordValidation = ref<FormValidation | undefined>();

function submit() {
  passwordValidation.value && submitForm(passwordValidation.value);
  contactDetailsValidation.value && submitForm(contactDetailsValidation.value);

  if (
    contactDetails.value &&
    passwordDetails.value?.password &&
    passwordValidation.value?.$invalid === false &&
    contactDetailsValidation.value?.$invalid === false
  ) {
    requestManager
      .sameOrCancelAndNew(
        'redeemInvite',
        services.clientInvites
          .acceptClientInvite({
            inviteToken: props.token,
            password: passwordDetails.value.password,
            phoneNumber: contactDetails.value.phoneNumber,
          })
          .pipe(switchMap((res) => from(authStore.startUpSession({ session: res })))),
        props.invite
      )
      .subscribe(
        () => emit('submitted'),
        () => emit('failed')
      );
  }
}

// Invite is not watched, it is explicitly expected to exist on component load
if (props.invite) {
  contactDetails.value = {
    email: props.invite.applicantEmail,
    firstName: props.invite.applicantFirstName,
    lastName: props.invite.applicantLastName,
    phoneNumber: '',
    title: props.invite.applicantTitle,
  };
}
</script>

<template>
  <div class="redeem-invite-step">
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="mb-4">Contact Details</h5>
        <ContactDetailsForm :validation.sync="contactDetailsValidation" :model.sync="contactDetails" />
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">Create Password</h5>
        <CreatePasswordForm :validation.sync="passwordValidation" :model.sync="passwordDetails" />
      </div>
    </div>
    <div class="buttons mt-3">
      <span class="required-text">* Mandatory</span>
      <VButton class="btn-primary" @click="submit" :loading="requestManager.requestStates.redeemInvite === 'pending'"
        >Next
        <MaterialIcon icon="arrow_forward" />
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.redeem-invite-step {
  color: getColor($color-bdDarkBlue);

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .required-text {
      color: getColor($color-bdDanger);
      font-size: $font-size-sm;
      font-weight: 600;
    }
  }
}
</style>
