import { FieldModel } from '../interfaces';
import { useFormElement } from './useFormElement';
import { useBaseFieldValues } from './useBaseFieldValues';

export function useTextFormFieldMethods<T extends FieldModel, M = any>(
  formEl: ReturnType<typeof useFormElement<T, M>>,
  options: { setValue?: (value: string) => void } = {}
) {
  const pasteHandler = formEl.modelStateRef('pasteHandler');
  const { dirtyOnInput, inputToValueFn, trimValue, emptyValue } = useBaseFieldValues(formEl);

  const setValue =
    options.setValue ??
    ((value: string) => {
      formEl.setValue(inputToValueFn.value(value), !formEl.field.value.$dirty && !dirtyOnInput.value);
      formEl.component.$forceUpdate();
    });

  function onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      formEl.emitFormEvent({
        event: 'form-field-submit',
      });
    }
  }

  function onBlur() {
    formEl.field.value.$touch();
    if (trimValue.value && typeof formEl.field.value.$model === 'string') {
      const trimmedValue = (formEl.field.value.$model as string).trim();
      if ((formEl.field.value.$model as string).replace(/\s/g, '') === '') {
        setValue(emptyValue.value);
      } else if (trimmedValue !== formEl.field.value.$model) {
        setValue((formEl.field.value.$model as string).trim());
      }
    }
    formEl.emitFormEvent({
      event: 'form-field-blur',
    });
  }

  function onPaste(event: ClipboardEvent) {
    if (typeof pasteHandler.value === 'function') {
      event.preventDefault();
      const data = event.clipboardData?.getData('text');
      const target = event.target as HTMLInputElement;
      if (data) {
        const transformed = pasteHandler.value(data);
        if (transformed) {
          // After cleaning up the pasted content, we need to ensure that only the
          // selected content is replaced and not the full string
          setValue(
            target.value.substring(0, target.selectionStart!) +
              transformed +
              target.value.substring(target.selectionEnd!)
          );
        }
      }
    }
  }
  return {
    onKeyPress,
    onBlur,
    onPaste,
    setValue,
  };
}
