<script lang="ts">
export interface InviteCompanyDetails {
  companyName: string;
  country: string;
  entityType: string;
  registrationNumber: string;
}
</script>

<script setup lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { defineManagedModelFormProps, useManagedModelForm } from 'ah-common-lib/src/form/useManagedModelForm';

const props = defineProps({
  ...defineManagedModelFormProps<InviteCompanyDetails>(),
});

const { formDef } = useManagedModelForm({
  props,
  formModel: makeFormModel({
    name: 'contactDetailsForm',
    title: 'Contact Details',
    fieldType: 'form',
    state: {
      showRequiredMarkers: true,
    },
    fields: [
      textField('companyName', 'Company Name', { readonly: true, required: false }, {}),
      textField('country', 'Country of Incorporation', { readonly: true, required: false }, {}),
      textField('entityType', 'Company Type', { readonly: true, required: false }, {}),
      textField('registrationNumber', 'Company Number', { readonly: true, required: false }, {}),
    ],
  }),
});
</script>

<template>
  <div class="contact-details-form">
    <ValidatedForm :fm="formDef.form" />
  </div>
</template>
