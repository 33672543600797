<script lang="ts" setup>
import { ref, watch, computed, onBeforeUnmount } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { DatePicker } from 'v-calendar/lib/v-calendar.umd';
import { format, isValid, startOfDay } from 'date-fns';
import { CalendarIcon } from '../../../icons/components';
import { isDescendant } from '../../../helpers/dom';
import { generateUUID } from '../../../helpers/uuid';
import { stripZoneOffset, addZoneOffset } from '../../../helpers/time';
import { eachDayOfInterval, startOfMonth, endOfMonth, subWeeks, addWeeks } from 'date-fns';
import { getValidatorFn } from '../../helpers';

const NUM_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const dayInput = ref<Element>();
const monthInput = ref<Element>();
const yearInput = ref<Element>();
const datePicker = ref<InstanceType<typeof DatePicker>>();

const year = ref('');

const month = ref('');

const day = ref('');

// Events are stored so the last interaction can be queried on @input events for these inputs
// Values are udnefined (rather than null) to avoid unnecessary reactivity

let lastMonthKeyEvent: KeyboardEvent | undefined;

let lastDayKeyEvent: KeyboardEvent | undefined;

const isCalendarShown = ref(false);

const uuid = generateUUID();

const clickListener = (event: any) => {
  if (isCalendarShown.value && datePicker.value && !isDescendant((datePicker.value as Vue).$el, event.target)) {
    hideCalendar();
  }
};

const currentMonthDisplayed = ref({
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
});

const selectionAttributes = {
  highlight: {
    class: 'date-highlight',
    contentClass: 'date-highlight-content',
  },
};

const attributes = [
  {
    key: 'today',
    highlight: {
      class: 'vc-today',
      contentClass: 'vc-today-content',
    },
    dates: new Date(),
  },
];

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, inputClass, inputErrorClass, fieldErrorsShown, dirtyOnInput, inputName } =
  useBaseFieldValues(formElement);

const { modelStateRef, field, model, setValue } = formElement;

const popupDate = computed(() => {
  if (field.value.$model) {
    let date = new Date(field.value.$model);
    if (isValid(date)) {
      return useDateTime.value && !useLocalTime.value ? addZoneOffset(date) : date;
    }
  }
  return null;
});

const useLocalTime = modelStateRef('useLocalTime', false);

const useDateTime = modelStateRef('useDateTime', false);

const dateDisallowedChecker = (date: Date) => {
  const validators = Object.keys(model.value.$validators);
  const dateVal = (useLocalTime.value ? date : stripZoneOffset(date)).toISOString();
  for (let i = 0; i < validators.length; i += 1) {
    const validator = getValidatorFn(model.value.$validators[validators[i]]);
    if (
      !validator(dateVal, model.value.$parent && model.value.$parent(), model.value.$parent && model.value.$parent())
    ) {
      return true;
    }
  }
  return false;
};

const disabledDates = computed(() => {
  // FIXME: "hacking" dates shown in page
  // v-calendar doesn't support date validations only arrays of
  // allowed/disabled dates, therefore, we are calculating disabled dates
  // in currently shown calendar page
  const month = new Date(currentMonthDisplayed.value.year, currentMonthDisplayed.value.month - 1);
  const days = eachDayOfInterval({
    start: subWeeks(startOfMonth(month), 1),
    end: addWeeks(endOfMonth(month), 1),
  });

  return days.filter((day) => dateDisallowedChecker(day));
});

function setDateFromPopup(date: Date) {
  date = startOfDay(date);
  if (!useLocalTime.value) {
    if (useDateTime.value && !useLocalTime.value) {
      date = stripZoneOffset(date);
    }
  }
  setValue(formatDate(date), true);
  hideCalendar();
}

function showCalendar() {
  if (!isCalendarShown.value && !readonly.value) {
    isCalendarShown.value = true;
    setTimeout(() => {
      if (popupDate.value && datePicker.value) {
        datePicker.value.move(popupDate.value, { transition: 'none' });
      }
      if (isCalendarShown.value) {
        window.addEventListener('click', clickListener);
      }
    });
  }
}

function hideCalendar() {
  if (isCalendarShown.value) {
    isCalendarShown.value = false;
    window.removeEventListener('click', clickListener);
  }
}

function formatDate(date: Date) {
  try {
    return useDateTime.value ? date.toISOString() : format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
}

function setYear(event: InputEvent) {
  year.value = (event.target as HTMLInputElement).value;
  onInput();
}

function setMonth(event: InputEvent) {
  month.value = (event.target as HTMLInputElement).value;
  if (NUM_KEYS.includes(lastMonthKeyEvent?.key || '') && parseInt((event.target as HTMLInputElement).value) > 1) {
    (yearInput.value as any).focus();
  }
  onInput();
}

function setDay(event: InputEvent) {
  day.value = (event.target as HTMLInputElement).value;
  if (NUM_KEYS.includes(lastDayKeyEvent?.key || '') && parseInt((event.target as HTMLInputElement).value) > 3) {
    (monthInput.value as any).focus();
  }
  onInput();
}

function isValidInput(year: number, month: number, day: number) {
  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
    return false;
  }

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

  if (!(year > 1900 && year < 9999)) {
    return false;
  }

  if (!(month > 0 && month < 13)) {
    return false;
  }

  if (day <= 0 || day > monthLength[month - 1]) {
    return false;
  }
  return true;
}

function onInput() {
  const dirty = !field.value.$dirty && !dirtyOnInput.value;

  if (!year.value && !month.value && !day.value) {
    setValue('', dirty);
    return;
  }

  const yearVal = parseInt(year.value, 10);
  const monthVal = parseInt(month.value, 10);
  const dayVal = parseInt(day.value, 10);

  if (isValidInput(yearVal, monthVal, dayVal)) {
    let date = startOfDay(new Date(yearVal, monthVal - 1, dayVal));

    if (useDateTime.value && !useLocalTime.value) {
      date = stripZoneOffset(date);
    }

    // FIXME: see above fixme
    setValue(formatDate(date), dirty);
  } else {
    setValue(new Date(NaN), dirty);
  }
}

function onKeyDown(event: KeyboardEvent) {
  if (event.target === monthInput.value) {
    lastMonthKeyEvent = event;
  } else if (event.target === dayInput.value) {
    lastDayKeyEvent = event;
  }

  if (event.key === 'Backspace' && !(event.target as any).value) {
    if (event.target === yearInput.value) {
      (monthInput.value as any).focus();
    } else if (event.target === monthInput.value) {
      (dayInput.value as any).focus();
    }
  }
}

function onBlur() {
  setTimeout(() => {
    let internal = false;
    [monthInput.value, yearInput.value, dayInput.value, datePicker.value].forEach((refVal) => {
      const refEl = refVal?.$el || refVal;
      if (
        document.activeElement &&
        refEl &&
        (document.activeElement === refEl || isDescendant(refEl, document.activeElement))
      ) {
        internal = true;
      }
    });
    if (!internal) {
      field.value.$touch();
    }
  });
}

onBeforeUnmount(() => {
  window.removeEventListener('click', clickListener);
});

watch(
  () => field.value.$model,
  () => {
    if (!field.value.$model) {
      year.value = '';
      month.value = '';
      day.value = '';
    } else {
      const date = useLocalTime.value ? new Date(field.value.$model) : addZoneOffset(new Date(field.value.$model));
      if (field.value.$model && isValid(date)) {
        year.value = date.getFullYear().toString();
        month.value = (date.getMonth() + 1).toString();
        day.value = date.getDate().toString();
      }
    }
  },
  { immediate: true }
);

watch(isCalendarShown, (newVal: boolean, oldVal: boolean) => {
  if (!newVal && oldVal) {
    field.value.$touch();
  }
});
</script>

<template>
  <div class="date-inputs-wrapper">
    <input
      ref="dayInput"
      inputmode="numeric"
      :name="`${inputName}-day`"
      :value="day"
      type="number"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      placeholder="DD"
      :class="['field-group-field-input day', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @input="setDay"
      @keydown="onKeyDown"
      @blur="onBlur"
    />
    <input
      ref="monthInput"
      inputmode="numeric"
      :name="`${inputName}-month`"
      :value="month"
      type="number"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      placeholder="MM"
      :class="['field-group-field-input month', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @input="setMonth"
      @keydown="onKeyDown"
      @blur="onBlur"
    />
    <span class="year-wrapper">
      <input
        ref="yearInput"
        inputmode="numeric"
        :name="`${inputName}-year`"
        :value="year"
        type="number"
        :readonly="readonly"
        :tabindex="readonly ? -1 : undefined"
        placeholder="YYYY"
        :class="['field-group-field-input year', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
        @keydown="onKeyDown"
        @input="setYear"
        @blur="onBlur"
      />
      <div class="calendar-pick" :class="{ readonly: readonly }">
        <span @click="showCalendar" :id="`calendar-icon-${uuid}`">
          <CalendarIcon class="calendar" />
        </span>
        <BTooltip custom-class="calendar-tooltip" :target="`calendar-icon-${uuid}`" :show="isCalendarShown" triggers="">
          <DatePicker
            ref="datePicker"
            :value="popupDate"
            :class="['date-picker', inputClass]"
            @input="setDateFromPopup($event)"
            :disabled-dates="disabledDates"
            :to-page.sync="currentMonthDisplayed"
            :attributes="attributes"
            :select-attribute="selectionAttributes"
            :drag-attribute="selectionAttributes"
            locale="eng"
            is-expanded
            trim-weeks
          />
        </BTooltip>
      </div>
    </span>
  </div>
</template>

<style lang="scss">
.date-inputs-wrapper {
  margin-bottom: -0.5rem;

  .field-group-field-input.year,
  .field-group-field-input.month,
  .field-group-field-input.day {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &.month,
    &.day {
      width: 3rem;

      @include desktop {
        width: 4rem;
        margin-right: 1rem;
      }
    }

    &.year {
      width: 4.5rem;

      @include desktop {
        width: 6rem;
      }
    }
  }

  .year-wrapper {
    display: inline-block;
    margin-right: 0rem;
  }

  .calendar-pick {
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.readonly {
      cursor: not-allowed;
    }
  }
}

.calendar-tooltip {
  opacity: 1;
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background: none;
    max-width: none;
    padding: 0;
  }
}
</style>
