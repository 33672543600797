import Vue from 'vue';
import { makeStoreSupportData } from './supportData';
import { runActions } from './runActions';
import { authStoreFactory } from 'ah-common-stores';
import { getRouter } from '../router/router';
import { checkAuth } from '../router/guards';
import { CustomRoute } from '../router/routeModels';
import { useConfig } from '../config';

let store: ReturnType<typeof authStoreFactory> | null = null;

export function useAuthStore() {
  if (!store) {
    store = authStoreFactory({
      checkRouteAuth() {
        const router = getRouter();

        // Recheck user's permission to be in the current route
        checkAuth(router.currentRoute as CustomRoute, router.currentRoute as CustomRoute, (location) => {
          if (location && typeof location !== 'function') {
            router.replace(location).catch(() => {
              // ignore route errors in this change
            });
          }
        });
      },
      admin: false,
      allowedRoles: useConfig().allowedRoles,
      getAppUpdater: () => Vue.ahAppUpdaterState,
      getOtp: () => Vue.otp,
      getRouter,
      getToast: () => Vue.toast,
      makeStoreSupportData,
      runActions: (action) => runActions(action),
    });
  }

  return store();
}
