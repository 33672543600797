<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { format, isValid } from 'date-fns';
import MonthSelectorInput from '../../../date/MonthSelectorInput.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const date = ref<Date | undefined>(new Date());

const formElement = useFormElement({
  emit,
  props,
});

const { placeholder, inputClass, inputErrorClass, fieldErrorsShown } = useBaseFieldValues(formElement);

const { modelStateRef, field, setValue } = formElement;

const inputGroupClass = modelStateRef('inputGroupClass', '');

function onInputChange(value: Date) {
  setValue(format(value, 'yyyy-MM-dd'));
}

function onDismiss() {
  field.value.$touch();
}

watch(
  () => field.value.$model,
  () => {
    if (!field.value.$model) {
      date.value = undefined;
    } else {
      const dateVal = new Date(field.value.$model);
      if (field.value.$model && isValid(date)) {
        date.value = dateVal;
      } else {
        date.value = undefined;
      }
    }
  },
  { immediate: true }
);
</script>

<template>
  <MonthSelectorInput
    :placeholder="placeholder"
    :class="['field-group-field-input']"
    :inputClass="[inputClass, { [inputErrorClass]: fieldErrorsShown }]"
    :inputGroupClass="[inputGroupClass, { [inputErrorClass]: fieldErrorsShown }]"
    :value="date"
    @update:value="onInputChange"
    @dismissed-popup="onDismiss"
  />
</template>

<style lang="scss">
.date-inputs-wrapper {
  margin-bottom: -0.5rem;

  .field-group-field-input.year,
  .field-group-field-input.month,
  .field-group-field-input.day {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    &.month,
    &.day {
      width: 3rem;

      @include desktop {
        width: 4rem;
        margin-right: 1rem;
      }
    }

    &.year {
      width: 4.5rem;

      @include desktop {
        width: 6rem;
      }
    }
  }

  .year-wrapper {
    display: inline-block;
    margin-right: 0rem;
  }

  .calendar-pick {
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.readonly {
      cursor: not-allowed;
    }
  }
}

.calendar-tooltip {
  opacity: 1;
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background: none;
    max-width: none;
    padding: 0;
  }
}
</style>
