<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useNotificationsStore } from 'ah-notifications/src/store';
import NotificationsList from './NotificationsList.vue';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import NotificationActionButtons from './NotificationActionButtons.vue';
import { useRouter } from 'vue-router/composables';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { Notification } from 'ah-api-gateways';
import WarningSplash from 'bd-common/src/components/common/WarningSplash.vue';
import LoadingSplash from '../common/LoadingSplash.vue';

const props = withDefaults(
  defineProps<{
    type?: 'notification' | 'file_export';
  }>(),
  {
    type: 'notification',
  }
);

const isTooltipShown = ref(false);

const rootRef = ref<HTMLElement | null>(null);

let clickListener: (event: MouseEvent) => void;

const notificationsStore = useNotificationsStore();

const router = useRouter();

const isNotificationsEmpty = computed(() => notifications.value.length === 0);

const isMoreThanFive = computed(() => notifications.value.length > 5);

const unreadCount = computed(() =>
  props.type === 'notification' ? notificationsStore.unreadCount : notificationsStore.fileExportUnreadCount
);

const isAllRead = computed(() => unreadCount.value === 0);

const handleMarkAllAsRead = () => {
  notificationsStore.markAllAsRead(props.type === 'notification' ? 'notifications' : 'fileExports');
};

function toggleTooltip(show: boolean = !isTooltipShown.value) {
  // Run in next tick to avoid click event propagation issues
  setTimeout(() => {
    isTooltipShown.value = show;
  }, 0);
}

function navigateToNotificationsPage() {
  const currentRoutePath = router.currentRoute.fullPath;
  router.push({
    path: props.type === 'notification' ? '/app/notifications' : '/app/downloads',
    query: {
      fromRoute: currentRoutePath,
    },
  });
}

function hideToogleTooltip() {
  isTooltipShown.value = false;
}

function handleSeeAllClick() {
  toggleTooltip();
  navigateToNotificationsPage();
}

onMounted(() => {
  clickListener = (event) => {
    if (isTooltipShown.value && (!rootRef.value || !isDescendant(rootRef.value, event.target as Element, true))) {
      hideToogleTooltip();
    }
  };

  window.addEventListener('click', clickListener);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', clickListener);
});

const notifications = computed(() => {
  const out: Notification[] = [];
  const notifArray =
    props.type === 'notification' ? notificationsStore.notifications : notificationsStore.fileExportNotifications;

  out.push(...notifArray.slice(0, 5));

  return out;
});

const loading = computed(() => notificationsStore.loading);

defineExpose({
  toggle: toggleTooltip,
  show: () => !isTooltipShown.value && toggleTooltip(true),
  hide: () => isTooltipShown.value && toggleTooltip(false),
});
</script>

<template>
  <div class="notifications-tooltip" ref="rootRef" v-if="isTooltipShown">
    <NotificationActionButtons
      :isAllRead="isAllRead"
      :isNotificationsEmpty="isNotificationsEmpty"
      @see-all="handleSeeAllClick"
      @mark-as-read="handleMarkAllAsRead"
    />
    <LoadingSplash v-if="loading" />
    <WarningSplash
      v-else-if="isNotificationsEmpty"
      heading="No notifications found"
      sub-heading="You are up to date"
      icon="tab_close"
      type="info"
      class="no-notifications-found"
    />
    <NotificationsList v-else class="notifications-list" :notifications="notifications" />
    <div class="see-all-bar mt-2" v-if="isMoreThanFive" @click="handleSeeAllClick">
      <MaterialIcon class="see-all-icon" icon="more_horiz" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.no-notifications-found {
  background: getColorWithAlpha($color-bdMediumBlue, 0.3);
  padding: 1.2em 0.7em;
}
</style>
