<script lang="ts" setup>
import MaterialIcon from '../common/MaterialIcon.vue';
import { PaginatedResponse } from 'ah-api-gateways';
import { PropType, computed } from 'vue';

const props = defineProps({
  data: {
    type: Object as PropType<Partial<PaginatedResponse<any>>>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'set-page', page: number): void;
}>();

interface PageRef {
  name: string;
  pageNumber?: number;
  active?: boolean;
}

const PAGE_RANGE = 1;

const currentPage = computed(() => props.data.pageNumber || 0);

const totalPages = computed(() => Math.ceil((props.data.total || 0) / (props.data.pageSize || 10)));

const pages = computed(() => {
  const out: PageRef[] = [
    {
      active: currentPage.value === 0,
      pageNumber: 0,
      name: '1',
    },
  ];
  if (currentPage.value > PAGE_RANGE + 1) {
    out.push({
      name: '...',
    });
  }
  for (
    let i = Math.max(1, currentPage.value - PAGE_RANGE);
    i < Math.min(totalPages.value, currentPage.value + PAGE_RANGE + 1);
    i++
  ) {
    out.push({
      pageNumber: i,
      name: `${i + 1}`,
      active: currentPage.value === i,
    });
  }
  if (currentPage.value < totalPages.value - PAGE_RANGE - 2) {
    out.push({
      name: '...',
    });
  }
  if (currentPage.value < totalPages.value - PAGE_RANGE - 1) {
    out.push({
      pageNumber: totalPages.value - 1,
      name: `${totalPages.value}`,
    });
  }

  return out;
});

function setPage(page: number) {
  emit('set-page', page);
}
</script>

<template>
  <div class="btn-group" role="group">
    <button
      type="button"
      :class="[
        'btn btn-secondary edge-item',
        {
          ['no-action']: currentPage === 0,
        },
      ]"
      @click="setPage(0)"
    >
      <MaterialIcon icon="keyboard_double_arrow_left" />
    </button>
    <button
      type="button"
      :class="[
        'btn btn-secondary edge-item',
        {
          ['no-action']: currentPage === 0,
        },
      ]"
      @click="setPage(currentPage - 1)"
    >
      <MaterialIcon icon="keyboard_arrow_left" />
    </button>
    <button
      type="button"
      :class="[
        'btn',
        {
          ['btn-secondary']: !page.active,
          ['btn-primary active-page']: page.active,
          ['no-action']: page.active || typeof page.pageNumber !== 'number',
        },
      ]"
      v-for="(page, index) in pages"
      :key="index"
      @click="typeof page.pageNumber === 'number' && setPage(page.pageNumber)"
    >
      {{ page.name }}
    </button>
    <button
      type="button"
      :class="[
        'btn btn-secondary edge-item',
        {
          ['no-action']: currentPage === totalPages - 1,
        },
      ]"
      @click="setPage(currentPage + 1)"
    >
      <MaterialIcon icon="keyboard_arrow_right" />
    </button>
    <button
      type="button"
      :class="[
        'btn btn-secondary edge-item',
        {
          ['no-action']: currentPage === totalPages - 1,
        },
      ]"
      @click="setPage(totalPages - 1)"
    >
      <MaterialIcon icon="keyboard_double_arrow_right" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  display: flex;
  align-items: center;
  border-width: 1px;

  &.no-action {
    pointer-events: none;
  }

  &.edge-item {
    padding-left: 0.4em;
    padding-right: 0.4em;
    > span {
      vertical-align: middle;
      font-size: 1.3em;
    }
  }
}
</style>
