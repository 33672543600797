<script lang="ts">
export interface ContactDetails {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}
</script>

<script setup lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { emailField, textField, phoneField } from 'ah-common-lib/src/form/models';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import {
  defineManagedModelFormEmits,
  defineManagedModelFormProps,
  useManagedModelForm,
} from 'ah-common-lib/src/form/useManagedModelForm';

const props = defineProps({
  ...defineManagedModelFormProps<ContactDetails>(),
});

const emit = defineEmits(defineManagedModelFormEmits<ContactDetails>());

const { formDef } = useManagedModelForm({
  props,
  emit,
  formModel: makeFormModel({
    name: 'contactDetailsForm',
    title: 'Contact Details',
    fieldType: 'form',
    state: {
      showRequiredMarkers: true,
    },
    fields: [
      textField('title', 'Title', { fieldWrapperClass: 'col col-2 pr-0', readonly: true, required: false }, {}),
      textField('firstName', 'First name', { fieldWrapperClass: 'col-5 pr-0', readonly: true, required: false }, {}),
      textField('lastName', 'Last name', { fieldWrapperClass: 'col-5', readonly: true, required: false }, {}),
      emailField('email', 'Email', { readonly: true, required: false }, {}),
      phoneField('phoneNumber', 'Mobile Number', {
        required: true,
        errorMessages: {
          required: 'This field is mandatory',
        },
      }),
    ],
  }),
});
</script>

<template>
  <div class="contact-details-form">
    <ValidatedForm :fm="formDef.form" :validation.sync="formDef.validation" />
    <p>You will need to confirm this number before completing registration.</p>
  </div>
</template>

<style lang="scss" scoped>
.contact-details-form {
  p {
    font-size: $font-size-sm;
    font-weight: 500;
  }
}
</style>
