<script setup lang="ts">
import { computed } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';

const props = defineProps(makeUseFormElementProps<FieldModel, string[]>());

const emit = defineEmits(makeUseFormElementEmits<string[]>());

const formElement = useFormElement({
  emit,
  props,
});

const { modelStateRef, model, field, setValue } = formElement;

const fieldValue = computed(() => (Array.isArray(field.value.$model) ? field.value.$model : []));

const options = modelStateRef('options');

function optionName(option: any) {
  return typeof option === 'string' ? option : option.label;
}

function optionValue(option: any) {
  return typeof option === 'string' ? option : option.value;
}

function isChecked(option: any) {
  return fieldValue.value.includes(optionValue(option));
}

function onChange(option: any, $event: Event) {
  const checked = !!($event.target as any).checked;
  const value = optionValue(option);
  const model = [...value.value];
  if (checked && !isChecked(option)) {
    model.push(value);
  } else if (!checked && isChecked(option)) {
    model.splice(model.indexOf(value), 1);
  }
  setValue(model);
}
</script>

<template>
  <div>
    <div v-for="option in options" :key="optionValue(option)" class="checkbox-holder custom-control custom-checkbox">
      <input
        :id="`${model.$path}-${optionValue(option)}`"
        :checked="isChecked(option)"
        type="checkbox"
        class="custom-control-input"
        @change="onChange(option, $event)"
      />
      <label :for="`${model.$path}-${optionValue(option)}`" class="custom-control-label">
        {{ optionName(option) }}
      </label>
    </div>
  </div>
</template>
