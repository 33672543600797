import { PropType, PropOptions, ExtractPropTypes } from 'vue';
import { EmitFn, ObjectEmitsOptions } from 'vue/types/v3-setup-context';
import { BaseFormModel, FormEvent, FormValidation } from '../interfaces';

/**
 * Prop definition object builder for a form emelemt's props
 *
 * Use the object builder (or the helper function computeExposedProps) to expose props
 * in any component that uses this composable:
 *
 *   import { makeUseFormElementProps } from ...
 *   const props = defineProps({
 *     ...makeUseFormElementProps<User>(),
 *   });
 *
 * Returns ComponentObjectPropsOptions
 */
export function makeUseFormElementProps<M extends BaseFormModel = BaseFormModel, V = any>() {
  return {
    field: { type: Object as PropType<FormValidation<V>>, required: true },
    model: { type: Object as PropType<M>, required: true },
  } satisfies Record<string, PropOptions>;
}

export type UseFormElementProps<M extends BaseFormModel = BaseFormModel, V = any> = ReturnType<
  typeof makeUseFormElementProps<M, V>
>;

export function makeUseFormElementEmits<V = any>() {
  return {
    'form-event': (_event: FormEvent<V>) => true,
  } satisfies ObjectEmitsOptions;
}

export type UseFormElementEmits<T> = ReturnType<typeof makeUseFormElementEmits<T>>;

export interface UseFormElementOptions<M extends BaseFormModel = BaseFormModel, V = any> {
  props: ExtractPropTypes<UseFormElementProps<M, V>>;
  component?: Vue;
  emit: EmitFn<UseFormElementEmits<V>>;
}
