<script lang="ts" setup>
import { ref, watch, computed, nextTick, getCurrentInstance } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import isEqual from 'lodash/isEqual';
import {
  formatCurrencyValue,
  cleanCurrencyString,
  formatCurrencyString,
  getLocaleSeparators,
} from '../../../helpers/currency';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, number>());

const emit = defineEmits(makeUseFormElementEmits<number>());

const component = getCurrentInstance()?.proxy;

const inputText = ref('');

const inputDirty = ref(false);

const currencyInput = ref<HTMLInputElement>();

const formElement = useFormElement({
  emit,
  props,
});

const {
  readonly,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
  placeholder,
  customErrors,
  unexpectedErrorsShown,
  dirtyOnInput,
} = useBaseFieldValues(formElement);

const { modelStateRef, emitFormEvent, field, setValue, setModelState } = formElement;

const allowNegative = modelStateRef('allowNegative', false);

const showAsDirty = computed(() => {
  return inputDirty.value || customErrors.value.length > 0;
});

function onBlur() {
  setTimeout(() => {
    field.value.$touch();
    inputDirty.value = true;
    updateInputText(true);
    component?.$forceUpdate();
  });

  emitFormEvent({
    event: 'form-field-blur',
  });
}

function onFocus() {
  // when the field is focused with a value of 0.00, the input will automatically
  // clear out so the user doesn't have to delete all the digits
  if (field.value.$model === 0) {
    currencyInput.value!.value = '';
  }
  setModelState('unexpectedError', false);
}

watch(
  () => field.value.$model,
  () => {
    setTimeout(() => {
      updateInputText();
    });
  },
  { immediate: true }
);

function updateInputText(force = false) {
  const existing = cleanNumber(inputText.value);

  if (force || existing !== field.value.$model) {
    inputText.value = formatNumber(field.value.$model) || '';
  }
}

function setAmount(value: string, keepPristine = !field.value.$dirty && !dirtyOnInput.value) {
  if (value === '') {
    setValue('', keepPristine);
  }
  const separators = getLocaleSeparators();
  let cursorStart = currencyInput.value!.selectionStart || 0;
  let cursorEnd = currencyInput.value!.selectionEnd || 0;
  value = value.replace(/[a-zA-Z ]/g, '');
  let cleanVal = value ? cleanCurrencyString(value, undefined, true) : value;
  if (value.substr(0, cursorStart) === separators.decimalSeparator || cleanVal === '.') {
    // Handle '.' as '0.' to move the cursor to the right place (the start of the decimal values)
    cursorStart += 1;
    cursorEnd += 1;
    if (cleanVal === '.') {
      cleanVal = '0.';
    }
  }
  const cleaned = cleanNumber(cleanVal);
  if (cleanVal && !Number.isNaN(cleaned)) {
    inputText.value = formatCurrencyString(cleanVal);
  } else {
    inputText.value = cleanVal;
  }
  component?.$forceUpdate();

  if (!Number.isNaN(cleaned)) {
    if ((allowNegative.value || (!allowNegative.value && cleaned > -1)) && !isEqual(cleaned, field.value.$model)) {
      setValue(cleaned, keepPristine);
    }
    nextTick(() => {
      if (typeof cursorStart === 'number' && typeof cursorEnd === 'number') {
        if (value === '') {
          currencyInput.value!.setSelectionRange(0, 0);
        } else {
          const delta = calcLengthDifference(
            value.substr(0, cursorStart),
            currencyInput.value!.value.substr(0, cursorStart)
          );
          currencyInput.value!.setSelectionRange(cursorStart + delta, cursorEnd + delta);
        }
      }
    });
  }
}

function formatNumber(num: number | null) {
  if (typeof num === 'number' && !Number.isNaN(num)) {
    return formatCurrencyValue(num);
  }
  return '';
}

function cleanNumber(amount: string): number {
  if (!amount) {
    return NaN;
  }

  const cleanVal = cleanCurrencyString(amount);
  const number = Number(cleanVal);

  if (Number.isNaN(number)) {
    return NaN;
  }
  return Number(number.toFixed(2));
}

function calcLengthDifference(prev: string, curr: string): number {
  const deltaSeparatorsPrev = prev.length - cleanCurrencyString(prev).length;
  const deltaSeparatorsNow = curr.length - cleanCurrencyString(curr).length;

  return deltaSeparatorsNow - deltaSeparatorsPrev;
}
</script>

<template>
  <div>
    <InputGroupWrapper v-bind="{ field, model }">
      <input
        ref="currencyInput"
        :value="inputText"
        :placeholder="placeholder"
        :disabled="readonly"
        inputmode="decimal"
        :class="[
          'field-group-field-input currency-field-input text-right',
          inputClass,
          {
            [inputErrorClass]: fieldErrorsShown,
            'hide-error': !showAsDirty,
            'is-dirty': showAsDirty,
            'unexpected-error': unexpectedErrorsShown,
          },
        ]"
        @input="setAmount($event.target.value)"
        @blur="onBlur"
        @focus="onFocus"
      />
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </InputGroupWrapper>
    <ErrorIcon v-if="unexpectedErrorsShown" class="error-icon icon" />
  </div>
</template>
