<script setup lang="ts">
import { useTheme } from 'ah-theme';
import { computed } from 'vue';
import { VRow } from 'ah-common-lib/src/common/components';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';

const theme = useTheme();

const supportEmail = computed(() => theme.val?.helpEmail);
</script>

<template>
  <div class="background">
    <div class="icon-background"><MaterialIcon icon="sentiment_dissatisfied" class="dissatisfaction-icon" /></div>
    <BoxGrid alignV="start" class="mr-md-2">
      <BoxGridBlock align-self="stretch" class="mb-0" cols="12" lg="12" useLoadingOverlay>
        <VRow>
          <VCol class="d-flex justify-content-center">
            <h1 class="title">Sorry, access denied</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="d-flex justify-content-center">
            <b class="sub-title">
              We apologise for the inconvenience,<br />
              but the invite you are attempting to use is no longer active.
            </b>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <p class="text text-center">
              If you have any questions or need further assistance, please don't hesitate to contact <br />
              our support team at
              <a :href="`mailto:${supportEmail}`"> {{ supportEmail }}</a>
            </p>
          </VCol>
        </VRow>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<style lang="scss" scoped>
.sub-title {
  margin-bottom: 30px;
  text-align: center;
}

.title {
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.text {
  font-family: $font-family-inter;
  font-size: 14px;
  text-wrap: nowrap;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dissatisfaction-icon {
  font-size: 51px;
  margin-bottom: 6px;
}

.icon-background {
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  bottom: 70%;
  color: getColor($color-bdPrimary);
}
</style>
