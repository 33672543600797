var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('button',{class:[
      'btn btn-secondary edge-item',
      {
        ['no-action']: _setup.currentPage === 0,
      },
    ],attrs:{"type":"button"},on:{"click":function($event){return _setup.setPage(0)}}},[_c(_setup.MaterialIcon,{attrs:{"icon":"keyboard_double_arrow_left"}})],1),_c('button',{class:[
      'btn btn-secondary edge-item',
      {
        ['no-action']: _setup.currentPage === 0,
      },
    ],attrs:{"type":"button"},on:{"click":function($event){return _setup.setPage(_setup.currentPage - 1)}}},[_c(_setup.MaterialIcon,{attrs:{"icon":"keyboard_arrow_left"}})],1),_vm._l((_setup.pages),function(page,index){return _c('button',{key:index,class:[
      'btn',
      {
        ['btn-secondary']: !page.active,
        ['btn-primary active-page']: page.active,
        ['no-action']: page.active || typeof page.pageNumber !== 'number',
      },
    ],attrs:{"type":"button"},on:{"click":function($event){typeof page.pageNumber === 'number' && _setup.setPage(page.pageNumber)}}},[_vm._v(" "+_vm._s(page.name)+" ")])}),_c('button',{class:[
      'btn btn-secondary edge-item',
      {
        ['no-action']: _setup.currentPage === _setup.totalPages - 1,
      },
    ],attrs:{"type":"button"},on:{"click":function($event){return _setup.setPage(_setup.currentPage + 1)}}},[_c(_setup.MaterialIcon,{attrs:{"icon":"keyboard_arrow_right"}})],1),_c('button',{class:[
      'btn btn-secondary edge-item',
      {
        ['no-action']: _setup.currentPage === _setup.totalPages - 1,
      },
    ],attrs:{"type":"button"},on:{"click":function($event){return _setup.setPage(_setup.totalPages - 1)}}},[_c(_setup.MaterialIcon,{attrs:{"icon":"keyboard_double_arrow_right"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }