<script lang="ts" setup>
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';
import { computed, useAttrs } from 'vue';

withDefaults(
  defineProps<{
    title?: string;
  }>(),
  {
    title: '',
  }
);

const attrs = useAttrs();

const bRowAttrs = computed(() => ({
  'align-v': 'stretch',
  'align-h': 'center',
  'align-content': 'stretch',
  cols: 12,
  ...mapKeys(attrs, (v, k) => kebabCase(k)),
}));
</script>

<template>
  <div :class="['box-grid', { 'stretch-v': bRowAttrs['align-v'] === 'stretch' }]">
    <div class="header" v-if="$scopedSlots.header || title">
      <slot name="header">
        <h2>{{ title }}</h2>
      </slot>
    </div>
    <VRow v-bind="bRowAttrs" class="box-grid-row">
      <slot />
    </VRow>
    <div class="footer" v-if="$scopedSlots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box-grid {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  grid-template-areas:
    'header'
    'row'
    'footer';

  .header {
    grid-area: header;
  }

  .footer {
    @include responsiveGutter('padding-top');
  }

  .row {
    grid-area: row;
    min-width: 1rem;
  }

  .footer {
    grid-area: footer;
  }

  &.stretch-v {
    height: 100%;
  }
}
</style>
