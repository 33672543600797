<script setup lang="ts">
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { computed, PropType } from 'vue';
import { Validation } from '@vuelidate/core';

const props = defineProps({
  validation: {
    type: Object as PropType<Validation>,
    required: true,
  },
});

const isPasswordRequiredInvalid = computed(() => !!props.validation.required?.$invalid);
const isPasswordNumberInvalid = computed(
  () => isPasswordRequiredInvalid.value || !!props.validation.passwordNumber?.$invalid
);
const isPasswordLowerInvalid = computed(
  () => isPasswordRequiredInvalid.value || !!props.validation.passwordLower?.$invalid
);
const isPasswordLengthInvalid = computed(
  () => isPasswordRequiredInvalid.value || !!props.validation.passwordLength?.$invalid
);
const isPasswordSpecialCharInvalid = computed(
  () => isPasswordRequiredInvalid.value || !!props.validation.passwordSpecialChar?.$invalid
);
const isPasswordUpperInvalid = computed(
  () => isPasswordRequiredInvalid.value || !!props.validation.passwordUpper?.$invalid
);
</script>

<template>
  <div class="password-criteria-block">
    <p class="mb-0">Your password must include:</p>
    <ul class="mb-3">
      <li :class="{ 'invalid-text': isPasswordLengthInvalid }">
        <div class="password-criteria">
          <span>At minimum of 8 characters</span>
          <MaterialIcon v-if="!isPasswordLengthInvalid" class="valid-icon" icon="check_circle" />
        </div>
      </li>
      <li :class="{ 'invalid-text': isPasswordNumberInvalid }">
        <div class="password-criteria">
          <span>At least 1 number</span>
          <MaterialIcon v-if="!isPasswordNumberInvalid" class="valid-icon" icon="check_circle" />
        </div>
      </li>
      <li :class="{ 'invalid-text': isPasswordSpecialCharInvalid }">
        <div class="password-criteria">
          <span>At least 1 special character</span>
          <MaterialIcon v-if="!isPasswordSpecialCharInvalid" class="valid-icon" icon="check_circle" />
        </div>
      </li>
      <li :class="{ 'invalid-text': isPasswordUpperInvalid }">
        <div class="password-criteria">
          <span>At least 1 uppercase character</span>
          <MaterialIcon v-if="!isPasswordUpperInvalid" class="valid-icon" icon="check_circle" />
        </div>
      </li>
      <li :class="{ 'invalid-text': isPasswordLowerInvalid }">
        <div class="password-criteria">
          <span>At least 1 lowercase character</span>
          <MaterialIcon v-if="!isPasswordLowerInvalid" class="valid-icon" icon="check_circle" />
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.password-criteria-block {
  font-size: $font-size-sm;

  li {
    &.invalid-text {
      color: getColor($color-bdDanger);
    }

    .valid-icon {
      padding-left: 0.2em;
      font-size: 1.2em;
      color: getColor($color-bdSuccess);
    }
  }
}
</style>
