<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { EyeIcon, EyeCrossedIcon } from '../../../icons/components';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';
import { FormIconClickEvent } from '../../interfaces';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const showPassword = ref(false);

const formElement = useFormElement<FieldModel, string>({
  emit,
  props,
});

const {
  inputName,
  readonly,
  inputMode,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
  valueToInputFn,
} = useBaseFieldValues(formElement);

const { onBlur, onKeyPress, setValue } = useTextFormFieldMethods(formElement);

const { model, field, getSlotName, getModelState, emitFormEvent } = formElement;

const stringVal = computed(() => valueToInputFn.value(field.value.$model));

const icon = computed(() => {
  if (!getModelState('allowShowPassword')) {
    return null;
  }

  return showPassword.value ? EyeCrossedIcon : EyeIcon;
});

function onIconClick(name: string) {
  showPassword.value = !showPassword.value;

  emitFormEvent({
    event: 'form-icon-clicked',
    name,
  } as FormIconClickEvent<string>);
}

const fieldType = computed(() => (showPassword.value ? 'text' : 'password'));
</script>

<template>
  <InputGroupWrapper v-bind="{ field, model }">
    <input
      :name="inputName"
      :value="stringVal"
      :type="fieldType"
      :inputmode="inputMode"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @blur="onBlur"
      @keypress="onKeyPress"
      @input="setValue($event.target.value)"
    />
    <template v-slot:[`${model.$path}:append`] v-if="icon">
      <BInputGroupText @click="onIconClick(icon.name)">
        <slot :name="getSlotName('passwordShownIcon')" v-bind="{ field, model, isPasswordShown: showPassword }">
          <Component :is="icon" :key="icon.name" />
        </slot>
      </BInputGroupText>
    </template>
  </InputGroupWrapper>
</template>
