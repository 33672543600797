import { Validation } from '@vuelidate/core';
import { toDataModel, updateModel } from './helpers';
import { FormDefinition, FormModel } from './interfaces';
import { isEqual } from 'lodash';
import { ExtractPropTypes, PropType, computed, reactive, watch } from 'vue';
import { EmitFn } from 'vue/types/v3-setup-context';

export function defineManagedModelFormProps<T extends Object>() {
  return {
    model: {
      type: Object as PropType<T>,
      required: false,
    },
  };
}

export function defineManagedModelFormEmits<T extends Object>() {
  return {
    'update:validation': (_payload: Validation) => true,
    'update:model': (_payload: T) => true,
  };
}

export function useManagedModelForm<T extends Object>(options: {
  emit?: EmitFn<ReturnType<typeof defineManagedModelFormEmits<T>>>;
  props: Readonly<ExtractPropTypes<ReturnType<typeof defineManagedModelFormProps<T>>>>;
  formModel: FormModel;
  /**
   * Optional method defining how form should update on model changes
   * Defaults to using updateModel(input)
   */
  updateForm?: (model: T, formDef: FormDefinition) => void;
  /**
   * Optional method defining how model should be calculated from form
   * defaults to toDataModel(input)
   */
  calculateModel?: (formDef: FormDefinition) => T;
}) {
  const formDef = reactive<FormDefinition>({
    form: options.formModel,
    validation: null,
  });

  const calculatedModel = computed(() =>
    options.calculateModel ? options.calculateModel(formDef) : toDataModel(formDef.form)
  );

  watch(
    () => options.props.model,
    (val) => val && updateModel(formDef.form, val),
    { immediate: true, deep: true }
  );

  watch(calculatedModel, (val) => {
    if (!isEqual(val, options.props.model)) {
      options.emit && options.emit('update:model', val);
    }
  });

  watch(
    () => formDef.validation,
    (val) => val && options.emit && options.emit('update:validation', val)
  );

  return {
    formDef,
    calculatedModel,
  };
}
