<!-- eslint-disable vue/no-mutating-props -->
<script lang="ts" setup>
import { computed, PropType } from 'vue';
import TrashcanIcon from '../../icons/components/TrashcanIcon.vue';
import { VButton } from '../../common/components';
import FormElement from './FormElement.vue';
import { getState } from '../helpers/formHelpers';
import { RepeatModel, FormModel, FormEvent, FormActionEvent, FormAction } from '../interfaces/form';
import { FormValidation } from '../interfaces';

/**
 * Repeat Form component
 *
 * DEPRECATED - currently, no repeat forms are used, and repetition of entities is handled outside of the form system
 * Kept here pending removal of repeat form functionality from form model constructors
 */

const props = defineProps({
  form: {
    type: Object as PropType<FormValidation<FormModel[]>>,
    required: true,
  },

  model: {
    type: Object as PropType<RepeatModel>,
    required: true,
  },
});

const emit = defineEmits({
  'form-event': (_event: FormEvent<any>) => true,
});

const elementTitlePlaceholder = computed(() => getState(props.model, 'multipleTitlePlaceholder', ''));

const multipleClass = computed(() => getState(props.model, 'multipleClass', ''));

const itemName = computed(() => getState(props.model, 'itemName', ''));

function isOpen(model: FormModel | RepeatModel) {
  return getState(model, 'open') || props.form.$model.length <= 1;
}

function elementTitle(model: FormModel) {
  const titleField = props.model.$state.multipleTitleField;
  if (typeof titleField === 'string') {
    return null;
  }
  if (typeof titleField === 'function') {
    return props.model.$state.multipleTitleField(model).trim();
  }
  return null;
}

function onChildEvent(event: FormEvent) {
  if (event.event === 'form-action') {
    if ((event as FormActionEvent).action.name === 'remove-item-internal') {
      removeItem(props.form.$model.indexOf(event.model as FormModel));
    }
  }
  emit('form-event', event);
}

function addItem() {
  if (props.form.$model) {
    props.form.$model.push(props.model.$constructor());
  } else {
    props.form.$model = [props.model.$constructor()];
  }
}

function removeItem(index: number) {
  props.form.$model.splice(index, 1);
}

function onFormAction(action: FormAction) {
  setTimeout(() => {
    emit('form-event', {
      field: props.form,
      model: props.model,
      event: 'form-action',
      action,
    } as FormEvent<FormModel[]>);
  });
}
</script>

<template>
  <div class="form repeat-form">
    <div
      v-for="(childForm, index) in form.$each.$iter"
      :key="index"
      class="repeat-form-item"
      :class="['repeat-form-item', multipleClass, { singleItem: form.$each.$iter.lenght === 1 }]"
    >
      <div :class="['repeat-form-item-title', { untitled: !elementTitle(form.$model[index]) }]">
        <h3 class="repeat-form-item-title-text mb-s">
          {{ elementTitle(form.$model[index]) || elementTitlePlaceholder }}
        </h3>
        <VButton class="secondary icon-btn remove-item" @click="removeItem(index)">
          <TrashcanIcon />
        </VButton>
      </div>
      <div v-if="isOpen(form.$model[index])" class="repeat-form-item-body">
        <FormElement :field="childForm" :model="form.$model[index]" @form-event="onChildEvent">
          <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope" />
          </template>
        </FormElement>
      </div>
    </div>
    <div class="form-actions">
      <VButton class="add-item-action" @click="addItem"> Add {{ itemName ? itemName : 'item' }} </VButton>
      <VButton
        v-for="(action, index) in model.$actions"
        :key="index"
        :disabled="!action.enabled || (!action.allowOnInvalid && form.$invalid)"
        :class="action.class"
        :label="action.label"
        :loading-label="action.loadingLabel"
        :loading="action.loading"
        @click="onFormAction(action)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.repeat-form-item {
  position: relative;
  margin-bottom: 2em;

  .remove-item {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  .repeat-form-item-title {
    &.untitled .repeat-form-item-title-text {
      color: #ccc;
    }
  }

  .repeat-form-item-actions {
    margin-top: 1em;
    text-align: right;
  }

  .open-toggle {
    position: absolute;
    top: 3.6em;
    right: 2.4em;
    cursor: pointer;
    color: #ccc;
    user-select: none;
    font-size: 1.3em;
    line-height: 0;
  }
}

.repeat-form-actions {
  margin-top: 1em;
  text-align: right;
}
</style>
