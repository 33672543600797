<script lang="ts" setup>
import FormField from './fields/FormField.vue';
import { FormModel } from '../interfaces/form';
import { computed, ref, defineAsyncComponent } from 'vue';
import { useFormElement } from '../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../composables/useFormElementInterfaces';

const RepeatForm = defineAsyncComponent(() => import('./RepeatForm.vue'));

const DynamicForm = defineAsyncComponent(() => import('./DynamicForm.vue'));

const props = defineProps(makeUseFormElementProps<FormModel>());

const emit = defineEmits(makeUseFormElementEmits());

const item = ref<InstanceType<typeof FormField>>();

const { modelStateRef, getModelState, toggleModelState, model, field, emitFormEvent } = useFormElement({
  emit,
  props,
});

const isRepeatForm = modelStateRef('multiple', false);

const open = modelStateRef('open', false);

const isTogglable = modelStateRef('togglable');

const isSection = computed(() => getModelState('display') === 'section');

const title = modelStateRef('title');

function onSectionClick() {
  if (isSection.value && !open.value) {
    emitFormEvent({ event: 'form-section-select' });
  }
}

function toggle(event: Event) {
  toggleModelState('open');
  event.stopPropagation();
}
</script>

<template>
  <div :class="{ 'form-section': isSection }" @click="onSectionClick">
    <template v-if="isSection">
      <h1 v-if="title" class="form-title">
        {{ title }}
      </h1>
      <span v-if="isTogglable" class="open-toggle" @click="toggle">
        {{ open ? '&#9650;' : '&#9660;' }}
      </span>
    </template>
    <RepeatForm v-if="isRepeatForm" ref="item" :form="field" :model="model" @form-event="emitFormEvent">
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </RepeatForm>
    <DynamicForm v-else ref="item" :form="field" @form-event="emitFormEvent">
      <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </DynamicForm>
  </div>
</template>

<style lang="scss" scoped>
.form-section {
  border: 1px solid #ccc;
  padding: 2em;
  border-radius: 1em;
  position: relative;
  margin-bottom: 2em;

  .form-title {
    padding: 0;
    margin: 0;
    margin-top: -10px;
    font-size: 1.5em;
    font-weight: 200;
  }

  .open-toggle {
    position: absolute;
    top: 1.6em;
    right: 2em;
    cursor: pointer;
    color: #ccc;
    user-select: none;
  }
}
</style>
