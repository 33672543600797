<script lang="ts" setup>
import { ref, watch, computed, nextTick, getCurrentInstance } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import coinify from 'coinify';
import VueSelect from 'vue-select';
import isEqual from 'lodash/isEqual';
import { ExchangeIcon, InfoTooltipIcon } from '../../../icons/components';
import { allowedCurrencies, Currency, AmountType } from 'ah-api-gateways';
import { formatCurrencyValue, cleanCurrencyString, formatCurrencyString } from '../../../helpers/currency';
import FormFieldErrors from './FormFieldErrors.vue';

interface CurrencyExchangeSettings {
  buyCurrency: Currency;
  sellCurrency: Currency;
  amount: number;
  tradeDirection: string;
  amountType: AmountType;
}

const props = defineProps(makeUseFormElementProps<FieldModel, CurrencyExchangeSettings>());

const component = getCurrentInstance()?.proxy;

const emit = defineEmits(makeUseFormElementEmits<CurrencyExchangeSettings>());

const amountType = ref(AmountType.SELL);

const amount = ref<number>();

const inputText = ref('');

const inputDirty = ref(false);

const currencyInput = ref<HTMLInputElement>();

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, inputClass, inputErrorClass, fieldErrorsShown, customErrors } = useBaseFieldValues(formElement);

const { modelStateRef, field, getModelState, setValue } = formElement;

const sellCurrencies = modelStateRef(
  'sellCurrencies',
  allowedCurrencies.filter((c) => c !== buyCurrency.value)
);

const buyCurrencies = modelStateRef(
  'buyCurrencies',
  allowedCurrencies.filter((c) => c !== sellCurrency.value)
);

const buyCurrency = computed<Currency>({
  get: () =>
    (field.value.$model || {}).buyCurrency || getModelState('selectedBuyCurrency', setDefaults.value ? 'EUR' : null),
  set: (val: string) => updateModel({ buyCurrency: val }, keepPristine.value),
});

const sellCurrency = computed<Currency>({
  get: () =>
    (field.value.$model || {}).sellCurrency || getModelState('selectedSellCurrency', setDefaults.value ? 'GBP' : null),
  set: (val: string) => updateModel({ sellCurrency: val }, keepPristine.value),
});

const isTradeDirectionSellBuy = computed<boolean>({
  get: () => tradeDirection.value === `${sellCurrency.value}${buyCurrency.value}`,
  set: (val: boolean) => {
    if (val) {
      updateModel({ tradeDirection: `${sellCurrency.value}${buyCurrency.value}` });
    } else {
      updateModel({ tradeDirection: `${buyCurrency.value}${sellCurrency.value}` });
    }
  },
});

const setDefaults = modelStateRef('setDefaults', true);

const displaySymbols = modelStateRef('displaySymbols', true);

const keepPristine = modelStateRef('keepPristine', true);

const currencyTitle = modelStateRef('currencyTitle', 'Amount');

const amountTitle = modelStateRef('amountTitle', '');

const showAsDirty = computed(() => {
  return inputDirty.value || customErrors.value.length > 0;
});

const tradeDirection = computed<string>(() => {
  return (field.value.$model || {}).tradeDirection || `${sellCurrency.value}${buyCurrency.value}`;
});

const toCurrency = computed<Currency>(() => {
  return isTradeDirectionSellBuy.value ? buyCurrency.value : sellCurrency.value;
});

const fromCurrency = computed<Currency>(() => {
  return isTradeDirectionSellBuy.value ? sellCurrency.value : buyCurrency.value;
});

const supportText = computed(() => {
  return getModelState(
    'supportText',
    `If you can't find the currency you're looking for please contact our trading desk`
  );
});

const currencyObj = computed(() => {
  return amountType.value === AmountType.BUY ? buyCurrencyObj.value : sellCurrencyObj.value;
});

const buyCurrencyObj = computed(() => {
  return coinify.get(buyCurrency.value);
});

const sellCurrencyObj = computed(() => {
  return coinify.get(sellCurrency.value);
});

const tradeDirectionRate = computed(() => {
  return isTradeDirectionSellBuy.value
    ? getModelState('sellUnprotectedSpotRate', '-', true)
    : getModelState('buyUnprotectedSpotRate', '-', true);
});

function updateInputText(force = false) {
  const existing = cleanNumber(inputText.value);

  if (force || existing !== (field.value.$model || {}).amount) {
    inputText.value = formatNumber((field.value.$model || {}).amount) || '';
  }
}

function switchCurrencies() {
  updateModel(
    {
      buyCurrency: sellCurrency.value,
      sellCurrency: buyCurrency.value,
      amountType: amountType.value === AmountType.SELL ? AmountType.BUY : AmountType.SELL,
      tradeDirection: tradeDirection.value,
    },
    true
  );
}

function onBuyCurrencyChange(val: Currency) {
  if (buyCurrency.value !== val) {
    buyCurrency.value = val;
  }
}

function onSellCurrencyChange(val: Currency) {
  if (sellCurrency.value !== val) {
    sellCurrency.value = val;
  }
}

function formatNumber(num: number | null) {
  if (typeof num === 'number' && !Number.isNaN(num)) {
    return formatCurrencyValue(num);
  }
  return '';
}

function getCurrencyObj(currency: Currency) {
  try {
    return coinify.get(currency);
  } catch (e) {
    return { symbol: '?' };
  }
}

function showBlurError() {
  if (setDefaults.value) {
    onBlur();
  }
}

function onBlur() {
  setTimeout(() => {
    field.value.$touch();
    inputDirty.value = true;
    updateInputText(true);
    component?.$forceUpdate();
  });
}

function updateModel(update?: any, keepPristine = false) {
  const model: Partial<CurrencyExchangeSettings> = {
    amountType: amountType.value,
    amount: amount.value,
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    tradeDirection: tradeDirection.value,
    ...update,
  };

  if (!update.tradeDirection) {
    model.tradeDirection = isTradeDirectionSellBuy.value
      ? `${model.sellCurrency}${model.buyCurrency}`
      : `${model.buyCurrency}${model.sellCurrency}`;
  }

  if (!isEqual(model, field.value.$model)) {
    setValue(model, keepPristine);
  }
}

function setAmount(value: string, keepPristine = false) {
  const cursorStart = currencyInput.value!.selectionStart;
  const cursorEnd = currencyInput.value!.selectionEnd;
  value = value.replace(/[a-zA-Z]/g, '');
  const cleanVal = value ? cleanCurrencyString(value, undefined, true) : value;
  const cleaned = cleanNumber(cleanVal);
  if (cleanVal && !Number.isNaN(cleaned)) {
    inputText.value = formatCurrencyString(cleanVal);
  } else {
    inputText.value = cleanVal;
  }
  component?.$forceUpdate();

  if (!Number.isNaN(cleaned)) {
    if (cleaned > 0) {
      updateModel(
        {
          amount: typeof cleaned === 'number' && cleaned >= 0 ? cleaned : null,
        },
        keepPristine
      );
    }
    nextTick(() => {
      if (typeof cursorStart === 'number' && typeof cursorEnd === 'number') {
        if (value === '' || cleaned === 0) {
          currencyInput.value!.setSelectionRange(0, 0);
        } else {
          const delta = calcLengthDifference(
            value.substr(0, cursorStart),
            currencyInput.value!.value.substr(0, cursorStart)
          );
          currencyInput.value!.setSelectionRange(cursorStart + delta, cursorEnd + delta);
        }
      }
    });
  }
}

function calcLengthDifference(prev: string, curr: string): number {
  const deltaSeparatorsPrev = prev.length - cleanCurrencyString(prev).length;
  const deltaSeparatorsNow = curr.length - cleanCurrencyString(curr).length;

  return deltaSeparatorsNow - deltaSeparatorsPrev;
}

function cleanNumber(amount: string): number {
  const cleanVal = cleanCurrencyString(amount);
  const number = Number(cleanVal);

  if (Number.isNaN(number)) {
    return NaN;
  }
  return Number(number.toFixed(2));
}
watch(
  () => field.value.$dirty,
  () => {
    if (!field.value.$dirty) {
      inputDirty.value = false;
    }
  }
);

watch(
  () => field.value.$model,
  () => {
    if (field.value.$model) {
      amountType.value = field.value.$model.amountType || AmountType.SELL;
      amount.value = field.value.$model.amount;
      updateInputText();
    }
  },
  { immediate: true, deep: true }
);

watch(amountType, () => {
  if (amountType.value !== field.value.$model.amountType) {
    updateModel({ amountType: amountType.value }, true);
  }
});
</script>

<template>
  <div class="currency-exchange form">
    <div class="currency-selection">
      <div class="from">
        <label class="field-group-field-label currency-label"> Converting from: </label>
        <VueSelect
          ref="sell CurrencySelect"
          :value="sellCurrency"
          :class="[
            'field-group-field-input hide-error currency-select',
            inputClass,
            { [inputErrorClass]: fieldErrorsShown },
          ]"
          :clearable="false"
          :options="sellCurrencies"
          :disabled="readonly"
          @input="onSellCurrencyChange"
        >
          <template v-for="optionName in ['option', 'selected-option']" v-slot:[optionName]="option">
            <span v-if="option" :key="optionName" class="currency-select-option">
              {{ option.label }}
              <span class="symbol" v-if="displaySymbols">
                {{ getCurrencyObj(option.label).symbol }}
              </span>
            </span>
          </template>
        </VueSelect>
      </div>
      <div class="change-currencies">
        <div class="change-currencies-button" @click="switchCurrencies">
          <ExchangeIcon />
        </div>
      </div>
      <div class="to">
        <label class="field-group-field-label currency-label"> To: </label>
        <VueSelect
          :value="buyCurrency"
          :class="[
            'field-group-field-input hide-error currency-select',
            inputClass,
            { [inputErrorClass]: fieldErrorsShown },
          ]"
          :clearable="false"
          :options="buyCurrencies"
          :disabled="readonly"
          @input="onBuyCurrencyChange"
          @search:blur="showBlurError"
        >
          <template v-for="optionName in ['option', 'selected-option']" v-slot:[optionName]="option">
            <span v-if="option.label" :key="optionName" class="currency-select-option">
              {{ option.label }}
              <span class="symbol" v-if="displaySymbols">
                {{ getCurrencyObj(option.label).symbol }}
              </span>
            </span>
          </template>
        </VueSelect>
      </div>
      <InfoTooltipIcon v-b-tooltip="supportText" class="tooltip-icon" />
      <div class="trade-direction">
        <div>
          <span v-if="displaySymbols" class="trade-direction-number">
            1
            {{ getCurrencyObj(fromCurrency).symbol }}
          </span>
          <span v-else class="trade-direction-number"> {{ fromCurrency }} 1 </span>
        </div>
        <div class="change-currencies-button" @click="isTradeDirectionSellBuy = !isTradeDirectionSellBuy">
          <ExchangeIcon />
        </div>
        <div>
          <span v-if="displaySymbols" class="trade-direction-number">
            {{ tradeDirectionRate }}
            {{ getCurrencyObj(toCurrency).symbol }}
          </span>
          <span v-else class="trade-direction-number"> {{ toCurrency }} {{ tradeDirectionRate }} </span>
        </div>
      </div>
    </div>
    <div class="amount-selection">
      <div>
        <label class="field-group-field-label currency-label"> {{ currencyTitle }} </label>
        <div class="amount-convert">
          <div class="amount-type">
            <VueSelect
              v-model="amountType"
              :class="[
                'field-group-field-input hide-error currency-select',
                inputClass,
                { [inputErrorClass]: fieldErrorsShown },
              ]"
              :clearable="false"
              :searchable="false"
              :disabled="readonly"
              :reduce="(option) => option.value"
              :options="[
                { value: 'SELL', label: 'from' },
                { value: 'BUY', label: 'to' },
              ]"
            >
              <template v-for="optionName in ['option', 'selected-option']" v-slot:[optionName]="option">
                <span v-if="option" :key="optionName" class="currency-select-option">
                  <span class="symbol" v-if="displaySymbols">
                    {{ option.value === 'SELL' ? sellCurrencyObj.symbol : buyCurrencyObj.symbol }}
                  </span>
                  {{ option.value === 'SELL' ? sellCurrency : buyCurrency }}
                </span>
              </template>
            </VueSelect>
          </div>
        </div>
      </div>
      <div class="currency-field">
        <label class="field-group-field-label currency-label" v-if="amountTitle"> {{ amountTitle }} </label>
        <div class="input-holder" :class="{ 'mock-label-padding': !amountTitle }">
          <input
            ref="currencyInput"
            :value="inputText"
            :disabled="readonly"
            inputmode="decimal"
            :class="[
              'field-group-field-input currency-field-input',
              inputClass,
              {
                [inputErrorClass]: fieldErrorsShown,
                'hide-error': !showAsDirty,
                'is-dirty': showAsDirty,
              },
            ]"
            @input="setAmount($event.target.value, true)"
            @blur="onBlur"
          />
          <div class="amount-currency" v-if="displaySymbols">
            {{ currencyObj.symbol }}
          </div>
        </div>

        <FormFieldErrors v-if="showAsDirty" :field="field" :model="model" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currency-exchange ::v-deep {
  .tooltip-icon {
    margin-left: 0.5em;
  }

  .change-currencies-button {
    background: $lightGrey;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;

    svg {
      width: 2em;
      height: 1.2em;
      position: relative;
      top: 0.15em;
      transform: rotate(90deg);

      path {
        fill: $grey;
      }
    }
  }

  .currency-selection,
  .amount-selection {
    float: left;
    margin-bottom: math.div($padded-space, 2);

    @include tablet {
      margin-right: math.div($padded-space, 2);
    }

    @include desktop {
      margin-right: $padded-space;
    }

    .currency-select {
      .currency-select-option {
        .symbol {
          float: left;
          font-weight: 700;
          padding-right: math.div($padded-space, 4);
        }
      }
    }
  }

  .currency-selection {
    > div {
      display: inline-block;
    }

    .trade-direction {
      display: flex;
      padding-top: 1rem;
      align-items: center;

      &-number {
        font-size: $font-size-sm;

        &:first-child {
          margin-right: math.div($padded-space, 4);
        }

        &:last-child {
          margin-left: math.div($padded-space, 4);
        }
      }
    }
  }

  .currency-select,
  .amount-type {
    width: 7.5rem;

    @include tablet {
      width: 9rem;
    }

    @include desktop {
      width: 12rem;
    }
  }

  .change-currencies {
    margin: 0 math.div($padded-space, 4);
    position: relative;
    z-index: 1;

    @include desktop {
      margin: 0 math.div($padded-space, 2);
    }
  }

  .amount-selection {
    display: flex;
    .amount-convert {
      display: flex;
      .amount-type {
        display: flex;
        margin-right: math.div($padded-space, 4);

        @include desktop {
          margin-right: math.div($padded-space, 2);
        }
      }

      .currency-select {
        display: inline-block;
      }
    }
    .currency-field {
      width: 10rem;

      .input-holder {
        display: flex;
        align-items: center;
      }

      @include tablet {
        width: 12rem;
      }

      @include desktop {
        width: 18rem;
      }

      .currency-field-input {
        text-align: right;
      }

      .amount-currency {
        color: $darkGrey;
        font-weight: 700;
        padding-left: 0.5rem;

        @include tablet {
          left: math.div($padded-space, 2);
        }
      }
    }
  }

  .currency-field {
    position: relative;
  }
}
</style>
