<script setup lang="ts">
import { ref } from 'vue';
import { useServices } from 'bd-common/src/services';
import AppLogo from 'bd-common/src/components/common/AppLogo.vue';
import NavViewGrid from 'bd-common/src/components/common/structure/NavViewGrid.vue';
import DeclineInviteSuccessMessage from '../components/registration/DeclineInviteSuccessMessage.vue';
import InviteErrorMessage from '../components/registration/InviteErrorMessage.vue';

enum InviteStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

const services = useServices();

const props = defineProps({
  token: {
    type: String,
  },
});

const inviteStatus = ref<InviteStatus>();

if (props.token) {
  services.clientInvites.declineClientInviteByToken(props.token).subscribe(
    () => {
      inviteStatus.value = InviteStatus.VALID;
    },
    () => {
      inviteStatus.value = InviteStatus.INVALID;
    }
  );
}
</script>

<template>
  <NavViewGrid ref="navViewGrid" hide-sidebar>
    <template #top-nav>
      <div class="top-nav-holder public-nav">
        <div class="logo">
          <AppLogo />
        </div>
      </div>
    </template>
    <DeclineInviteSuccessMessage v-if="inviteStatus === InviteStatus.VALID"></DeclineInviteSuccessMessage>
    <InviteErrorMessage v-else-if="inviteStatus === InviteStatus.INVALID"></InviteErrorMessage>
  </NavViewGrid>
</template>

<style lang="scss" scoped>
.top-nav-holder {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: 'logo middle-area buttons';
  padding: 0 3em;

  .logo {
    grid-area: logo;
    display: flex;
    align-items: center;

    .app-logo {
      width: 40px;
      height: 40px;
      margin-bottom: 0;
      position: relative;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
  }
}
</style>
