<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { formatNumberValue } from 'ah-common-lib/src/helpers/numbers';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, number>());

const emit = defineEmits(makeUseFormElementEmits<number>());

const textInput = ref<HTMLInputElement>();

const PERCENTAGE_TO_BASIS_CONVERSION = 100;

const savedStringVal = ref('');

const formElement = useFormElement<FieldModel, number>({
  emit,
  props,
});

const {
  dirtyOnInput,
  maxLength,
  inputName,
  fieldType,
  readonly,
  inputMode,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
} = useBaseFieldValues(formElement);

const { onBlur, onKeyPress, onPaste } = useTextFormFieldMethods(formElement, { setValue });

const { model, field, setValue: formElementSetValue } = formElement;

const stringVal = computed(() => {
  return formatNumberValue(savedStringVal.value, maxDecimalPlaces.value);
});

// Max decimal places allowed
const maxDecimalPlaces = computed(() => {
  return model.value.$state.unit === 'percentage' ? 2 : 0;
});

/**
 * We always do the percentage to basis conversation and only convert to basis value to
 * ensure the 2 decimal places maximum
 */
function stringToVal(value: string, capValue = true) {
  try {
    let val = parseFloat(value) * PERCENTAGE_TO_BASIS_CONVERSION;
    if (capValue) {
      val = Math.floor(val);
    }
    if (model.value.$state.unit !== 'percentage') {
      val /= PERCENTAGE_TO_BASIS_CONVERSION;
    }
    return val;
  } catch (e) {
    return NaN;
  }
}

function valToString(value: number) {
  if (model.value.$state.unit === 'percentage') {
    return (value / PERCENTAGE_TO_BASIS_CONVERSION).toFixed(maxDecimalPlaces.value);
  }
  return value.toFixed(maxDecimalPlaces.value);
}

function setValue(value: string) {
  let val: number | undefined;

  if (!isNaN(stringToVal(value))) {
    val = stringToVal(value.toString());
  }
  savedStringVal.value = value;
  setTimeout(() => {
    const typedVal = val !== undefined && val > -1 ? valToString(val) : '';
    if (stringToVal(value.toString(), false) !== stringToVal(typedVal, false)) {
      textInput.value!.value = formatNumberValue(typedVal, maxDecimalPlaces.value);
    } else {
      textInput.value!.value = formatNumberValue(value, maxDecimalPlaces.value);
    }
  });

  if (val !== field.value.$model) {
    formElementSetValue(val, !field.value.$dirty && !dirtyOnInput.value);
  }
}

watch(
  [() => field.value.$model, () => model.value.$state.unit],
  () => {
    const val = field.value.$model;
    const savedVal = stringToVal(savedStringVal.value);

    if ((!Number.isNaN(val) && val !== savedVal) || val === 0) {
      savedStringVal.value = valToString(val);
    }
  },
  { immediate: true }
);
</script>

<template>
  <InputGroupWrapper v-bind="{ field, model }">
    <input
      ref="textInput"
      :maxlength="maxLength"
      :name="inputName"
      :value="stringVal"
      :type="fieldType"
      :inputmode="inputMode"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @blur="onBlur"
      @paste="onPaste"
      @keypress="onKeyPress"
      @input="setValue($event.target.value)"
    />
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </InputGroupWrapper>
</template>
