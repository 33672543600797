<script setup lang="ts">
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import RemainingCharCounter from 'ah-common-lib/src/common/components/RemainingCharCounter.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, any>());

const emit = defineEmits(makeUseFormElementEmits<any>());

const formElement = useFormElement({
  emit,
  props,
});

const { fieldErrorsShown, disabled, readonly, inputErrorClass, maxLength } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName } = formElement;

const inputGroupClass = modelStateRef('inputGroupClass', '');

const showLengthCounter = modelStateRef('showLengthCounter', false);

const hideAppendBg = modelStateRef('removeAppendBg', false);
</script>

<template>
  <div class="field-group-input-container">
    <BInputGroup
      :class="[
        'field-group-input',
        inputGroupClass,
        { [inputErrorClass]: fieldErrorsShown, disabled, readonly },
        { 'without-append-bg': hideAppendBg },
      ]"
    >
      <template #prepend v-if="getSlotName('prepend', false)">
        <slot :name="getSlotName('prepend')" v-bind="{ field, model }" />
      </template>
      <slot />
      <template #append v-if="showLengthCounter || getSlotName('append', false)">
        <RemainingCharCounter
          :maxLength="maxLength"
          :length="field.$model ? field.$model.length : 0"
          v-if="showLengthCounter"
        />
        <slot :name="getSlotName('append')" v-bind="{ field, model }" />
      </template>
    </BInputGroup>
  </div>
</template>

<style lang="scss" scoped>
.without-append-bg {
  .field-group-field-input {
    border-right: none;
  }
  .input-group-text {
    border-left: none;
    background-color: transparent;
  }
}
</style>
