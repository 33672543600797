<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';
import { extractIBAN, friendlyFormatIBAN, electronicFormatIBAN } from 'ibantools';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const formElement = useFormElement<FieldModel, string>({
  emit,
  props,
});

const {
  dirtyOnInput,
  maxLength,
  inputName,
  fieldType,
  readonly,
  inputMode,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
} = useBaseFieldValues(formElement);

const { onBlur, onKeyPress, onPaste } = useTextFormFieldMethods(formElement, { setValue });

const { model, field, setModelState, setValue: formElementSetValue } = formElement;

const stringVal = computed(() => {
  return friendlyFormatIBAN(field.value.$model) || field.value.$model;
});

function setValue(value: string) {
  const iban = extractIBAN(electronicFormatIBAN(value) ?? '');
  setModelState('validIBAN', iban.valid);
  formElementSetValue(iban.valid ? iban.iban : value, !field.value.$dirty && !dirtyOnInput.value);
}

watch(stringVal, () => {
  const iban = extractIBAN(electronicFormatIBAN(stringVal.value) ?? '');
  setModelState('validIBAN', iban.valid);
});

setValue(field.value.$model);
</script>

<template>
  <InputGroupWrapper v-bind="{ field, model }">
    <input
      :maxlength="maxLength"
      :name="inputName"
      :value="stringVal"
      :type="fieldType"
      :inputmode="inputMode"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @blur="onBlur"
      @paste="onPaste"
      @keypress="onKeyPress"
      @input="setValue($event.target.value)"
    />
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </InputGroupWrapper>
</template>
