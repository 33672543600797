<script setup lang="ts">
import NavViewGrid from 'bd-common/src/components/common/structure/NavViewGrid.vue';
import AppLogo from 'bd-common/src/components/common/AppLogo.vue';
import { useServices } from 'bd-common/src/services';
import ClientInviteRegistrationFlow from '../components/registration/ClientInviteRegistrationFlow.vue';
import { ref } from 'vue';
import { ClientInvite } from 'ah-api-gateways/models/client/clientInvite';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useRouter } from 'vue-router/composables';
import { from, of, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import LoadingSplash from 'bd-common/src/components/common/LoadingSplash.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import InviteErrorMessage from '../components/registration/InviteErrorMessage.vue';

const services = useServices();

const clientInvite = ref<ClientInvite>();

const reqManager = useRequestManager().manager;

const router = useRouter();

const authStore = useAuthStore();

const navViewGrid = ref<InstanceType<typeof NavViewGrid>>();

// We type token as a string to satisfy type requirements of child components
// isValidToken ensures this assumption is verified
const token: string = router.currentRoute.query.token as string;

const isValidToken = typeof token === 'string' && token;

function requestToken() {
  if (authStore.hasRegistrationSession) {
    return services.clientInvites.getSessionClientInvite({ options: { errors: { silent: true } } });
  } else if (isValidToken) {
    return services.clientInvites
      .getClientInviteByToken(token as string, { options: { errors: { silent: true } } })
      .pipe(
        mergeMap((invite) => {
          if (authStore.loggedInUser && invite.applicantEmail !== authStore.loggedInIdentity?.email) {
            return from(
              authStore
                .logout({ redirect: false })
                .catch()
                .then(() => invite)
            );
          }
          return of(invite);
        })
      );
  } else {
    return throwError('No valid way to load token');
  }
}

reqManager.currentOrNew('loadInvite', requestToken()).subscribe((invite) => {
  clientInvite.value = invite;
});

function onStepChange() {
  navViewGrid.value?.scrollView(0);
}
</script>

<template>
  <NavViewGrid ref="navViewGrid" hide-sidebar>
    <template #top-nav>
      <div class="top-nav-holder public-nav">
        <div class="logo">
          <AppLogo />
        </div>
      </div>
    </template>
    <LoadingSplash class="mt-5" v-if="reqManager.requestStates.loadInvite === 'pending'" />
    <ClientInviteRegistrationFlow
      v-else-if="clientInvite"
      @update:step="onStepChange"
      :token="token"
      :invite="clientInvite"
      class="row register-block mt-5"
    />
    <InviteErrorMessage v-else-if="reqManager.requestStates.loadInvite === 'error'" />
  </NavViewGrid>
</template>

<style lang="scss" scoped>
.top-nav-holder {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: 'logo middle-area buttons';
  padding: 0 3em;

  .logo {
    grid-area: logo;
    display: flex;
    align-items: center;

    .app-logo {
      width: 40px;
      height: 40px;
      margin-bottom: 0;
      position: relative;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
  }
}
</style>
