<script lang="ts" setup>
import { computed } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { getFieldErrorList } from '../../helpers';

const props = defineProps(makeUseFormElementProps<FieldModel>());

const emit = defineEmits(makeUseFormElementEmits());

const formElement = useFormElement({
  emit,
  props,
});

const { fieldErrorsShown } = useBaseFieldValues(formElement);

const { field, model, getSlotName } = formElement;

const fieldErrors = computed(() => getFieldErrorList(field.value, model.value));
</script>

<template>
  <div v-if="fieldErrorsShown" class="field-group-errors">
    <slot :name="getSlotName('errors-prepend')" v-bind="{ field, model }" />
    <template v-for="error in fieldErrors">
      <div v-if="error.html" :key="error.name" class="error" v-html="error.error" />
      <div :key="error.name" class="error" v-else>
        {{ error.error }}
      </div>
    </template>
  </div>
</template>
