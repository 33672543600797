<script setup lang="ts">
import { PropType, ref } from 'vue';
import CompanyDetailsForm, { InviteCompanyDetails } from './CompanyDetailsForm.vue';
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { ClientInvite, ClientInviteCompanyAddress } from 'ah-api-gateways/models/client/clientInvite';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useServices } from 'bd-common/src/services';
import RegisteredAddressForm from './RegisteredAddressForm.vue';
import { useSettingsStore } from 'bd-common/src/store/settingsStore';
import { submitForm } from 'ah-common-lib/src/form/helpers/formHelpers';
import { mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { waitForEntityChange } from 'ah-requests';

/**
 * Invite Redeem Step
 *
 * Collects user information and redeems a Client Invite, initializing a temporary session
 */

const props = defineProps({
  invite: {
    type: Object as PropType<ClientInvite>,
    required: true,
  },
});

const emit = defineEmits({
  submitted: () => true,
  failed: () => true,
});

const requestManager = useRequestManager().manager;
const services = useServices();
const settingsStore = useSettingsStore();
const authStore = useAuthStore();

// Invite is not watched, it is explicitly expected to exist on component load
const companyDetails = ref<InviteCompanyDetails>({
  country: props.invite.companyCountry,
  companyName: props.invite.companyName,
  registrationNumber: props.invite.registrationNumber,
  entityType: props.invite.entityType,
});

const inviteRegisteredAddress = ref<ClientInviteCompanyAddress>();
const inviteRegisteredAddressValidation = ref<FormValidation | undefined>();

function submit() {
  inviteRegisteredAddressValidation.value && submitForm(inviteRegisteredAddressValidation.value);

  if (inviteRegisteredAddressValidation.value?.$invalid === false) {
    requestManager
      .sameOrCancelAndNew(
        'registerClient',
        services.clientInvites
          .registerClientFromInvite({
            companyDetails: {
              address: inviteRegisteredAddress.value!,
            },
          })
          .pipe(
            // Waiting for session update until a client is added to the session data (due to CQRS delay)
            mergeMap(() =>
              waitForEntityChange(
                () =>
                  from(
                    useAuthStore()
                      .refreshSession()
                      .then(() => authStore.userData)
                  ),
                (session) => !!session?.individual?.client?.id
              )
            )
          ),
        props.invite
      )
      .subscribe(
        () => emit('submitted'),
        () => emit('failed')
      );
  }
}

requestManager.newPromise('loadCountries', settingsStore.loadCountries()).then(() => {
  companyDetails.value!.country =
    settingsStore.countries.item?.find((c) => c.cc === props.invite.companyCountry)?.name ??
    props.invite.companyCountry;
});
requestManager.newPromise('loadEntityTypes', settingsStore.loadEntityTypes()).then(() => {
  companyDetails.value!.entityType =
    settingsStore.entityTypeList?.find((entityType) => entityType.id === props.invite.entityType)?.legalForm ??
    props.invite.entityType;
});
</script>

<template>
  <div class="company-details-step">
    <div class="card mb-4">
      <div class="card-body">
        <h5 class="mb-4">Company Details</h5>
        <CompanyDetailsForm :model.sync="companyDetails" />
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">Registered Address</h5>
        <RegisteredAddressForm
          :validation.sync="inviteRegisteredAddressValidation"
          :model.sync="inviteRegisteredAddress"
        />
      </div>
    </div>
    <div class="buttons mt-3">
      <span class="required-text">* Mandatory</span>
      <VButton class="btn-primary" @click="submit" :loading="requestManager.requestStates.registerClient === 'pending'"
        >Next
        <MaterialIcon icon="arrow_forward" />
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .required-text {
    color: getColor($color-bdDanger);
    font-size: $font-size-sm;
    font-weight: 600;
  }
}
</style>
