<script lang="ts" setup>
import UserLogin from 'bd-common/src/components/session/UserLogin.vue';
import SessionBlock from 'bd-common/src/components/session/SessionBlock.vue';
import { useAuthStore } from 'bd-common/src/store/authStore';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import { LoginStage } from 'bd-common/src/components/session/userLoginInterfaces';
import { UserRole } from 'ah-api-gateways/models/user/userRole';

const props = withDefaults(
  defineProps<{
    redirectTo?: string;
    redirectToQuery?: string;
  }>(),
  {
    redirectTo: '/app',
    redirectToQuery: '',
  }
);

const userLogin = ref<typeof UserLogin>();

const authStore = useAuthStore();

const router = useRouter();

const currentStage = ref<LoginStage>();

onBeforeMount(() => {
  /**
   * Clearing session for the use case where a logged in user goes back
   * to the login screen while with a registration session
   */
  if (authStore.hasRegistrationSession) {
    authStore.logout();
  }
});

function onLoginSuccess() {
  if (authStore.userData?.role === UserRole.CLIENT_REGISTRATION) {
    router.push('/register');
    return;
  }
  let query = {};
  try {
    query = JSON.parse(props.redirectToQuery);
  } catch (e) {}
  router.push({ path: props.redirectTo, query });
}
</script>

<template>
  <SessionBlock narrow id="login-view">
    <UserLogin id="user-login" ref="userLogin" @login-success="onLoginSuccess" @stage-change="currentStage = $event" />
  </SessionBlock>
</template>
