export enum LoginStage {
  login = 'login',
  otp = 'otp',
  autologin = 'autologin',
  verifyEmail = 'verifyEmail',
  success = 'success',
}

export interface AutoLoginDetails {
  email: string;
  password: string;
}
