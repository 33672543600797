<script setup lang="ts">
import MaterialIcon from 'bd-common/src/components/common/MaterialIcon.vue';
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
});

const tooltipTitle = computed(() => `<h4>${props.title}</h4>`);

const tooltipText = computed(() => `<span>${props.text}</span>`);

const content = computed(() => `<div>${tooltipTitle.value}${tooltipText.value}</div>`);

const tooltipObject = computed(() => {
  return {
    html: true,
    customClass: 'info-tooltip',
    placement: 'top',
  };
});
</script>

<template>
  <span class="info-component-wrapper">
    <MaterialIcon icon="info" class="info-icon" v-b-tooltip.hover="tooltipObject" :title="content" />
  </span>
</template>

<style lang="scss" scoped>
::v-deep {
  .info-component-wrapper {
    display: inline-block;
    white-space: nowrap;
    z-index: 1;
    position: relative;
  }
  .info-icon {
    color: getColor($color-bdPrimary) !important;
    font-size: 1.5em;
    cursor: pointer;
    line-height: 0;
    margin-right: 0.3em;
  }
}
</style>
