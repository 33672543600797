<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, number>());

const emit = defineEmits(makeUseFormElementEmits<number>());

const currInputVal = ref('');

const lastNumber = ref<number>();

const formElement = useFormElement<FieldModel, number>({
  emit,
  props,
});

const {
  inputName,
  readonly,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
  valueToInputFn,
  inputToValueFn,
  dirtyOnInput,
  emptyValue,
  number,
} = useBaseFieldValues(formElement);

const { onBlur, onKeyPress } = useTextFormFieldMethods(formElement, { setValue });

const { model, field, modelStateRef, component, setValue: formElSetValue } = formElement;

const stringVal = computed(() => {
  const val = valueToInputFn.value(field.value.$model);

  if (number.value && typeof val === 'number') {
    return Number.isNaN(val) ? emptyValue.value : val.toString();
  }

  return val;
});

function setValue(value: string) {
  currInputVal.value = value;
  let val: number | string | undefined = parseInput(value);

  if (typeof val === 'number' && !isNaN(val)) {
    if (typeof min.value === 'number' && !isNaN(min.value)) {
      val = Math.max(val, min.value);
    }
    if (typeof max.value === 'number' && !isNaN(max.value)) {
      val = Math.min(val, max.value);
    }
  } else {
    val = undefined;
  }

  let numberVal: number | undefined = typeof val === 'string' ? parseInput(value) : val;

  lastNumber.value = numberVal;
  formElSetValue(inputToValueFn.value(numberVal), !field.value.$dirty && !dirtyOnInput.value);
  component.$forceUpdate();
}

function onNumberFieldBlur() {
  currInputVal.value = stringVal.value;
  onBlur();
}

function parseInput(value: string) {
  return integer.value ? parseInt(value, 10) : parseFloat(value);
}

const integer = modelStateRef('integer', false);

const min = modelStateRef('min', 0);

const max = modelStateRef('max', null);

const step = modelStateRef('step', 1);

watch(
  () => field.value.$model,
  () => {
    if (typeof field.value.$model === 'number') {
      if (field.value.$model !== parseInput(currInputVal.value)) {
        currInputVal.value = stringVal.value;
      }
    } else {
      currInputVal.value = stringVal.value;
    }
  },
  { immediate: true }
);
</script>

<template>
  <InputGroupWrapper v-bind="{ field, model }">
    <input
      :name="inputName"
      :value="currInputVal"
      type="number"
      inputmode="decimal"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      :min="min"
      :max="max"
      :step="step"
      @blur="onNumberFieldBlur"
      @keypress="onKeyPress"
      @input="setValue($event.target.value)"
    />
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </InputGroupWrapper>
</template>
