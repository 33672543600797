<script setup lang="ts">
import { computed, ref, onBeforeUnmount, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { DatePicker } from 'v-calendar/lib/v-calendar.umd';
import { format, startOfDay, parse, addDays, subDays } from 'date-fns';
import { CalendarIcon } from '../../../icons/components';
import { isDescendant } from '../../../helpers/dom';
import { generateUUID } from '../../../helpers/uuid';
import { stripZoneOffset, addZoneOffset } from '../../../helpers/time';
import { eachDayOfInterval, startOfMonth, endOfMonth, subWeeks, addWeeks, isValid } from 'date-fns';
import { getValidatorFn } from 'ah-common-lib/src/form/helpers';

// FIXME format may become an editable value, but currently it is effectively a constant
const formatStr = 'dd-MM-yyyy';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const formElement = useFormElement({
  emit,
  props,
});

const { fieldErrorsShown, readonly, inputErrorClass, inputClass, inputName } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getModelState, setValue } = formElement;

const dateInput = ref<Element>();

const datePicker = ref<InstanceType<typeof DatePicker>>();

const previousInputDateString = ref('');

const inputDateString = ref('');

const isCalendarShown = ref(false);

const uuid = generateUUID();

const useDateTime = modelStateRef('useDateTime', false);

const shouldDisplayCalendar = modelStateRef('showCalendar', true);

const dirtyOnInput = modelStateRef('dirtyOnInput', true);

const useLocalTime = modelStateRef('useLocalTime', false);

const placeholder = computed(() => {
  const placeholder = getModelState('placeholder', 'DD-MM-YYYY');

  return placeholder === 'Insert date...' ? 'DD-MM-YYYY' : placeholder;
});

const popupDate = computed(() => {
  if (field.value.$model) {
    let date = new Date(field.value.$model);
    return useDateTime.value && !useLocalTime.value ? addZoneOffset(date) : date;
  }
  return field.value.$model;
});

const disabledDates = computed(() => {
  // FIXME: "hacking" dates showned in page
  // v-calendar doesn't support date validations only arrays of
  // allowed/disabled dates, therefore, we are calculating disabled dates
  // in current showned calendar page
  const month = new Date(currentMonthDisplayed.value.year, currentMonthDisplayed.value.month - 1);
  const days = eachDayOfInterval({
    start: subWeeks(startOfMonth(month), 1),
    end: addWeeks(endOfMonth(month), 1),
  });

  return days.filter((day) => dateDisallowedChecker(day));
});

const dateDisallowedChecker = (date: Date) => {
  const validators = Object.keys(model.value.$validators);
  const dateVal = (useLocalTime.value ? date : stripZoneOffset(date)).toISOString();
  for (let i = 0; i < validators.length; i += 1) {
    const validator = getValidatorFn(model.value.$validators[validators[i]]);
    if (
      !validator(dateVal, model.value.$parent && model.value.$parent(), model.value.$parent && model.value.$parent())
    ) {
      return true;
    }
  }
  return false;
};

const clickListener = (event: any) => {
  if (isCalendarShown.value && datePicker.value && !isDescendant(datePicker.value.$el, event.target)) {
    hideCalendar();
  }
};

const currentMonthDisplayed = ref({
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
});

const selectionAttributes = {
  highlight: {
    class: 'date-highlight',
    contentClass: 'date-highlight-content',
  },
};

const attributes = [
  {
    key: 'today',
    highlight: {
      class: 'vc-today',
      contentClass: 'vc-today-content',
    },
    dates: new Date(),
  },
];

function formatDate(date: Date) {
  try {
    return useDateTime.value ? date.toISOString() : format(date, 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
}

function onKeyPress(event: KeyboardEvent) {
  if (inputDateString.value) {
    if (event.key === 'ArrowUp') {
      inputDateString.value = format(addDays(parse(inputDateString.value, formatStr, new Date()), 1), formatStr);
    }
    if (event.key === 'ArrowDown') {
      inputDateString.value = format(subDays(parse(inputDateString.value, formatStr, new Date()), 1), formatStr);
    }
  }
}

onBeforeUnmount(() => {
  window.removeEventListener('click', clickListener);
});

function showCalendar() {
  if (!isCalendarShown.value && !readonly.value) {
    isCalendarShown.value = true;
    setTimeout(() => {
      if (field.value.$model && datePicker.value) {
        datePicker.value.move(new Date(field.value.$model), { transition: 'none' });
      }
      if (isCalendarShown.value) {
        window.addEventListener('click', clickListener);
      }
    });
  }
}

function hideCalendar() {
  if (isCalendarShown.value) {
    isCalendarShown.value = false;
    window.removeEventListener('click', clickListener);
  }
}

function formatInputDateString(date: string) {
  if (date.length < 7) {
    date = date.replace(/(\d{2})(?=\d)/g, '$1-');
  }
  return date;
}

function onFieldInput(event: InputEvent) {
  inputDateString.value = (event.target as HTMLInputElement).value;
  const inputLength = inputDateString.value.length;
  /**
   * Multiple calculations are made on the adding date string
   * to make sure the format will match whats expected (dd-mm-yyyy).
   *
   * Example:
   *  - Added "03" > we will add a '-' automatically > "03-"
   *  - Added "3-" > we will add a 0 before the single number > "03-"
   */
  if (inputLength < previousInputDateString.value.length) {
    if (inputLength === 3 || inputLength === 4) {
      inputDateString.value = inputDateString.value.substring(0, inputLength - 1);
    }
  } else {
    if (inputDateString.value.length === 2 || inputDateString.value.length === 5) {
      if (inputDateString.value[inputDateString.value.length - 1] === '-') {
        inputDateString.value =
          inputDateString.value.slice(0, inputDateString.value.length - 2) +
          '0' +
          inputDateString.value.slice(inputDateString.value.length - 2);
      } else {
        inputDateString.value += '-';
      }
    }
    inputDateString.value = formatInputDateString(inputDateString.value);
  }
  previousInputDateString.value = inputDateString.value;
}

function setDateFromPopup(date: Date) {
  date = startOfDay(date);

  if (useDateTime.value && !useLocalTime.value) {
    date = stripZoneOffset(date);
  }

  setValue(formatDate(date));
  hideCalendar();
}

function setDate(event: InputEvent) {
  inputDateString.value = (event.target as HTMLInputElement).value;
  onInput();
}

function onInput() {
  const dirty = !field.value.$dirty && !dirtyOnInput.value;

  if (inputDateString.value.length) {
    const date = parse(inputDateString.value, 'dd-MM-yyyy', new Date());

    if (isValid(date)) {
      let parsedDate = startOfDay(new Date(date));

      if (useDateTime.value && !useLocalTime.value) {
        parsedDate = stripZoneOffset(parsedDate);
      }
      setValue(formatDate(parsedDate));
    } else {
      setValue(new Date(NaN), dirty);
    }
  } else {
    setValue('');
  }
}

watch(
  () => field.value.$model,
  () => {
    if (!field.value.$model) {
      inputDateString.value = '';
      previousInputDateString.value = '';
    } else {
      const date = useLocalTime.value ? new Date(field.value.$model) : addZoneOffset(new Date(field.value.$model));
      if (field.value.$model && isValid(date)) {
        inputDateString.value = format(date, formatStr);
        previousInputDateString.value = inputDateString.value;
      }
    }
  },
  { immediate: true }
);

watch(isCalendarShown, (newVal: boolean, oldVal: boolean) => {
  if (!newVal && oldVal) {
    field.value.$touch();
  }
});
</script>

<template>
  <div class="single-input-date-wrapper">
    <span class="date-wrapper">
      <input
        ref="dateInput"
        inputmode="text"
        type="text"
        :value="inputDateString"
        :name="inputName"
        :readonly="readonly"
        :tabindex="readonly ? -1 : undefined"
        :placeholder="placeholder"
        :class="['field-group-field-input date', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
        @input="onFieldInput"
        @keydown="onKeyPress"
        @blur="setDate"
      />
      <div class="calendar-pick" :class="{ readonly: readonly }" v-if="shouldDisplayCalendar">
        <span @click="showCalendar" :id="`calendar-icon-${uuid}`">
          <CalendarIcon class="calendar" />
        </span>
        <BTooltip custom-class="calendar-tooltip" :target="`calendar-icon-${uuid}`" :show="isCalendarShown" triggers="">
          <DatePicker
            ref="datePicker"
            :value="popupDate"
            :class="['date-picker', inputClass]"
            @input="setDateFromPopup($event)"
            :disabled-dates="disabledDates"
            :to-page.sync="currentMonthDisplayed"
            :attributes="attributes"
            :select-attribute="selectionAttributes"
            :drag-attribute="selectionAttributes"
            locale="eng"
            is-expanded
            trim-weeks
          />
        </BTooltip>
      </div>
    </span>
  </div>
</template>

<style lang="scss">
.single-input-date-wrapper {
  .field-group-field-input.date {
    display: inline-block;
    margin-right: 0.5rem;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  .date-wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 0rem;
  }

  .calendar-pick {
    display: inline-block;
    position: relative;
    cursor: pointer;
    &.readonly {
      cursor: not-allowed;
    }
  }
}

.calendar-tooltip {
  opacity: 1;
  .arrow {
    display: none;
  }

  .tooltip-inner {
    background: none;
    max-width: none;
    padding: 0;
  }
}
</style>
