<script lang="ts" setup>
import { computed } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';
import InputGroupWrapper from '../common/InputGroupWrapper.vue';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const formElement = useFormElement<FieldModel, string>({
  emit,
  props,
});

const {
  maxLength,
  inputName,
  fieldType,
  readonly,
  inputMode,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
  valueToInputFn,
  emptyValue,
  number,
} = useBaseFieldValues(formElement);

const { onBlur, onKeyPress, onPaste, setValue } = useTextFormFieldMethods(formElement);

const { model, field } = formElement;

const stringVal = computed(() => {
  const val = valueToInputFn.value(field.value.$model);

  if (number.value && typeof val === 'number') {
    return Number.isNaN(val) ? emptyValue.value : val.toString();
  }

  return val;
});
</script>

<template>
  <InputGroupWrapper v-bind="{ field, model }">
    <input
      :maxlength="maxLength"
      :name="inputName"
      :value="stringVal"
      :type="fieldType"
      :inputmode="inputMode"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @blur="onBlur"
      @paste="onPaste"
      @keypress="onKeyPress"
      @input="setValue($event.target.value)"
    />
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </InputGroupWrapper>
</template>
