<script lang="ts" setup>
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { generateUID } from '../../../helpers/uuid';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const uid = generateUID(6);

const formElement = useFormElement({
  emit,
  props,
});

const { readonly, inputName } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName, setValue } = formElement;

const inputClass = modelStateRef('inputClass', 'custom-control custom-radio');

const options = modelStateRef('options');

const inline = modelStateRef('inline', false);

function optionName(option: any) {
  return typeof option === 'string' ? option : option.label;
}

function optionValue(option: any) {
  return typeof option === 'string' ? option : option.value;
}

function isOptionDisabled(option: any) {
  return readonly.value || option?.disabled === true;
}
</script>

<template>
  <div class="field-group-input-container">
    <div
      v-for="option in options"
      :key="optionValue(option)"
      :class="[inputClass, { 'custom-control-inline': inline }]"
    >
      <input
        :id="`${model.$path}-${optionValue(option)}-${uid}`"
        :checked="optionValue(option) === field.$model"
        :value="optionValue(option)"
        :disabled="isOptionDisabled(option)"
        :name="inputName"
        type="radio"
        class="custom-control-input"
        @change="setValue(optionValue(option))"
      />
      <label :for="`${model.$path}-${optionValue(option)}-${uid}`" class="custom-control-label">
        <slot :name="getSlotName('option')" v-bind="{ field, model, option }">{{ optionName(option) }}</slot>
      </label>
    </div>
    <slot :name="getSlotName('append')" v-bind="{ field, model }" />
  </div>
</template>

<style lang="scss" scoped>
.custom-control {
  padding-bottom: 0.25rem;

  .custom-control-input:checked ~ .custom-control-label {
    font-weight: $font-weight-medium;
  }
  &.custom-radio {
    .custom-control-label {
      font-weight: $font-weight-regular;
      &::before,
      &::after {
        top: 0.1rem;
      }
    }
  }
}
</style>
