import { render, staticRenderFns } from "./DeclineInviteView.vue?vue&type=template&id=5ac937ee&scoped=true"
import script from "./DeclineInviteView.vue?vue&type=script&setup=true&lang=ts"
export * from "./DeclineInviteView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DeclineInviteView.vue?vue&type=style&index=0&id=5ac937ee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac937ee",
  null
  
)

export default component.exports