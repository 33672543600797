<script lang="ts" setup>
import { computed } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
import { useTextFormFieldMethods } from '../../composables/useTextFormFieldMethods';

const props = defineProps(makeUseFormElementProps<FieldModel, string>());

const emit = defineEmits(makeUseFormElementEmits<string>());

const formElement = useFormElement<FieldModel, string>({
  emit,
  props,
});

const {
  maxLength,
  inputName,
  fieldType,
  readonly,
  autocomplete,
  placeholder,
  inputClass,
  inputErrorClass,
  fieldErrorsShown,
  valueToInputFn,
  emptyValue,
} = useBaseFieldValues(formElement);

const { onKeyPress, setValue } = useTextFormFieldMethods(formElement);

const { field, modelStateRef } = formElement;

const stringVal = computed(() => valueToInputFn.value(field.value.$model));

const showMaxLengthCounter = modelStateRef('showMaxLengthCounter', false);

const rows = modelStateRef('rows', 3);

function onInput(event: Event) {
  const text = (event.target as HTMLTextAreaElement).value || '';
  if (text.replace(/\s/g, '') === '') {
    setValue(emptyValue.value);
  } else {
    setValue(text);
  }
}
</script>

<template>
  <div class="field-group-input-container">
    <textarea
      :name="inputName"
      :value="stringVal"
      :type="fieldType"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      :rows="rows"
      :maxlength="showMaxLengthCounter ? maxLength : null"
      max-rows="6"
      @blur="() => field.$touch()"
      @keypress="onKeyPress"
      @input="onInput"
    />
    <div v-if="showMaxLengthCounter" class="max-length-counter mt-1">
      <span>{{ stringVal?.length || 0 }} / {{ maxLength }}</span>
    </div>
  </div>
</template>

<style scoped>
.max-length-counter {
  text-align: right;
  font-size: 12px;
}
</style>
