var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"terms-and-condition-step"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"head-section"},[_c('h5',{staticClass:"mt-2"},[_vm._v("Terms and Conditions")]),_c('VButton',{staticClass:"btn-secondary",attrs:{"loading":_setup.requestManager.requestStates.downloadTerms === 'pending'},on:{"click":_setup.downloadTerms}},[_c(_setup.MaterialIcon,{attrs:{"icon":"download"}}),_vm._v(" Download ")],1)],1)]),_c('div',{staticClass:"embed-file"},[(_setup.requestManager.requestStates.loadTOSHtml === 'pending')?_c('LoadingIcon'):_c('div',{domProps:{"innerHTML":_vm._s(_setup.termsAndConditionsHtml)}})],1),_c('div',{class:[
        'confirm-terms-conditions',
        {
          'invalid-styling':
            _setup.termsAndConditionsFM.validation &&
            _setup.termsAndConditionsFM.validation.$invalid &&
            _setup.termsAndConditionsFM.validation.$dirty,
        },
      ]},[_c('ValidatedForm',{attrs:{"fm":_setup.termsAndConditionsFM.form,"validation":_setup.termsAndConditionsFM.validation},on:{"update:validation":function($event){return _vm.$set(_setup.termsAndConditionsFM, "validation", $event)}}})],1)]),_c('div',{staticClass:"buttons mt-3"},[_c('span',{staticClass:"required-text"},[_vm._v("* Mandatory")]),_c('VButton',{staticClass:"btn-primary",attrs:{"loading":_setup.requestManager.requestStates.approveTerms === 'pending'},on:{"click":_setup.handleFinishButtonClick}},[_vm._v(" Finish "),_c(_setup.MaterialIcon,{attrs:{"icon":"arrow_forward"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }