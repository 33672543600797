import type { AppConfig as BaseAppConfig } from 'config/baseConfig';
import { Component } from 'vue';
import { CustomRouteConfig } from './router/routeModels';
import { UserRole } from 'ah-api-gateways';
import Vue from 'vue';

export interface BdAppConfig {
  postSetup?: (vue: typeof Vue) => Promise<any>;
  allowedRoles: UserRole[];
  routes: CustomRouteConfig[];
  rootComponent: Component;
}

export type AppConfig = BaseAppConfig & BdAppConfig;

export type AppConfigSetup = Partial<BaseAppConfig> & BdAppConfig;

let config: AppConfig | null = null;

export function setupAppConfig(_config: AppConfigSetup) {
  config = {
    ...require(process.env.VUE_APP_CONFIG_FILE!).default,
    ..._config,
  };

  return config!;
}

export function useConfig(): AppConfig {
  if (!config) {
    throw 'App config used before setup!';
  }
  return config;
}
